.page .pane-node-comment-form {
  border-top: 1px solid $border-color;
  padding-top: 40px;
  margin-top: 40px;
  position: relative;
  h2.pane-title {
    text-transform: none;
    font-weight: 600;
  }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block) {
    label {
      display: none !important;
    }
    .form-textarea {
      padding: 10px 12px 10px 60px;
    }
  }
  .user-image {
    position: absolute;
    top: 105px;
    left: -10px;
    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }
}

.pane-node-comments {
  padding-top: 40px;
  h2.pane-title {
    text-transform: none;
    font-weight: 600;
  }
  .comment {
    position: relative;
    border: 1px solid $border-color;
    padding: 20px 20px 20px 70px;
    margin-top: -1px;
    .changed, .links, a.permalink {
      display: none;
    }
    .name {
      font-weight: 600;
      font-size: rem-calc(20);
      a {
        text-decoration: none;
        &:hover {
          color: $secondary-color;
        }
      }
    }
    .date {
      color: $tertiary-color;
      text-transform: uppercase;
      font-size: rem-calc(15);
      margin: 0 0 7px 0;
      font-weight: 600;
    }
    h3 {
      margin: 0;
    }
    h3 a.permalink {
      display: block;
      text-decoration: none;
    }
    .user-picture, .field-name-ds-user-picture {
      position: absolute;
      top: 10px;
      left: -10px;
      img {
        border-radius: 50%;
        width: 60px;
        height: 60px;
      }
    }
  }
}

.question-form {
  position: relative;
  .user-image {
    position: absolute;
    top: 40px;
    left: -10px;
    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block) {
    .form-textarea {
      padding: 10px 12px 10px 60px !important;
    }
    .button {
      box-shadow: none !important;
    }
  }
}