.page {
  overflow-x: hidden;
}

.page-content {
  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }
  @include media-breakpoint-up(md) {
    padding: 50px 0;
  }
}

.pane-pane-page-title,
.pane-page-title {
  h1 {
    margin-top: 0;
    display: inline-block;
    margin-right: 10px;
  }
  .bib-label {
    background-color: $yellow;
    text-transform: uppercase;
    font-size: rem-calc(15);
    color: $white;
    font-weight: 700;
    padding: 1px 5px;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
  }
}

.one-sidebar {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
  }
  .main {
    @include media-breakpoint-up(md) {
      flex: 1 0 60%;
      max-width: 60%;
    }
  }
  .sidebar-second {
    @include media-breakpoint-down(md) {
      padding-top: 40px;
    }
    @include media-breakpoint-up(md) {
      flex: 1 0 40%;
      max-width: 40%;
      padding-left: 70px;
    }
    .panel-pane {
      margin-bottom: 20px;
    }
  }
}

.panel-pane.pane-bundle-manual-list,
.panel-pane.pane-bundle-automatic-list {
  .field-name-field-short-description, {
    @include media-breakpoint-down(md) {
      padding-bottom: 45px;
    }
    @include media-breakpoint-up(md) {
      max-width: calc(100% - 100px);
    }
    .field-name-field-short-description {
      padding-bottom: 0;
      max-width: 100%;
    }
  }
  .content-wrapper {
    .field-name-field-short-description {
      padding-bottom: 0;
      max-width: 100%;
    }
  }
}

.panel-pane.pane-recent-answers-panel-pane-1 {
  background-color: #E7F2F4;
  @include media-breakpoint-down(md) {
    padding: 25px 20px;
  }
  @include media-breakpoint-up(md) {
    padding: 35px 50px;
  }
  h2.pane-title {
    text-transform: none;
    font-weight: 600;
  }
  ul {
    margin: 0;
    padding: 0;
  }
}

.panel-pane.pane-score-info {
  border: 1px solid $border-color;
  @include media-breakpoint-down(md) {
    padding: 25px 20px 15px 20px;
  }
  @include media-breakpoint-up(md) {
    padding: 35px 50px 15px 50px;
  }
  h2.pane-title {
    text-transform: none;
    font-weight: 600;
  }
  h3 {
    color: $tertiary-color;
    text-transform: uppercase;
    font-size: rem-calc(15);
    margin: 0 0 7px 0;
  }
}

.panel-pane.related:not(.pane-news-related) {
  background-color: #E7F2F4;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: 25px 20px 15px 20px;
  }
  @include media-breakpoint-up(md) {
    padding: 35px 50px 15px 50px;
  }
  &.pane-composer-related {
    @include media-breakpoint-up(md) {
      margin-top: 87px;
      padding: 140px 50px 30px;
    }
    .composer-photo {
      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
      @include media-breakpoint-up(md) {
        position: absolute;
        left: 50px;
        top: -55px;
      }
      img {
        max-width: 180px;
        height: auto;
      }
    }
  }
  h2.pane-title {
    text-transform: none;
    font-weight: 600;
  }
  h3 {
    color: $tertiary-color;
    text-transform: uppercase;
    font-size: rem-calc(15);
    margin: 0 0 7px 0;
  }
}

.pane-news-related {
  position: relative;
  .news-photo {
    img {
      max-width: 100%;
      height: auto;
      vertical-align: top;
    }
  }
  .news-content {
    background-color: #E7F2F4;
    @include media-breakpoint-down(md) {
      padding: 25px 20px;
    }
    @include media-breakpoint-up(md) {
      padding: 30px 50px;
    }
  }
}

.panel-pane.pane-node-field-contact-e-mail {
  border: 1px solid $border-color;
  @include media-breakpoint-down(md) {
    padding: 25px 20px;
  }
  @include media-breakpoint-up(md) {
    padding: 35px 50px;
  }
  h2 {
    text-transform: uppercase;
    font-size: rem-calc(15);
    margin: 0 0 7px 0;
  }
}

aside.sidebar-second {
  .panel-pane.pane-bundle-text {
    border: 1px solid $border-color;
    @include media-breakpoint-down(md) {
      padding: 25px 20px;
    }
    @include media-breakpoint-up(md) {
      padding: 35px 50px;
    }
    &.lightblue {
      border: none;
      background-color: #E7F2F4;
    }
    &.yellow {
      border: none;
      background-color: $yellow;
      color: $white;
      h2.pane-title, h3 {
        color: $white;
      }
      p {
        font-weight: 500;
      }
    }
    &.blue {
      border: none;
      background-color: #E7F2F4;
    }
    &.darkblue {
      border: none;
      background: $primary-color;
      color: $white;
      h2.pane-title, h3 {
        color: $white;
      }
      p {
        font-weight: 500;
      }
    }
    h2 {
      text-transform: uppercase;
      font-size: rem-calc(15);
      margin: 0 0 7px 0;
    }
    h2.pane-title {
      text-transform: none;
      font-weight: 700;
      @include media-breakpoint-down(md) {
        font-size: rem-calc(24);
      }
      @include media-breakpoint-up(md) {
        font-size: rem-calc(27);
      }
    }
    h3 {
      color: $tertiary-color;
      text-transform: uppercase;
      font-size: rem-calc(15);
      margin: 1.2em 0 7px 0;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.panel-pane.pane-composer-spotlight {
  @include media-breakpoint-up(md) {
    margin-top: 34px;
  }
}

.panel-pane.pane-create-question {
  h2.pane-title {
    text-transform: none;
    font-weight: 600;
  }
  .top-questions {
    padding-top: 10px;
  }
  .item-list {
    ul {
      margin: 0;
      padding: 0;
    }
  }
  .field-name-question-link {
  }
}

.panel-pane.pane-pm-existing-pages {
  padding-top: 30px;
  padding-bottom: 30px;
  h2.pane-title {
    text-transform: none;
    font-weight: 600;
  }
  .item-list {
    ul {
      margin: 0;
      padding: 0;
    }
  }
}

.panel-pane.pane-user-profile {
  max-width: $max-content-width;
  img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  .view-mode-account {
    .label-inline {
      display: inline-block;
    }
  }
  .field-collection-container {
    border: none;
    margin: 30px 0 20px 0;
  }
  .field-collection-view {
    margin: -1px 0 0 0;
    padding: 15px 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    h3 {
      margin: 0 0 7px 0;
    }
  }
  .field-name-user-edit a {
    @extend.btn-blue;
  }
}

.field-name-body {
  max-width: $max-content-width;
}

form#user-login {
  max-width: 500px;
}

.field-name-title {
  h3 {
    margin-bottom: 7px;
  }
}

.field-name-title + .field-name-body {
  p {
    margin-top: 0;
    &:last-child {
      margin-bottom: 7px;
    }
  }
}

.node-panopoly-page {
  &.view-mode-search_result {
    max-width: $max-content-width;
    h2 {
      color: $primary-color;
      font-size: rem-calc(24);
      margin: 25px 0 5px 0;
    }
  }
  &.view-mode-list {
    h3 {
      margin: 5px 0;
      a {
        text-decoration: none;
        &:hover {
          color: $secondary-color;
        }
      }
    }
  }
  &.view-mode-square {
    position: relative;
    height: 100%;
    display: inline-flex;
    flex-flow: column;
    align-items: stretch;
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
    &:hover {
      cursor: hover;
      box-shadow: inset 0px -3px $secondary-color;
      h3 {
        color: $secondary-color;
      }
    }
    h3 {
      color: $primary-color;
      font-size: rem-calc(24);
      margin-top: 0;
      a {
        text-decoration: none;
        &:hover {
          color: $secondary-color;
        }
      }
    }
    .content-wrapper {
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      @include media-breakpoint-down(md) {
        padding: 15px 20px 20px 20px;
      }
      @include media-breakpoint-up(md) {
        padding: 25px 30px 30px 30px;
        flex: 1 1 auto;
      }
    }
    .image-wrapper {
      position: relative;
    }
  }
}

.panel-pane {
  &.colored {
    &.lightblue {
      background-color: #E7F2F4;
      @include media-breakpoint-down(md) {
        padding: 25px;
      }
      @include media-breakpoint-up(md) {
        padding: 50px;
      }
      .pane-title {
        color: $primary-color;
      }
    }
    &.darkblue {
      @include media-breakpoint-down(md) {
        padding: 25px;
      }
      @include media-breakpoint-up(md) {
        padding: 50px;
      }
    }
  }
}