.pane-main-menu,
.pane-menu-menu-main-menu-en {
  transition: $transition;
  position: relative;
  .pane-title {
    display: none;
  }
  ul.menu {
    position: relative;
    li {
      margin: 0;
      padding: 0;
      display: block;
      text-align: left;
      @include media-breakpoint-up(lg) {
        display: inline-block;
        &:nth-child(1) {
          display: none;
        }
      }
    }
    a {
      display: inline-block;
      font-weight: 600;
      font-family: $font-family--headers;
      text-transform: uppercase;
      font-size: rem-calc(17);
      color: $white;
      margin: 0 7px;
      padding: 5px 0;
      text-decoration: none;
      line-height: 12px;
      position: relative;
      border-bottom: 1px solid transparent;
      &:hover,
      &:focus,
      &:active {
        color: $white;
        &:after {
          content: '';
          position: absolute;
          background: url('../images/icons/application/nav-active.svg') no-repeat 100% 50%;
          width: 12px;
          height: 4px;
          left: 0;
          bottom: -5px;
        }
      }
      &.active {
        color: $white;
        &:after {
          content: '';
          position: absolute;
          background: url('../images/icons/application/nav-active.svg') no-repeat 100% 50%;
          width: 12px;
          height: 4px;
          left: 0;
          bottom: -5px;
        }
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}
