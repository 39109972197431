.sidebar-layout {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-areas: "sidebar_aside sidebar_content";
    grid-template-columns: $default-aside-width calc(90vw - #{$default-aside-width});

    @supports (display: grid) {
      grid-template-columns: $default-aside-width minmax(auto, $main-content-max-width);
    }
  }
}

.sidebar-layout__aside {
  grid-area: sidebar_aside;
}

.sidebar-layout__content {
  grid-area: sidebar_content;

  padding: 0 rem-calc(20);

  @include media-breakpoint-up(md) {
    padding: 0 rem-calc(40) 0 10vw;
  }
}
