.paragraphs-item-embed {
  .field-name-decoded-code {
    @include media-breakpoint-down(xs) {
      overflow:hidden;
      padding-bottom:56.25%;
      position:relative;
      height:0;
      iframe {
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
      }
    }
  }
}