.download-list {
  margin: 0;
  padding: 0;
  li {
    border-bottom: 1px solid $border-color;
  }
}
.file {
  display: block;
  a {
    display: block;
    padding: 7px 0 8px 0;
    min-height: 16px;
    padding-left: 37px;
    position: relative;
    transition: none;
    &:before {
      content: '';
      position: absolute;
      top: 13px;
      left: 0;
      width: 30px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: 0;
    }
    &:hover {
      color: lighten($primary-color,12%);
    }
  }
  &.file--mime-application-pdf {
    a {
      &:before {
        background-image: url('../images/file/file-pdf.svg');
      }
      &:hover:before {
        //background-image: url('../images/file/file-pdf--white.svg');
      }
    }
  }
  &.file--mime-audio-mpeg {
    a {
      &:before {
        background-image: url('../images/file/file-mp3.svg');
      }
      &:hover:before {
        //background-image: url('../images/file/file-pdf--white.svg');
      }
    }
  }
  &.file--mime-application-zip {
    a {
      &:before {
        background-image: url('../images/file/file-zip.svg');
      }
      &:hover:before {
        //background-image: url('../images/file/file-zip--white.svg');
      }
    }
  }
  &.file--mime-text-plain {
    a {
      &:before {
        background-image: url('../images/file/file-txt.svg');
      }
      &:hover:before {
        // background-image: url('../images/file/file-doc--white.svg');
      }
    }
  }
  &.file--x-office-document {
    a {
      &:before {
        background-image: url('../images/file/file-doc.svg');
      }
      &:hover:before {
       // background-image: url('../images/file/file-doc--white.svg');
      }
    }
  }
  &.file--mime-application-octet-stream {
    a {
      &:before {
        background-image: url('../images/file/file-mscz.svg');
      }
    }
  }
}