.panel-row {
  @include media-breakpoint-down(md) {
    margin: 40px 0;
  }
  @include media-breakpoint-up(md) {
    margin: 70px 0;
  }
  &:first-child {
    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }
  }
  &:last-child {
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
  .col-sm-6 {
    .panel-pane.pane-bundle-free-block {
      @include media-breakpoint-up(md) {
        padding-right: 50px;
      }
      p {
        margin: 10px 0;
      }
      .field-name-field-link {
        @include media-breakpoint-down(md) {
          margin-bottom: 30px;
        }
      }
    }
  }
  .panel-pane + .panel-pane {
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 70px;
    }
  }
  .panel-pane {
    position: relative;
    &.youtube {
      .pane-title {
        background: url('../images/icons/social/youtube-title.svg') no-repeat 0 50%;
        padding-left: 40px;
        color: $white;
      }
    }
    &.colored {
      &.lightblue {
        background-color: #E7F2F4;
        @include media-breakpoint-down(md) {
          padding: 25px;
        }
        @include media-breakpoint-up(md) {
          padding: 50px;
        }
        .pane-title {
          color: $primary-color;
        }
      }
      &.yellow {
        background-color: $yellow;
        color: $white;
        @include media-breakpoint-down(md) {
          padding: 25px;
        }
        @include media-breakpoint-up(md) {
          padding: 50px;
        }
        p {
          font-weight: 500;
        }
        .field-name-field-link {
          @extend.btn-white--yellow-text;
          a {
            color: $yellow;
            &:hover, &:focus, &:active {
              color: $secondary-color;
            }
          }
        }
      }
      &.blue {
        background-color: #E7F2F4;
        @include media-breakpoint-down(md) {
          padding: 25px;
        }
        @include media-breakpoint-up(md) {
          padding: 50px;
        }
      }
      &.darkblue {
        background: $primary-color url('../images/icons/application/bg-shape--dark.svg') no-repeat 90% 50%;
        background-size: 110% auto;
        color: $white;
        p {
          font-weight: 500;
        }
      }
      .col {
        &.col-md-7 {
          @include media-breakpoint-down(md) {
            padding: 25px 40px;
          }
          @include media-breakpoint-up(md) {
            padding: 50px 60px 50px 70px;
          }
        }
      }
      .pane-title {
        color: $white;
        text-transform: none;
        font-weight: 600;
        @include media-breakpoint-down(md) {
          font-size: rem-calc(30);
        }
        @include media-breakpoint-up(md) {
          font-size: rem-calc(36);
        }
      }
      .field-name-field-basic-image-image img {
        vertical-align: top;
        width: 100%;
        height: auto;
      }
    }
    .pane-title, .field-name-field-short-description {
      @include media-breakpoint-up(md) {
        max-width: 700px;
      }
    }
    .field-name-field-short-description + .field-name-manual-list {
      @include media-breakpoint-up(md) {
        margin-top: 15px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .field-name-field-link {
      @extend.btn-blue;
      a {
        color: $white;
        text-decoration: none;
      }
    }
  }
  &.colored {
    @include media-breakpoint-down(md) {
      padding-top: 40px;
      padding-bottom: 40px;
      margin-bottom: 0;
    }
    @include media-breakpoint-up(md) {
      padding-top: 70px;
      padding-bottom: 70px;
      margin-bottom: 0;
    }
    &.lightblue {
      background-color: #E7F2F4;
      .pane-title {
        color: $primary-color;
      }
    }
    &.blue {
      background-color: #E7F2F4;
      .node-news.view-mode-square {
        background-color: $white;
      }
    }
    &.darkblue {
      background: $primary-color url('../images/icons/application/bg-shape--dark.svg') no-repeat 90% 50%;
      background-size: 110% auto;
      color: $white;
      .col:nth-child(2) {
        @include media-breakpoint-up(md) {
          text-align: right;
        }
        .field-name-field-basic-text-text {
          @extend.btn-white;
          p {
            margin: 0;
          }
          a {
            text-decoration: none;
            &:hover, &:focus, &:active {
              color: $secondary-color;
            }
          }
        }
      }
      .pane-bundle-html-code {
        margin-top: 20px;
      }
    }
    &.yellow {
      background-color: $yellow;
    }
    .node-news {
      &.view-mode-square {
        .content-wrapper {
          border:  none;
        }
      }
    }
  }
  &.full-width {
    .container {
      max-width: none;
    }
  }
}

.panel-row.colored:first-child {
  @include media-breakpoint-down(md) {
    margin-top: -30px;
  }
  @include media-breakpoint-up(md) {
    margin-top: -50px;
  }
}

.panel-row.panel-bottom {
  margin-bottom: -50px; // Stick to the footer.
  margin-top: 35px;
}

.panel-row.colored + .panel-row.colored {
  margin-top: 0;
}



.pane-block:not(.pane-views-exp-scores-page):not(.pane-views-exp-nieuws-page) {
  @include media-breakpoint-down(sm) {
    padding-left: $phone-padding;
    padding-right: $phone-padding;
  }
  @include media-breakpoint-up(sm) {
    width: $max-site-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $tablet-padding;
    padding-right: $tablet-padding;
  }
  @include media-breakpoint-up(lg) {
    padding-left: $desktop-padding;
    padding-right: $desktop-padding;
  }
}

.bryant .panel-pane {
  margin-bottom: 30px;
}
.bryant {
  .entity-paragraphs-item:first-child {
    margin-top: 0;
  }
}

@import "panes/manual-list";
@import "panes/automatic-list";
@import "panes/comments";
@import "panes/messages";
@import "panes/pane-text";
@import "panes/slogan";
@import "panes/pane-faq";
@import "panes/pane-html-code";
@import "panes/pane-image";
@import "panes/pane-video";
@import "panes/free-block";
@import "panes/pane-logo";
