.main-header {
  background-color: #265C6D;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  /*background-image: url('../images/header/koorklank-header.jpg') no-repeat 100% 50%;*/
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #265C6D;
    opacity: .7;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #265C6D;
    opacity: .6;
    width: 100%;
    height: 100%;
    z-index: 0;
    mix-blend-mode: color;
  }
  ul, ul:not([class])  {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li:before {
      display: none;
    }
  }
  a {
    text-decoration: none;
  }
  .header {
    z-index: 1;
    position: relative;
    @include media-breakpoint-down(sm) {
      padding: 15px 0;
    }
    @include media-breakpoint-up(sm) {
      padding: 20px 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 23px 0 20px 0;
    }
    .container {
      display: flex;
      flex-wrap: wrap;
      @include media-breakpoint-up(lg) {
        align-items: center;
      }
    }
    .pane-pane-header {
      flex: 1 0 30%;
      max-width: 30%;
    }
    .pane-main-menu,
    .pane-menu-menu-main-menu-en {
      flex: 1 0 calc(70% - 50px);
      max-width: calc(70% - 50px);
      text-align: right;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .pane-pane-search {
      position: relative;
      @include media-breakpoint-down(md) {
        margin-left: auto;
      }
      @include media-breakpoint-up(lg) {
        flex: 1 0 50px;
        max-width: 50px;
      }
      &.open {
        #search-block-form {
          visibility: visible;
          opacity: 1;
        }
      }
      .search-toggle {
        display: block;
        background: url('../images/icons/application/search--white.svg') no-repeat 100% 50%;
        width: 40px;
        height: 50px;
        font-size: 0;
        text-indent: -9999px;
        overflow: hidden;
        @include media-breakpoint-down(lg) {
          margin-right: 40px;
        }
      }
      #search-block-form {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 50px;
        right: 0;
        width: 250px;
        border: 5px solid $tertiary-color;
        z-index: 400;
        .form-text {
          border: none;
          margin: 0;
        }
        .form-actions {
          position: absolute;
          top: 0;
          right: 0;
          &:after {
            display: none;
          }
          input.button {
            margin: 0;
            background: transparent url('../images/icons/application/arrow--right--dark.svg') no-repeat 60% 50%;
            width: 40px;
            height: 40px;
            text-indent: -9999px;
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
  .hero-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .header-intro {
    z-index: 1;
    position: relative;
    color: $white;
    @include media-breakpoint-down(md) {
      padding: 40px 0 30px 0;
    }
    @include media-breakpoint-up(md) {
      padding: 80px 0 60px 0;
    }
    h1, h2, h3, h4 {
      margin-top: 0;
      color: $white;
      @include media-breakpoint-down(md) {
        font-size: rem-calc(30);
      }
      @include media-breakpoint-up(md) {
        font-size: rem-calc(42);
      }
    }
    p {
      margin-top: 0;
      @include media-breakpoint-down(md) {
        font-size: rem-calc(19);
      }
      @include media-breakpoint-up(md) {
        font-size: rem-calc(22);
        line-height: 30px;
      }
      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }
  .header-intro-width {
    z-index: 1;
    position: relative;
    max-width: 620px;
    .button {
      @extend.btn-red;
    }
  }
}

.site-logo {
  display: block;
  position: relative;
  z-index: map-get($z-index, site-logo);
  img {
    transition: $transition;
    vertical-align: top;
  }
  @include media-breakpoint-down(sm) {
    img {
      width: 77px;
      height: 50px;
    }
  }
  @include media-breakpoint-up(sm) {
    img {
      width: 123px;
      height: 80px;
    }
  }
}

.name-and-slogan {
  display: none;
}

.top-bar {
  z-index: 2;
  position: relative;
  background-color: rgba(14,63,79,.8);
  color: #C0D3D9;
  font-weight: 500;
  @include media-breakpoint-down(md) {
    display: none;
  }
  a {
    color: #C0D3D9;
    font-weight: 500;
    &:hover {
      color: $white;
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .menu {
    @include media-breakpoint-up(md) {
      flex-grow: 1;
      text-align: right;
      li {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .user {
    @include media-breakpoint-up(md) {
      a {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

body.logged-in {
  .top-bar {
  }
}