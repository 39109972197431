.pane-bundle-slogan {
  .fieldable-panels-pane {
    background-color: $primary-color;
    color: $white;
    font-weight: 500;
    @include media-breakpoint-down(md) {
      padding: 25px;
    }
    @include media-breakpoint-up(md) {
      padding: 50px;
    }
  }
  .field-name-field-slogan-link a {
    @extend.btn-white;
    margin: 20px 0 0 0 !important;
  }
}