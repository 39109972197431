$primary-color-en: #E61B5F;
$secondary-color-en: #5DA3B0;


.i18n-en {
  .menu-wrapper {
    background-color: darken(#78bdca,5%);
  }
  .main-header {
    background-color: #78bdca;
    &:before {
      background-color: #5DA3B0;
      opacity: .65;
    }
    &:after {
      background-color: #5DA3B0;
      opacity: .65;
    }
    .header-intro-width .button {
      color: $secondary-color-en;
      background-color: $white;
      &:after {
        border-left: 6px solid $secondary-color-en;
      }
      &:hover, &:active, &:focus {
        color: darken($secondary-color-en,7%);
        &:after {
          border-left: 6px solid $secondary-color-en;
        }
      }
    }
  }

  &.node-type-panopoly-landing-page .site-logo {
    background: url('../images/logo/logo--white.svg') no-repeat;
    @include media-breakpoint-down(sm) {
      width: 77px;
      height: 50px;
      background-size: 77px 50px;
    }
    @include media-breakpoint-up(sm) {
      width: 153px;
      height: 100px;
      background-size: 153px 100px;
    }
    img {
      display: none;
    }
  }
  .site-logo {
    background: url('../images/logo/logo--white.svg') no-repeat;
    @include media-breakpoint-down(sm) {
      width: 77px;
      height: 50px;
      background-size: 77px 50px;
    }
    @include media-breakpoint-up(sm) {
      width: 123px;
      height: 80px;
      background-size: 123px 80px;
    }
    img {
      display: none;
    }
  }
  /*
  .top-bar {
    z-index: 2;
    position: relative;
    background-color: rgba(0,0,0,.95);
    color: $white;
    a {
      color: $white;
    }
    .user {
      @include media-breakpoint-up(md) {
        text-align: right;
        padding-right: 15px;
      }
    }
  }
  .main-language .language-switcher-locale-url {
    background-color: #111;
  }
  .footer-top {
    background: $primary-color-en;
    .col-md-3:nth-child(1), .col-md-3:nth-child(2) {
      a {
        color: $white;
        text-decoration: none;
        text-shadow: 0.03em 0 $primary-color-en, -0.03em 0 $primary-color-en, 0 0.03em $primary-color-en, 0 -0.03em $primary-color-en, 0.06em 0 $primary-color-en, -0.06em 0 $primary-color-en, 0.09em 0 $primary-color-en, -0.09em 0 $primary-color-en, 0.12em 0 $primary-color-en, -0.12em 0 $primary-color-en, 0.15em 0 $primary-color-en, -0.15em 0 $primary-color-en;
        background: linear-gradient($primary-color-en,$primary-color-en),linear-gradient($primary-color-en,$primary-color-en),linear-gradient($white,$white);
        background-size: .05em 1px,.05em 1px,1px 1px;
        background-repeat: no-repeat,no-repeat,repeat-x;
        background-position: 0 95%,100% 95%,0 95%;
        &:hover, &:focus, &:active {
          background: linear-gradient($primary-color-en,$primary-color-en),linear-gradient($primary-color-en,$primary-color-en),linear-gradient($white,$white);
          background-size: .05em 1px,.05em 1px,1px 1px;
          background-repeat: no-repeat,no-repeat,repeat-x;
          background-position: 0 95%,100% 95%,0 95%;
        }
      }
    }
  }
  .pane-bundle-slogan .fieldable-panels-pane {
    background: $primary-color-en;
  }
  .btn-blue {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    padding: 12px 25px 10px 12px;
    vertical-align: middle;
    border: 0;
    font-family: $font-family--headers;
    font-size: rem-calc(15);
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: $primary-color-en;
    border-radius: 0;
    -webkit-appearance: none;
    cursor: pointer;
    transition: $transition;
    height: 40px;
    &:after {
      content: '';
      position: absolute;
      top: 15px;
      right: 10px;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 6px solid $white;
    }
    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
      background-color: lighten($primary-color-en, 7%);
    }
    &:active,
    &:focus {
      outline: 0;
    }
  }
  h2.pane-title,
  .node-score:not(.view-mode-search_result) h2,
  .node-composer.view-mode-square .composer-content h3 a,
  h1,
  .one-sidebar .main h2 {
    color: $primary-color-en;
  }
  .node-score:not(.view-mode-search_result) .field-name-bezetting-begeleiding {
    color: #111;
  }
  .panel-row.colored.darkblue {
    background: $primary-color-en url('../images/icons/application/bg-shape--dark-pink.svg') no-repeat 90% 50%;
  }
  .node-score:not(.view-mode-search_result) .field-name-field-composer,
  .one-sidebar .main h3,
  .panel-pane.pane-score-info h3,
  .panel-pane.related:not(.pane-news-related) h3,
  .node-composer .date-display-single,
  .view-questions .field-name-node-author-name-and-role a,
  h2 {
    color: $secondary-color-en;
  }
  .node-score:not(.view-mode-search_result).view-mode-square:hover {
    box-shadow: inset 0 -3px $primary-color-en;
  }
  #sliding-popup.sliding-popup-bottom {
    background-color: darken($primary-color-en,10%) !important;
  }
  .panel-row .panel-pane.colored.yellow {
    background-color: darken($secondary-color-en,7%);
    .field-name-field-link {
      &:after {
        border-left: 6px solid $secondary-color-en;
      }
      a {
        color: $secondary-color-en;
        &:hover, &:focus, &:active {
          color: $primary-color-en;
        }
      }
    }
  }
  .pane-bundle-slogan .field-name-field-slogan-link {
    a {
      color: $primary-color-en;
      &:after {
        border-left: 6px solid $primary-color-en;
      }
      &:hover, &:focus, &:active {
        color: $primary-color-en;
        &:after {
          border-left: 6px solid $primary-color-en;
        }
      }
    }
  }
  a {
    color: $primary-color-en;
    &:hover {
      color: darken($primary-color-en, 7%);
    }
  }
  .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text a:hover {
    color: darken($primary-color-en, 7%);
  }
  .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text:after {
    border-left: 6px solid $primary-color-en;
  }
  h3 {
    color: #111;
  }
  .field-name-field-qa h3:after, .pane-bundle-faq h3:after {
    border-top: 6px solid #111;
  }
  .field-name-field-qa .open h3, .pane-bundle-faq .open h3 {
    color: darken($secondary-color-en,7%);
  }
  .btn-white:after {
    border-left: 6px solid $primary-color-en;
  }
  .field-name-field-qa .open h3:after, .pane-bundle-faq .open h3:after {
    border-bottom: 6px solid $secondary-color-en;
    border-top: none;
  }
  .swiper-container .swiper-button-prev {
    background-image: url(../images/icons/application/swiper--prev--en.svg);
  }
  .swiper-container .swiper-button-next {
    background-image: url(../images/icons/application/swiper--next--en.svg);
  }
  .pane-views-exp-scores-page .views-exposed-widget label:not(.option) {
    color: #111 !important;
  }
  .pane-views-exp-scores-page .views-exposed-widget label:not(.option):after {
    border-top: 5px solid #111;
  }
  .page form:not(.ctools-use-modal-processed) input, .page form:not(.ctools-use-modal-processed) select, .page form:not(.ctools-use-modal-processed) textarea {
    color: #111;
  }
  .node-news.view-mode-square h3 a:hover {
    color: $primary-color-en;
  }
  .node-news.view-mode-square:hover {
    box-shadow: inset 0 -3px $primary-color-en;
  }
  .node-composer.view-mode-list .content-wrapper h3 a:hover {
    color: $primary-color-en;
  }
  .node-composer.view-mode-list:hover .content-wrapper {
    box-shadow: inset 0 -3px $primary-color-en;
  }
  .score-count {
    color: #111;
  }
  .download-list li:before, .links li:before, ul:not([class]) li:before {
    background-color: #111;
  }
  div.horizontal-tabs li.horizontal-tab-button a, div.horizontal-tabs li.horizontal-tab-button a strong {
    color: #111;
  }
  div.horizontal-tabs li.horizontal-tab-button.selected a strong, div.horizontal-tabs li.horizontal-tab-button a.active strong {
    color: $primary-color-en;
  }
  div.horizontal-tabs li.horizontal-tab-button.selected a:before, div.horizontal-tabs li.horizontal-tab-button a.active:before {
    background: $primary-color-en;
  }
  div.horizontal-tabs li.horizontal-tab-button.selected a:after, div.horizontal-tabs li.horizontal-tab-button a.active:after {
    border-top: 7px solid $primary-color-en;
  }
  .page form:not(.ctools-use-modal-processed) .js-hide {
    display: none;
  }
  .node-score:not(.view-mode-search_result).view-mode-square:hover h2 {
    color: $primary-color-en;
  }
  .node-news .field-name-post-date {
    color: darken($secondary-color-en,7%);
  }
  div.horizontal-tabs li.horizontal-tab-button a:active, div.horizontal-tabs li.horizontal-tab-button a:focus, div.horizontal-tabs li.horizontal-tab-button a:hover {
    color: $primary-color-en;
  }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-bef-checkbox label, form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-checkbox label {
    color: #111;
  }*/
}