.paragraphs-item-paragraph-manual-list   {
  .col {
    margin-bottom: 30px;
  }
  margin-bottom: 15px; // 45 from every paragraph - 30 from the col
  .field-name-field-link {
    @extend.btn-blue;
    a {
      color: $white;
      text-decoration: none;
    }
  }
}

.paragraphs-item-paragraph-manual-list + .entity-paragraphs-item {
  margin-top: 15px; // 45 from every paragraph - 30 from the col
}
