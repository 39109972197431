.breadcrumbs {
	max-width: $max-content-width;
 	margin: 20px auto 10px auto;
}
.breadcrumbs__item {
	position: relative;
	padding-right: 20px;
	display: inline-block;
	&:not(:last-child):after {
		content: '';
		position: absolute;
		background: url('../images/icons/application/arrow--right.svg') no-repeat 100% 50%;
		width: 8px;
		height: 14px;
		right: 5px;
		top: 7px;
	}
}
.breadcrumbs__item--link {
	font-weight: bold;
	font-size: rem-calc(15);
	color: $black;
	text-decoration: none;
	white-space: nowrap;
}

a.breadcrumbs__item--link {
	&:hover,
	&:focus {
		color: $primary-color;
	}
}
