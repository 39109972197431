div.horizontal-tabs {
  margin: 0 0 1em 0; /* LTR */
  position: relative; /* IE6/7 */
  border: none;
  padding: 0;
  @include media-breakpoint-up(sm) {
    margin: 30px 0;
  }
  ul.horizontal-tabs-list {
    margin: 0;
    border: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    height: auto;
    clear: both;
    position: relative;
    display: flex;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $border-color;
      width: 100%;
      height: 1px;
      z-index: 0;
    }
  }
  li.horizontal-tab-button {
    padding: 0 1px 0;
    margin: 0 15px 0 0;
    color: $secondary-color;
    &.selected a,
    a.active {
      position: relative;
      z-index: 1;
      color: $secondary-color;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        z-index: 2;
        background: $secondary-color;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -7px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 7px solid $secondary-color;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 2;
      }
      strong {
        color: $secondary-color;
      }
    }
    a,
    a strong {
      display: block;
      -webkit-transition: none;
      -moz-transition: none ;
      -ms-transition: none ;
      -o-transition: none ;
      transition: none ;
      text-decoration: none;
      text-transform: uppercase;
      font-size: rem-calc(16);
      line-height: rem-calc(16);
      font-weight: 700;
      color: #7A959D;
    }
    a {
      padding: 3px 0 5px 0;
      &:hover, &:focus, &:active {
        background: transparent;
        color: $secondary-color;
        strong {
          color: $secondary-color;
        }
      }
    }
  }
}

.horizontal-tabs fieldset.horizontal-tabs-pane {
  padding: 15px 0;
  margin: 0;
  border: 0;
}

fieldset.horizontal-tabs-pane > legend,
fieldset.vertical-tabs-pane fieldset.horizontal-tabs-pane > legend {
  display: none;
}

/**
 * tab content
 */
div.field-group-htabs-wrapper .field-group-format-wrapper {
  clear: both;
  padding: 0 0 0.6em;
}
/*hide*/
.horizontal-tabs .horizontal-tab-hidden {
  display: block;
  position: absolute;
  top: -100000px;
  width: 100%;
}
