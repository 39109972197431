.social-nav {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: 0;
  li {
    list-style-type: none;
  }
}

.main-header {
  .social-nav__item {
    font-size: 0;
    text-indent: -9999px;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    &.facebook {
      background-image: url('../images/icons/social/facebook-header.svg');
      width: 25px;
      height: 50px;
    }
    &.youtube {
      background-image: url('../images/icons/social/youtube-header.svg');
      width: 32px;
      height: 50px;
    }
    &.soundcloud {
      background-image: url('../images/icons/social/soundcloud-header.svg');
      width: 62px;
      height: 50px;
    }
  }
}

.footer-top {
  .social-nav__item {
    font-size: 0;
    text-indent: -9999px;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    &.facebook {
      background-image: url('../images/icons/social/facebook.svg');
      width: 25px;
      height: 50px;
    }
    &.youtube {
      background-image: url('../images/icons/social/youtube.svg');
      width: 40px;
      height: 50px;
    }
    &.soundcloud {
      background-image: url('../images/icons/social/soundcloud.svg');
      width: 62px;
      height: 50px;
    }
    &.musescore {
      background-image: url('../images/icons/social/musescore.svg');
      width: 50px;
      height: 50px;
    }
    &.twitter {
      background-image: url('../images/icons/social/twitter.svg');
      width: 40px;
      height: 50px;
    }
  }
}

.pane-social-icons {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0;
  }
  h5 {
    color: $quaternary-color;
    font-size: rem-calc(17);
    font-weight: 400;
    display: inline-block;
    margin: 0;
  }
  .social-nav {
    padding-left: 10px;
  }
  .social-nav__item {
    font-size: 0;
    text-indent: -9999px;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    &.-facebook {
      background-image: url('../images/icons/social/facebook-share.svg');
      width: 25px;
      height: 50px;
    }
    &.-mail {
      background-image: url('../images/icons/social/mail-share.svg');
      width: 40px;
      height: 50px;
    }
    &.-twitter {
      background-image: url('../images/icons/social/twitter-share.svg');
      width: 40px;
      height: 50px;
    }
  }
}