.pane-pane-messages {
  @include media-breakpoint-down(sm) {
    padding-left: $phone-padding;
    padding-right: $phone-padding;
  }
  @include media-breakpoint-up(sm) {
    width: $max-site-width;
    margin: 0 auto;
    padding-left: $tablet-padding;
    padding-right: $tablet-padding;
  }
  @include media-breakpoint-up(lg) {
    padding-left: $desktop-padding;
    padding-right: $desktop-padding;
  }
}