.panel-pane.pane-bundle-video {
  max-width: $max-content-width;
  margin-left: auto;
  margin-right: auto;
  .fieldable-panels-pane {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    iframe {
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
  }
}