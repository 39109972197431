.page-content > .pane-newsletter {
  margin-top: 30px;
  @include media-breakpoint-down(sm) {
    padding-left: $phone-padding;
    padding-right: $phone-padding;
  }
  @include media-breakpoint-up(sm) {
    max-width: $max-site-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $tablet-padding;
    padding-right: $tablet-padding;
  }
  @include media-breakpoint-up(lg) {
    padding-left: $desktop-padding;
    padding-right: $desktop-padding;
  }
}

.pane-bundle-newsletter,
.pane-newsletter {
  .newsletter-wrapper {
    background: #E7F2F4 url('../images/icons/application/bg-shape--light.svg') no-repeat 90% 50%;
    background-size: 110% auto;
    @include media-breakpoint-down(md) {
      padding: 25px;
    }
    @include media-breakpoint-up(md) {
      padding: 50px;
    }
    .pane-title {
      text-transform: none;
      font-weight: 600;
      @include media-breakpoint-down(md) {
        font-size: rem-calc(30);
      }
      @include media-breakpoint-up(md) {
        font-size: rem-calc(36);
      }
    }
  }
  .field-name-field-newsletter-benefits,
  p {
    max-width: 780px;
  }
  .campaignmonitor-subscribe-form {
    div:not([class]) {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
      }
      .form-item {
        margin: 0 !important;
        @include media-breakpoint-up(md) {
          flex: 1 0 50%;
          max-width: 50%;
        }
      }
      input {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        box-shadow: none !important;
      }
      input.button.form-submit {
        padding: 12px !important;
        height: 44px !important;
        @include media-breakpoint-down(md) {
          margin-top: 10px !important;
        }
        @include media-breakpoint-up(md) {
          margin-left: 15px;
        }
      }
      label {
        display: none !important;
      }
    }
  }
}