.node-type-panopoly-landing-page {
  .header {
    @include media-breakpoint-up(md) {
      padding: 30px 0;
    }
  }
  .site-logo {
    @include media-breakpoint-down(sm) {
      img {
        width: 77px;
        height: 50px;
      }
    }
    @include media-breakpoint-up(sm) {
      img {
        width: 153px;
        height: 100px;
      }
    }
  }
  /* homepage visit the library */
  .panel-row {
    .panel-pane {
      &.colored {
        &.yellow {
          padding: 0;
        }
      }
    }
  }
  /* homepage composers */
  .panel-row {
    .col-sm-6 {
      .panel-pane {
        &.colored {
          &.lightblue {
            padding: 0;
          }
        }
      }
    }
  }
}

