.user-login-form {
  max-width: $max-content-width;
  margin: 60px auto;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 2px solid $border-color;
    -webkit-text-fill-color: $text-color;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.page form:not(.ctools-use-modal-processed) {
  ::-webkit-input-placeholder {
    color: $black;
  }

  ::-moz-placeholder {
    color: $black;
  }

  :-ms-input-placeholder {
    color: $black;
  }

  :-moz-placeholder {
    color: $black;
  }
  ::placeholder {
    color: $black;
  }
  .form-item {
    margin: 10px 0;
  }
  /*fieldset legend + .fieldset-wrapper {
    .form-item:only-child {
      margin: 0;
    }
  }*/
  .fieldset-legend {
    font-size: rem-calc(24);
    a {
      text-decoration: none;
    }
  }
  fieldset {
    border: none;
    padding: 0;
    margin-bottom: 1em;
  }
  fieldset.collapsed {
    height: 2em;
  }
  .fieldset-description p {
    margin-top: 0;
  }
  label, legend {
    position: relative;
    display: block;
    font-weight: 600;
    font-family: $font-family--base;
    font-size: rem-calc(16);
    color: $primary-color;
    &.form-required:after {
      color: $black;
      content: ' *';
      display: inline;
      font-weight: 400;
      font-size: rem-calc(14);
    }
  }
  input, textarea, select {
    margin-top: 1px;
    padding: 10px 12px;
    background-color: $white;
    border: 1px solid $border-color;
    font-family: $font-family--base;
    font-size: rem-calc(15);
    color: $text-color;
    border-radius: 0;
    display: block;
    box-shadow: inset 0 5px 5px -5px rgba(0,0,0,.15);
    transition: $transition;
    &:focus {
      outline: none;
      border-color: $tertiary-color;
    }
    &.invalid {
      border-color: red;
      animation: shake 1s linear;
    }
  }
  .form-text, .form-textarea, .form-email, .form-select {
    width: 100%;
  }
  .form-number {
    min-width: 150px;
  }
  .form-type-select {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 12px;
      bottom: 20px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $black;
      pointer-events: none;
    }
  }
  .form-select, .form-number {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
  }
  .form-select.day,
  .form-select.month,
  .form-select.year {
    width: auto;
    &::after {
      display: none;
    }
  }
  .webform-datepicker .form-type-select {
    display: inline-block;
    &::after {
      display: none;
    }
  }
  .webform-datepicker .webform-calendar {
    display: inline-block;
    border: 0 none;
    box-shadow: none;
  }
  .webform-address--wrapper {
    .fieldset-wrapper {
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
      }
      /* street */
      .form-item-adres-address {
        @include media-breakpoint-up(sm) {
          flex: 1 0 70%;
          max-width: 70%;
          padding-right: 20px;
        }
      }
      /* street number */
      .form-item-adres-address-2 {
        @include media-breakpoint-up(sm) {
          flex: 1 0 30%;
          max-width: 30%;
        }
      }
      /* city */
      .form-item-adres-city {
        @include media-breakpoint-up(sm) {
          flex: 1 0 70%;
          max-width: 70%;
          padding-right: 20px;
        }
      }
      /* zip */
      .form-item-adres-postal-code {
        @include media-breakpoint-up(sm) {
          flex: 1 0 30%;
          max-width: 30%;
        }
      }
    }
  }
  .description {
    font-size: rem-calc(13);
    margin-top: 3px;
    //color: lighten($text-color,25%);
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .button {
    @extend.btn-blue;
  }
  .js-hide {
    display: none;
  }
}

.page input[type=number]::-webkit-inner-spin-button, input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.form-actions {
  margin: 0;
  position: relative;
  text-align: right;
  &:after {
    content: '';
    position: absolute;
    top: 25px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid $white;
  }
  .button {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    padding: 12px 25px 10px 12px;
    vertical-align: middle;
    border: 0;
    font-family: $font-family--headers;
    font-size: rem-calc(15);
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: $tertiary-color;
    border-radius: 0;
    -webkit-appearance: none;
    cursor: pointer;
    transition: $transition;
    height: 40px;
    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
      background-color: lighten($tertiary-color, 7%);
    }
    &:active,
    &:focus {
      outline: 0;
    }
  }
}

.page {
  form.search-form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) {
    max-width: $max-content-width;
    input.button.form-submit {
      position: relative;
      display: inline-block;
      margin: 10px 0;
      padding: 12px;
      vertical-align: middle;
      border: 0;
      font-family: $font-family--headers;
      font-size: rem-calc(15);
      font-weight: bold;
      line-height: 1.1;
      text-transform: uppercase;
      color: $white;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      background: $tertiary-color;
      border-radius: 0;
      -webkit-appearance: none;
      cursor: pointer;
      transition: $transition;
      height: 40px;
      box-shadow: none;
      &:after {
        content: '';
        position: absolute;
        top: 15px;
        right: 10px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 6px solid $white;
      }
      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
        background-color: lighten($tertiary-color, 7%);
      }
      &:active,
      &:focus {
        outline: 0;
      }
    }
  }
}

/* Chosen fields */
.chosen-container {
  width: 100% !important;
  .chosen-single,
  .chosen-choices {
    border: 1px solid $border-color;
    border-radius: 0;
    background: #fff;
    padding: 5px 15px;
    height: 48px;
    line-height: 40px;
    color: $text-color;
    font-size: $text-size--base;
    box-shadow: inset 0 5px 5px -5px rgba(0,0,0,.15);
    div b {
      background: none !important;
    }
  }
  .chosen-drop {
    border-color: $border-color;
    box-shadow: none;
    border-radius: 0;
    font-size: 15px;
    line-height: 28px;
    background: #fff;
  }
  .chosen-results li {
    padding: 8px 6px;
    &.highlighted {
      background: none;
      color: $primary-color;
    }
  }
}
.chosen-container-active.chosen-with-drop .chosen-single {
  background: #fff;
  border: 1px solid $border-color;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -16px 15px;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  color: $text-color;
  font-size: $text-size--base;
  font-family: $font-family--base;
}

.chosen-container .chosen-choices,
.chosen-container .chosen-single {
  height: 46px;
  box-shadow: none;
  border: 1px solid $border-color;
  font-family: $font-family--base;
  font-size: rem-calc(15);
  font-weight: 500;
  color: $text-color;
  padding: 3px 15px;
}

.chosen-container-single .chosen-single {
  box-shadow: none;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 12px;
    bottom: 18px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #185c71;
    pointer-events: none;
  }
}

.chosen-container-active.chosen-with-drop .chosen-single {
  box-shadow: none;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 12px;
    bottom: 18px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #185c71;
    pointer-events: none;
  }
}

.chosen-container-single .chosen-search input[type="text"] {
  border: 1px solid $border-color;
  height: 40px;
  background: url('../images/icons/application/search--dark.svg') no-repeat 7px 50%;
  padding-left: 35px;
  &:focus {
    outline: none;
    border-color: $tertiary-color;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi) {
  .chosen-container-single .chosen-search input[type="text"] {
    background-image: url('../images/icons/application/search--dark.svg') !important;
    background-position: 7px 50% !important;
    background-size: auto !important;
  }
}

.chosen-container .chosen-results li.active-result {
  font-size: rem-calc(15);
  &:hover {
    color: $secondary-color;
  }
}

#autocomplete {
  background-color: $white;
  border: 1px solid $border-color;
  ul:not([class]) {
    padding: 5px 0;
    li {
      color: $text-color;
      padding: 1px 12px;
      cursor: pointer;
      &:before {
        display: none;
      }
      &.selected {
        background-color: $primary-color;
        color: $white;
      }
    }
  }
}

/* hide arrow */
html.js fieldset.collapsible .fieldset-legend {
 background: none;
  padding-left: 0;
}