.paragraphs-item-paragraph-gallery  {
  .field-name-ds-function-gallery-popup{
    margin-left: -5px;
    margin-right: -5px;
    .gallery-item {
      width: 25%;
      float: left;
      padding: 0 5px;
      position: relative;
      -webkit-transition: opacity 0.2s;
      -moz-transition: opacity 0.2s;
      -ms-transition: opacity 0.2s;
      -o-transition: opacity 0.2s;
      transition: opacity 0.2s;

      &:focus,
      &:hover {
        opacity: 0.8;
      }
      .more {
        background: rgba(0,0,0, 0.3);
        opacity: 0.7;
        position: absolute;
        left: 5px;
        right: 5px;
        top: 0;
        bottom: 0;
        color: #fff;
        text-align: center;
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        -ms-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        transition: opacity 0.2s;
        &::before {
          content: "+";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          text-align: center;
          width: 100%;
          font-size: 40px;
          font-weight: 100;
        }
        &:hover,
        &:focus {
          opacity:0.5;
        }
      }
    }
  }

}