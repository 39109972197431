.paragraphs-item-paragraph-quote  {
  background-color: #E7F2F4;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    padding: 25px 30px 30px 30px;
    &:before {
      bottom: -20px;
      right: 20px;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 35px 30px 40px 30px;
    &:before {
      bottom: -20px;
      right: 100px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    background: url('../images/icons/application/quote-marks.svg') no-repeat;
    width: 107px;
    height: 90px;
  }
  .field-name-field-text {
    font-size: rem-calc(22);
    line-height: 27px;
    @include media-breakpoint-up(sm) {
      max-width: $max-content-width;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      margin: 0;
      font-weight: 500;
    }
  }
  .field-name-field-quotee {
    margin-top: 10px;
    @include media-breakpoint-up(sm) {
      max-width: $max-content-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
}