.node-composer {
  .meta {
    .field-name-field-data-of-birth,
    .field-name-field-date-death {
      display: inline-block;
    }
    .field-name-field-date-death {
      position: relative;
      padding-left: 15px;
      &:after {
        content: ' - ';
        position: absolute;
        top: -11px;
        left: 4px;
        color: $tertiary-color;
        font-weight: 700;
        font-size: rem-calc(15);
      }
    }
  }
  .date-display-single {
    margin-top: -10px;
  }
  .date-display-single {
    display: block;
    color: $tertiary-color;
    font-weight: 700;
    font-size: rem-calc(15);
  }
  &.view-mode-search_result {
    max-width: $max-content-width;
    h2 {
      color: $primary-color;
      font-size: rem-calc(24);
      margin: 25px 0 5px 0;
    }
  }
  &.view-mode-full {
  }
  &.view-mode-list {
    position: relative;
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
    &:hover {
      .content-wrapper {
        cursor: hover;
        box-shadow: inset 0px -3px $secondary-color;
        h3 {
          color: $secondary-color;
        }
      }
    }
    .field-name-field-photo {
      position: absolute;
      top: 15px;
      left: 0;
      img {
        max-width: 100px;
        height: auto;
        vertical-align: top;
      }
    }
    .content-wrapper {
      border: 1px solid $border-color;
      padding: 20px 20px 20px 100px;
      margin-left: 10px;
      min-height: 122px;
      background-color: $white;
      h3 {
        margin: 5px 0 0 0;
        a {
          text-decoration: none;
          &:hover {
            color: $secondary-color;
          }
        }
      }
    }
  }
  &.view-mode-square {
    position: relative;
    height: 100%;
    background-color: $white;
    .composer-image {
      @include media-breakpoint-down(md) {
        margin-left: 20px;
      }
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 50px;
        left: -50px;
        width: 180px;
        height: auto;
      }
      img {
        vertical-align: top;
        max-width: 180px;
        height: auto;
      }
    }
    .composer-content {
      background-color: #E7F2F4;
      height: 100%;
      @include media-breakpoint-down(md) {
        padding: 60px 20px 20px 20px;
        margin-top: -40px;
      }
      @include media-breakpoint-up(md) {
        padding: 50px 50px 50px 160px;
      }
      h3 {
        margin-top: 0;
        margin-bottom: 15px;
        @include media-breakpoint-down(md) {
          font-size: rem-calc(30);
        }
        @include media-breakpoint-up(md) {
          font-size: rem-calc(36);
        }
        a {
          text-decoration: none;
          color: $primary-color;
        }
      }
      .field-name-field-short-description {
        margin: 15px 0;
      }
      .field-name-node-link {
        @extend.btn-blue;
        a {
          margin-top: 15px;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}

.panel-row {
  .col-sm-6:nth-child(1) {
    .node-composer.view-mode-square {
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }
      @include media-breakpoint-up(md) {
        margin-left: 50px;
        margin-right: 30px;
      }
    }
  }
  .col-sm-6:nth-child(2) {
    .node-composer.view-mode-square {
      @include media-breakpoint-up(md) {
        margin-left: 80px;
      }
    }
  }
  .col-sm-6 {
    .panel-pane {
      height: 100%;
      @include media-breakpoint-up(md) {
        .fieldable-panels-pane {
          height: 100%;
        }
      }
    }
  }
}

.pane-composer-spotlight {
  .node-composer {
    &.view-mode-square {
      .composer-image {
        position: relative;
        top: auto;
        left: auto;
        @include media-breakpoint-down(md) {
          margin-left: 20px;
        }
        @include media-breakpoint-up(md) {
          margin-left: 50px;
        }
      }
      .composer-content {
        margin-top: -120px;
        @include media-breakpoint-down(md) {
          padding: 140px 20px 20px 20px;
        }
        @include media-breakpoint-up(md) {
          padding: 140px 50px 30px 50px;
        }
      }
    }
  }
}

.view-composer {
  .filters {
    background: #E7F2F4 url('../images/icons/application/bg-shape--light.svg') no-repeat 90% 50%;
    background-size: 110% auto;
    padding: 20px;
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .views-exposed-form .views-exposed-widget {
      float: none;
      padding: 0;
    }
    #views-exposed-form-composer-page {
      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }
      @include media-breakpoint-up(md) {
        flex: 1 0 300px;
        max-width: 300px;
        margin-bottom: 0;
      }
      .views-exposed-widgets {
        margin: 0;
        position: relative;
        .form-item {
          margin: 0;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 13px;
            left: 15px;
            background: url('../images/icons/application/search--dark.svg') no-repeat 90% 50%;
            width: 20px;
            height: 20px;
          }
          .form-text {
            padding: 10px 12px 10px 42px;
          }
        }
      }
      .views-submit-button {
        position: absolute;
        top: 2px;
        right: 2px;
        background: transparent url('../images/icons/application/arrow--right--dark.svg') no-repeat 60% 50%;
        width: 40px;
        height: 40px;
        text-indent: -9999px;
      }
    }
    .item-list {
      @include media-breakpoint-down(sm) {
        display: none;
      }
      @include media-breakpoint-up(md) {
        flex: 1 0 calc(100%-300px);
        max-width: calc(100%-300px);
        padding-left: 25px;
      }
      .views-summary {
        list-style-type: none;
        li {
          display: inline-block;
          margin: 0;
          padding: 0;
          a {
            padding: 5px;
            text-decoration: none;
          }
        }
      }
    }
  }
  .views-content {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }
    .views-row {
      @include media-breakpoint-up(sm) {
        flex: 1 0 50%;
        max-width: 50%;
        padding-left: 15px;
        padding-right: 15px;
      }
      @include media-breakpoint-up(md) {
        flex: 1 0 33.33%;
        max-width: 33.33%;
      }
      @include media-breakpoint-down(xs) {
        &:first-child {
          .node-composer.view-mode-list {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.field-name-manual-list {
  .node-composer.view-mode-list {
    &:nth-child(3n+1) {
      @include media-breakpoint-up(md) {
        display: inline-block;
        width: calc(33% - 10px);
        margin-right: 10px;
      }
    }
    &:nth-child(3n+2) {
      @include media-breakpoint-up(md) {
        display: inline-block;
        width: calc(33% - 10px);
        margin-left: 10px;
      }
    }
    &:nth-child(3n+3) {
      @include media-breakpoint-up(md) {
        display: inline-block;
        width: calc(33% - 10px);
        margin-left: 10px;
      }
    }
  }
}