.arrow-link {
  color: $primary-color;
  font-weight: bold;
  font-size: rem-calc(15);
  text-decoration: none;
  transition: $transition;
  border: none;
  &:hover {
    color: $primary-color;
  }
  &.-left {
    background: url('../images/icons/application/arrow--left.svg') no-repeat 0% 50%;
    padding-left: 17px;
    &:hover {
      padding-left: 21px;
    }
  }
  &.-right {
    background: url('../images/icons/application/arrow--right.svg') no-repeat 100% 50%;
    padding-right: 17px;
    &:hover {
      padding-right: 21px;
    }
  }
}

.arrow-link--left {
  color: $secondary-color;
  font-weight: bold;
  font-family: $font-family--headers;
  font-size: rem-calc(15);
  text-decoration: none;
  transition: $transition;
  border: none;
  background: url('../images/icons/application/arrow--left.svg') no-repeat 0% 50%;
  padding-left: 17px;
  &:hover {
    color: $primary-color;
    padding-left: 21px;
  }
}

.arrow-link--right {
  color: $secondary-color;
  font-weight: bold;
  font-family: $font-family--headers;
  font-size: rem-calc(15);
  text-decoration: none;
  transition: $transition;
  border: none;
  background: url('../images/icons/application/arrow--right.svg') no-repeat 100% 50%;
  padding-right: 17px;
  &:hover {
    color: $primary-color;
    padding-right: 21px;
  }
}