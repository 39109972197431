.ajax-progress {
	display: inline-block;
	padding: 1px 5px 2px 5px;
}
[dir="rtl"] .ajax-progress {
	float: right;
}
.ajax-progress-throbber .throbber {
	background: transparent url(../../../images/core/throbber-active.gif) no-repeat 0 center;
	display: inline;
	padding: 1px 5px 2px;
}
.ajax-progress-throbber .message {
	display: inline;
	padding: 1px 5px 2px;
}
tr .ajax-progress-throbber .throbber {
	margin: 0 2px;
}
.ajax-progress-bar {
	width: 16em;
}

/* Full screen throbber */
.ajax-progress-fullscreen {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255,255,255,.55);
	background-image: url('../images/icons/application/loader.gif');
	background-position: 50% 40%;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: 5;
	@include media-breakpoint-down(sm) {
		top: 75px;
	}
	@include media-breakpoint-up(md) {
		top: 104px;
	}
}
[dir="rtl"] .ajax-progress-fullscreen {
	left: auto;
	right: 49%;
}

/**
 * @file
 * Visual styles for animated throbber.
 *
 * @see autocomplete.js
 */

.js input.form-autocomplete {
	background-image: url(../../../images/core/throbber-inactive.png);
	background-position: 100% center; /* LTR */
	background-repeat: no-repeat;
}
.js[dir="rtl"] input.form-autocomplete {
	background-position: 0% center;
}
.js input.form-autocomplete.ui-autocomplete-loading {
	background-image: url(../../../images/core/throbber-active.gif);
	background-position: 100% center; /* LTR */
}
.js[dir="rtl"] input.form-autocomplete.ui-autocomplete-loading {
	background-position: 0% center;
}