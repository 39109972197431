.page {
  #user-pass-reset {
    max-width: $max-content-width;
    margin: 0 auto;
  }
  #edit-account {
    max-width: $max-content-width;
  }
  /* Profile fieldset */
  #edit-profile-main-field-music-profile,
  #edit-profile-main {
    table th, table td {
      padding: 0 0 5px 0;
    }
    table tr th {
      background-color: transparent;
      border: none;
    }
    tr.odd {
      background-color: transparent;
      border: none;
    }
    tbody {
      border: none;
    }
    form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button {
      position: relative;
      display: inline-block;
      margin: 10px 0;
      padding: 12px;
      vertical-align: middle;
      border: 0;
      font-family: $font-family--headers;
      font-size: rem-calc(15);
      font-weight: bold;
      line-height: 1.1;
      text-transform: uppercase;
      color: $white;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      background: $tertiary-color;
      border-radius: 0;
      -webkit-appearance: none;
      cursor: pointer;
      transition: $transition;
      height: 40px;
      box-shadow: none;
      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
        background-color: lighten($tertiary-color, 7%);
      }
      &:active,
      &:focus {
        outline: 0;
      }
    }
  }
}

/* User forms */
.page-user-edit .field-type-field-collection,
.page-user-register .field-type-field-collection {
  .field-multiple-table {
    tr,
    tr td {
      background: transparent !important;
      border-radius: 0 !important;
    }
    td > *,
    td .ajax-new-content > * {
      margin-bottom: 5px;
    }
    @include media-breakpoint-up(md) {
      .form-wrapper {
        margin: 0 1em 0 0;
      }
      .form-text {
        max-width: 220px;
      }
      td > *,
      td .ajax-new-content > * {
        float: left;
        margin-bottom: 0;
      }
      tr {
        padding: 0;
        border: 0 none;
      }
    }
    td {
      padding: 10px 0;
      .form-item {
        margin-top: 0;
      }
    }
    td input.button {
      border: 0 none;
      padding: 0;
      box-shadow: none;
      background: transparent url(../images/icons/application/delete.png) no-repeat center center;
      background-size: 15px 17px;
      text-indent: -10000px;
      overflow: hidden;
      width: 30px;
      height: 30px;
      @include media-breakpoint-up(md) {
        margin: 29px 0 0 0;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
  .button.field-add-more-submit {
    padding: 10px 10px 10px 0 ;
    background: transparent;
    text-decoration: underline;
    color:$primary-color;
    font-weight: normal;
    box-shadow: none;
    text-transform: none;
    &:hover,
    &:focus {
      color: #027b91;
      background-color: transparent;
    }
  }
  .order-weight {
    display: none;
  }
  // TODO: make more generic.
  .form-item-profile-main-field-music-profile-und-0--weight::after,
  .form-item-profile-main-field-music-profile-und-1--weight::after,
  .form-item-profile-main-field-music-profile-und-2--weight::after,
  .form-item-profile-main-field-music-profile-und-3--weight::after,
  .form-item-profile-main-field-music-profile-und-4--weight::after,
  .form-item-profile-main-field-music-profile-und-5--weight::after,
  .form-item-profile-main-field-music-profile-und-6--weight::after,
  .form-item-profile-main-field-music-profile-und-7--weight::after,
  .form-item-profile-main-field-music-profile-und-8--weight::after,
  .form-item-profile-main-field-music-profile-und-9--weight::after,
  .form-item-profile-main-field-music-profile-und-10--weight::after,
  .form-item-profile-main-field-music-profile-und-11--weight::after {
    display: none !important;
  }
}

/* Registration form */
.page-user-register,
.page-user-edit {
  .page-content form {
    max-width: $max-content-width;
    .profile-name {
      display: flex;
      margin: 0 -10px;
      > div {
        width: calc(50% - 20px);
        margin: 0 10px;
      }
    }
    .form-item-field-country-und {
      margin: 10px 0 20px !important;
    }
  }
}

.group-contact {
  display: flex;
  flex-wrap: wrap;
  h3 {
    width: 100%;
  }
  > div {
    width: calc(50% - 15px);
    margin-right: 15px;
  }
}

/* User detail page */
.view-mode-account .field {
  margin: 0;

  .field-collection-view {
    border-bottom: 0 none;
    border-top: 1px solid #f4f2f1;
    margin: 1em 0 0.5em;
    padding: 1em 0 0.5em;
  }
}
.pane-user-profile > .pane-inner > h3 {
  display: none;
}
.pane-user-profile .field-name-ds-user-picture {
  margin-bottom: 1em;
}
.pane-user-profile .label-inline {
  font-weight: bold;
}
.field-name-user-edit a {
  @extend .button;
}


/**
 * Password strength indicator.
 */
.password-strength {
  width: 17em;
  float: right;  /* LTR */
}
.password-strength-title {
  display: inline;
}
.password-strength-text {
  float: right; /* LTR */
  font-weight: bold;
}
.password-indicator {
  background-color: #C4C4C4;
  height: 0.3em;
  width: 100%;
}
.password-indicator div {
  height: 100%;
  width: 0%;
  background-color: #47C965;
}
input.password-confirm,
input.password-field {
  width: 16em;
  margin-bottom: 0.4em;
}
div.password-confirm {
  float: right;  /* LTR */
  visibility: hidden;
  width: 17em;
}
div.form-item div.password-suggestions {
  padding: 0.2em 0.5em;
  margin: 0.7em 0;
  width: 38.5em;
  border: 1px solid #B4B4B4;
}
div.password-suggestions ul {
  margin-bottom: 0;
}
.confirm-parent,
.password-parent {
  clear: left; /* LTR */
  margin: 0;
  width: 750px;
}

/* Registration */
.page-bijna-geregistreerd,
.page-user-reset {
  .pane-bundle-text {
    max-width: none;
  }
}
.field-name-field-user-picture {
  input.form-file {
    display: inline-block !important;
  }
}