/* 32px */
/* blue */
/* red */
/* light blue */
/* blue breadcrumbs, pager */
/* ==========================================================================
   Normalize
   ========================================================================== */
*,
:after,
:before {
  box-sizing: inherit; }

/* Html & body
   ========================================================================== */
html {
  height: 100%;
  scroll-behavior: smooth;
  box-sizing: border-box; }
  @media (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto; } }

body {
  min-height: 100%;
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  border: 0; }

figure {
  margin: 0; }
  figure img {
    display: block; }

svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  margin: 0;
  padding: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/* ==========================================================================
   Scaffolding - Rem Calc
   ========================================================================== */
/* Strip Unit
   ========================================================================== */
/* Convert to rem
   ========================================================================== */
/* Rem Calc
   ========================================================================== */
/* ==========================================================================
   Scaffolding - Breakpoints
   ========================================================================== */
/* Next
   ========================================================================== */
/* Min
   ========================================================================== */
/* Max
   ========================================================================== */
/* Up
   ========================================================================== */
/* Down
   ========================================================================== */
/* Only
   ========================================================================== */
/* Between
   ========================================================================== */
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 550px) {
    .container {
      width: 100%;
      max-width: 100%; } }
  @media (min-width: 1100px) {
    .container {
      width: 100%;
      max-width: 100%; } }
  @media (min-width: 1150px) {
    .container {
      width: 100%;
      max-width: 100%; } }
  @media (min-width: 1900px) {
    .container {
      width: 100%;
      max-width: 100%; } }

.row {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -webkit-flex-wrap: wrap; }
  @media (min-width: 280px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media (min-width: 550px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media (min-width: 1100px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media (min-width: 1150px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media (min-width: 1900px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }

.col {
  position: relative;
  flex-grow: 1;
  flex-basis: 100%;
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: 1px; }
  .col.-centered {
    margin: 0 auto; }
  @media (min-width: 280px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 550px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 1100px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 1150px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 1900px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }

@media (min-width: 280px) {
  .col-xs-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-xs-1 {
    margin-left: 8.33333%; }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-xs-2 {
    margin-left: 16.66667%; }
  .col-xs-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-xs-3 {
    margin-left: 25%; }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-xs-4 {
    margin-left: 33.33333%; }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-xs-5 {
    margin-left: 41.66667%; }
  .col-xs-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-xs-6 {
    margin-left: 50%; }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-xs-7 {
    margin-left: 58.33333%; }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-xs-8 {
    margin-left: 66.66667%; }
  .col-xs-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-xs-9 {
    margin-left: 75%; }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-xs-10 {
    margin-left: 83.33333%; }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-xs-11 {
    margin-left: 91.66667%; }
  .col-xs-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-xs-12 {
    margin-left: 100%; } }

@media (min-width: 550px) {
  .col-sm-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-sm-1 {
    margin-left: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-sm-2 {
    margin-left: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-sm-3 {
    margin-left: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-sm-4 {
    margin-left: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-sm-5 {
    margin-left: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-sm-6 {
    margin-left: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-sm-7 {
    margin-left: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-sm-8 {
    margin-left: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-sm-9 {
    margin-left: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-sm-10 {
    margin-left: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-sm-11 {
    margin-left: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-sm-12 {
    margin-left: 100%; } }

@media (min-width: 1100px) {
  .col-md-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-md-1 {
    margin-left: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-md-2 {
    margin-left: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-md-3 {
    margin-left: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-md-4 {
    margin-left: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-md-5 {
    margin-left: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-md-6 {
    margin-left: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-md-7 {
    margin-left: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-md-8 {
    margin-left: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-md-9 {
    margin-left: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-md-10 {
    margin-left: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-md-11 {
    margin-left: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-md-12 {
    margin-left: 100%; } }

@media (min-width: 1150px) {
  .col-lg-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-lg-1 {
    margin-left: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-lg-2 {
    margin-left: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-lg-3 {
    margin-left: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-lg-4 {
    margin-left: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-lg-5 {
    margin-left: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-lg-6 {
    margin-left: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-lg-7 {
    margin-left: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-lg-8 {
    margin-left: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-lg-9 {
    margin-left: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-lg-10 {
    margin-left: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-lg-11 {
    margin-left: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-lg-12 {
    margin-left: 100%; } }

@media (min-width: 1900px) {
  .col-xl-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-xl-1 {
    margin-left: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-xl-2 {
    margin-left: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-xl-3 {
    margin-left: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-xl-4 {
    margin-left: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-xl-5 {
    margin-left: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-xl-6 {
    margin-left: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-xl-7 {
    margin-left: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-xl-8 {
    margin-left: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-xl-9 {
    margin-left: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-xl-10 {
    margin-left: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-xl-11 {
    margin-left: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-xl-12 {
    margin-left: 100%; } }

.ipe-tabs [data-tab-id="revert"] a {
  display: none; }

.ipe-tabs .ipe-tab:nth-last-child(2) {
  border-right: 1px solid darkgray;
  border-top-right-radius: 9px; }

/* some updates for the panopoly widgets popup */
#modal-content .form-item > label {
  font-size: 1.2em; }

#modal-content .form-item .form-item > label,
#modal-content .form-item > label.option {
  font-size: 1em; }

#modal-content .media-widget a {
  margin-right: 1em; }

#modal-content .form-actions {
  float: none;
  width: auto;
  text-align: left;
  margin: 1em 1em 3em 0;
  clear: both; }

/* Fixes for flexbox behavior on rows */
.panels-ipe-region {
  width: 100%; }

.panels-ipe-editing .panels-ipe-region {
  float: none; }

ul.panels-ipe-linkbar {
  list-style: none;
  margin: 0; }
  ul.panels-ipe-linkbar li {
    display: inline-block; }

.manualcrop-style-button-holder {
  display: inline-block; }

ul.panels-ipe-linkbar {
  list-style: none;
  margin: 0; }
  ul.panels-ipe-linkbar li {
    display: inline-block; }

div[onclick] {
  cursor: pointer; }

.cke_editable {
  font-size: 16px;
  padding: 10px 15px; }
  .cke_editable p, .cke_editable span, .cke_editable ul {
    font-size: 16px !important; }
  .cke_editable h2 {
    color: #185C71;
    font-size: 23px; }
  .cke_editable h3 {
    color: #78BDCA;
    font-size: 18px; }
  .cke_editable ul:not([class]) {
    margin: 5px 0 30px 16px; }
    .cke_editable ul:not([class]) li:before {
      top: 10px;
      left: -15px; }
  .cke_editable a {
    cursor: pointer; }

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

html,
body {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.45;
  color: #144b5c; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "brandon-grotesque", sans-serif;
  line-height: 1.1; }
  @media (max-width: 1149px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 12px 0; } }
  @media (min-width: 1100px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 15px 0; } }
  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  h6 + p {
    margin-top: 0; }

h1 {
  text-transform: uppercase;
  font-weight: 900;
  color: #185C71; }
  @media (max-width: 1149px) {
    h1 {
      font-size: 1.5rem; } }
  @media (min-width: 1100px) {
    h1 {
      font-size: 1.625rem; } }

h2 {
  font-weight: 700;
  color: #78BDCA; }
  @media (max-width: 1149px) {
    h2 {
      font-size: 1.5rem; } }
  @media (min-width: 1100px) {
    h2 {
      font-size: 1.6875rem; } }
  h2.pane-title {
    text-transform: uppercase;
    color: #185C71;
    font-size: 1.5625rem;
    font-weight: 900;
    margin-top: 0;
    max-width: calc(100% - 100px); }
  h2.grouping-title {
    color: #185C71; }

h3 {
  font-weight: 700;
  color: #185C71; }
  @media (max-width: 1149px) {
    h3 {
      font-size: 1.125rem; } }
  @media (min-width: 1100px) {
    h3 {
      font-size: 1.375rem; } }

h4 {
  font-weight: 700;
  font-size: 1.125rem; }

h5 {
  font-weight: 700;
  font-size: 1.125rem; }

h6 {
  font-weight: 700;
  font-size: 1rem; }

p {
  margin: 20px 0; }

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 20px; }

.intro-txt, .small-header .block-ek-header-block--content--inner p {
  font-size: 1rem; }
  @media (min-width: 1100px) {
    .intro-txt, .small-header .block-ek-header-block--content--inner p {
      font-size: 1.125rem; } }

a {
  color: #185C71;
  text-decoration: underline;
  transition: all 0.25s ease; }
  a:hover, a:focus {
    color: #2385a3; }

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #D6E7E9; }
  @media (min-width: 1100px) {
    hr {
      margin: 40px 0; } }

.quote {
  background-color: #ffffff;
  border: 1px solid #D6E7E9;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  margin: 0; }
  @media (max-width: 1149px) {
    .quote {
      padding: 30px; }
      .quote:before {
        top: 30px;
        left: 30px; } }
  @media (min-width: 1100px) {
    .quote {
      padding: 85px 40px 40px 40px; }
      .quote:before {
        top: 40px;
        left: 40px; } }
  .quote:before {
    content: '';
    position: absolute;
    background: url("../images/icons/application/quote-marks.svg") no-repeat;
    width: 34px;
    height: 27px; }

.quote__cite {
  display: block;
  margin-top: 10px;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
  font-style: normal; }

ul:not([class]), .download-list, .links {
  padding: 0;
  margin: 5px 0 30px 24px; }
  ul:not([class]) li, .download-list li, .links li {
    list-style: none;
    margin-bottom: 1px;
    position: relative; }
    ul:not([class]) li::before, .download-list li::before, .links li::before {
      content: "";
      position: absolute;
      top: 12px;
      left: -22px;
      display: inline-block;
      margin-right: 10px;
      width: 5px;
      height: 5px;
      background-color: #185C71;
      border-radius: 50%; }

ol:not(.fancy) {
  padding: 0;
  margin: 5px 0 30px 24px; }

ol.fancy {
  margin: 5px 0 30px 20px;
  padding: 0;
  list-style: none;
  counter-reset: li; }
  ol.fancy li {
    position: relative;
    margin-bottom: 20px;
    counter-increment: li;
    padding-left: 25px; }
    ol.fancy li:before {
      position: absolute;
      content: counter(li);
      color: #ffffff;
      background-color: #185C71;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      font-weight: bold;
      display: inline-block;
      left: -20px;
      text-align: center;
      padding-top: 2px; }

.list-reset, .bordered-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.bordered-list {
  margin: 10px 0; }
  .bordered-list li {
    padding: 15px 0;
    border-top: 1px solid #D6E7E9; }
    .bordered-list li:last-child {
      border-bottom: 1px solid #D6E7E9; }

.responsive-table {
  overflow-x: auto;
  margin: 30px 0; }

table {
  width: 100%; }
  table th, table td {
    /*border-bottom: 1px solid $border-color;
	    border-left: 1px solid $border-color;
	    border-right: 1px solid $border-color;*/
    padding: 12px 20px;
    text-align: left;
    /*&:first-child {
	    	border-left: none;
	    }
	    &:last-child {
	    	border-right: none;
	    }*/ }
  table th {
    font-weight: 600; }
  table tr th {
    background-color: #7A959D; }
    table tr th:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px; }
    table tr th:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px; }
  table tr:nth-child(even) td {
    background-color: #7A959D; }
    table tr:nth-child(even) td:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px; }
    table tr:nth-child(even) td:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px; }

/* Layout builder */
.layout {
  margin: 50px 0; }

.page {
  overflow-x: hidden; }

@media (max-width: 1149px) {
  .page-content {
    padding: 30px 0; } }

@media (min-width: 1100px) {
  .page-content {
    padding: 50px 0; } }

.pane-pane-page-title h1,
.pane-page-title h1 {
  margin-top: 0;
  display: inline-block;
  margin-right: 10px; }

.pane-pane-page-title .bib-label,
.pane-page-title .bib-label {
  background-color: #DAA32E;
  text-transform: uppercase;
  font-size: 0.9375rem;
  color: #ffffff;
  font-weight: 700;
  padding: 1px 5px;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px; }

@media (min-width: 1100px) {
  .one-sidebar {
    display: flex;
    flex-wrap: wrap; } }

@media (min-width: 1100px) {
  .one-sidebar .main {
    flex: 1 0 60%;
    max-width: 60%; } }

@media (max-width: 1149px) {
  .one-sidebar .sidebar-second {
    padding-top: 40px; } }

@media (min-width: 1100px) {
  .one-sidebar .sidebar-second {
    flex: 1 0 40%;
    max-width: 40%;
    padding-left: 70px; } }

.one-sidebar .sidebar-second .panel-pane {
  margin-bottom: 20px; }

@media (max-width: 1149px) {
  .panel-pane.pane-bundle-manual-list .field-name-field-short-description,
  .panel-pane.pane-bundle-automatic-list .field-name-field-short-description {
    padding-bottom: 45px; } }

@media (min-width: 1100px) {
  .panel-pane.pane-bundle-manual-list .field-name-field-short-description,
  .panel-pane.pane-bundle-automatic-list .field-name-field-short-description {
    max-width: calc(100% - 100px); } }

.panel-pane.pane-bundle-manual-list .field-name-field-short-description .field-name-field-short-description,
.panel-pane.pane-bundle-automatic-list .field-name-field-short-description .field-name-field-short-description {
  padding-bottom: 0;
  max-width: 100%; }

.panel-pane.pane-bundle-manual-list .content-wrapper .field-name-field-short-description,
.panel-pane.pane-bundle-automatic-list .content-wrapper .field-name-field-short-description {
  padding-bottom: 0;
  max-width: 100%; }

.panel-pane.pane-recent-answers-panel-pane-1 {
  background-color: #E7F2F4; }
  @media (max-width: 1149px) {
    .panel-pane.pane-recent-answers-panel-pane-1 {
      padding: 25px 20px; } }
  @media (min-width: 1100px) {
    .panel-pane.pane-recent-answers-panel-pane-1 {
      padding: 35px 50px; } }
  .panel-pane.pane-recent-answers-panel-pane-1 h2.pane-title {
    text-transform: none;
    font-weight: 600; }
  .panel-pane.pane-recent-answers-panel-pane-1 ul {
    margin: 0;
    padding: 0; }

.panel-pane.pane-score-info {
  border: 1px solid #D6E7E9; }
  @media (max-width: 1149px) {
    .panel-pane.pane-score-info {
      padding: 25px 20px 15px 20px; } }
  @media (min-width: 1100px) {
    .panel-pane.pane-score-info {
      padding: 35px 50px 15px 50px; } }
  .panel-pane.pane-score-info h2.pane-title {
    text-transform: none;
    font-weight: 600; }
  .panel-pane.pane-score-info h3 {
    color: #78BDCA;
    text-transform: uppercase;
    font-size: 0.9375rem;
    margin: 0 0 7px 0; }

.panel-pane.related:not(.pane-news-related) {
  background-color: #E7F2F4;
  position: relative; }
  @media (max-width: 1149px) {
    .panel-pane.related:not(.pane-news-related) {
      padding: 25px 20px 15px 20px; } }
  @media (min-width: 1100px) {
    .panel-pane.related:not(.pane-news-related) {
      padding: 35px 50px 15px 50px; } }
  @media (min-width: 1100px) {
    .panel-pane.related:not(.pane-news-related).pane-composer-related {
      margin-top: 87px;
      padding: 140px 50px 30px; } }
  @media (max-width: 1149px) {
    .panel-pane.related:not(.pane-news-related).pane-composer-related .composer-photo {
      margin-bottom: 15px; } }
  @media (min-width: 1100px) {
    .panel-pane.related:not(.pane-news-related).pane-composer-related .composer-photo {
      position: absolute;
      left: 50px;
      top: -55px; } }
  .panel-pane.related:not(.pane-news-related).pane-composer-related .composer-photo img {
    max-width: 180px;
    height: auto; }
  .panel-pane.related:not(.pane-news-related) h2.pane-title {
    text-transform: none;
    font-weight: 600; }
  .panel-pane.related:not(.pane-news-related) h3 {
    color: #78BDCA;
    text-transform: uppercase;
    font-size: 0.9375rem;
    margin: 0 0 7px 0; }

.pane-news-related {
  position: relative; }
  .pane-news-related .news-photo img {
    max-width: 100%;
    height: auto;
    vertical-align: top; }
  .pane-news-related .news-content {
    background-color: #E7F2F4; }
    @media (max-width: 1149px) {
      .pane-news-related .news-content {
        padding: 25px 20px; } }
    @media (min-width: 1100px) {
      .pane-news-related .news-content {
        padding: 30px 50px; } }

.panel-pane.pane-node-field-contact-e-mail {
  border: 1px solid #D6E7E9; }
  @media (max-width: 1149px) {
    .panel-pane.pane-node-field-contact-e-mail {
      padding: 25px 20px; } }
  @media (min-width: 1100px) {
    .panel-pane.pane-node-field-contact-e-mail {
      padding: 35px 50px; } }
  .panel-pane.pane-node-field-contact-e-mail h2 {
    text-transform: uppercase;
    font-size: 0.9375rem;
    margin: 0 0 7px 0; }

aside.sidebar-second .panel-pane.pane-bundle-text {
  border: 1px solid #D6E7E9; }
  @media (max-width: 1149px) {
    aside.sidebar-second .panel-pane.pane-bundle-text {
      padding: 25px 20px; } }
  @media (min-width: 1100px) {
    aside.sidebar-second .panel-pane.pane-bundle-text {
      padding: 35px 50px; } }
  aside.sidebar-second .panel-pane.pane-bundle-text.lightblue {
    border: none;
    background-color: #E7F2F4; }
  aside.sidebar-second .panel-pane.pane-bundle-text.yellow {
    border: none;
    background-color: #DAA32E;
    color: #ffffff; }
    aside.sidebar-second .panel-pane.pane-bundle-text.yellow h2.pane-title, aside.sidebar-second .panel-pane.pane-bundle-text.yellow h3 {
      color: #ffffff; }
    aside.sidebar-second .panel-pane.pane-bundle-text.yellow p {
      font-weight: 500; }
  aside.sidebar-second .panel-pane.pane-bundle-text.blue {
    border: none;
    background-color: #E7F2F4; }
  aside.sidebar-second .panel-pane.pane-bundle-text.darkblue {
    border: none;
    background: #185C71;
    color: #ffffff; }
    aside.sidebar-second .panel-pane.pane-bundle-text.darkblue h2.pane-title, aside.sidebar-second .panel-pane.pane-bundle-text.darkblue h3 {
      color: #ffffff; }
    aside.sidebar-second .panel-pane.pane-bundle-text.darkblue p {
      font-weight: 500; }
  aside.sidebar-second .panel-pane.pane-bundle-text h2 {
    text-transform: uppercase;
    font-size: 0.9375rem;
    margin: 0 0 7px 0; }
  aside.sidebar-second .panel-pane.pane-bundle-text h2.pane-title {
    text-transform: none;
    font-weight: 700; }
    @media (max-width: 1149px) {
      aside.sidebar-second .panel-pane.pane-bundle-text h2.pane-title {
        font-size: 1.5rem; } }
    @media (min-width: 1100px) {
      aside.sidebar-second .panel-pane.pane-bundle-text h2.pane-title {
        font-size: 1.6875rem; } }
  aside.sidebar-second .panel-pane.pane-bundle-text h3 {
    color: #78BDCA;
    text-transform: uppercase;
    font-size: 0.9375rem;
    margin: 1.2em 0 7px 0; }
  aside.sidebar-second .panel-pane.pane-bundle-text p {
    margin-bottom: 0; }

@media (min-width: 1100px) {
  .panel-pane.pane-composer-spotlight {
    margin-top: 34px; } }

.panel-pane.pane-create-question h2.pane-title {
  text-transform: none;
  font-weight: 600; }

.panel-pane.pane-create-question .top-questions {
  padding-top: 10px; }

.panel-pane.pane-create-question .item-list ul {
  margin: 0;
  padding: 0; }

.panel-pane.pane-pm-existing-pages {
  padding-top: 30px;
  padding-bottom: 30px; }
  .panel-pane.pane-pm-existing-pages h2.pane-title {
    text-transform: none;
    font-weight: 600; }
  .panel-pane.pane-pm-existing-pages .item-list ul {
    margin: 0;
    padding: 0; }

.panel-pane.pane-user-profile {
  max-width: 930px; }
  .panel-pane.pane-user-profile img {
    border-radius: 50%;
    width: 60px;
    height: 60px; }
  .panel-pane.pane-user-profile .view-mode-account .label-inline {
    display: inline-block; }
  .panel-pane.pane-user-profile .field-collection-container {
    border: none;
    margin: 30px 0 20px 0; }
  .panel-pane.pane-user-profile .field-collection-view {
    margin: -1px 0 0 0;
    padding: 15px 0;
    border-top: 1px solid #D6E7E9;
    border-bottom: 1px solid #D6E7E9; }
    .panel-pane.pane-user-profile .field-collection-view h3 {
      margin: 0 0 7px 0; }

.field-name-body {
  max-width: 930px; }

form#user-login {
  max-width: 500px; }

.field-name-title h3 {
  margin-bottom: 7px; }

.field-name-title + .field-name-body p {
  margin-top: 0; }
  .field-name-title + .field-name-body p:last-child {
    margin-bottom: 7px; }

.node-panopoly-page.view-mode-search_result {
  max-width: 930px; }
  .node-panopoly-page.view-mode-search_result h2 {
    color: #185C71;
    font-size: 1.5rem;
    margin: 25px 0 5px 0; }

.node-panopoly-page.view-mode-list h3 {
  margin: 5px 0; }
  .node-panopoly-page.view-mode-list h3 a {
    text-decoration: none; }
    .node-panopoly-page.view-mode-list h3 a:hover {
      color: #DE4744; }

.node-panopoly-page.view-mode-square {
  position: relative;
  height: 100%;
  display: inline-flex;
  flex-flow: column;
  align-items: stretch; }
  @media (max-width: 1149px) {
    .node-panopoly-page.view-mode-square {
      margin-bottom: 15px; } }
  .node-panopoly-page.view-mode-square:hover {
    cursor: hover;
    box-shadow: inset 0px -3px #DE4744; }
    .node-panopoly-page.view-mode-square:hover h3 {
      color: #DE4744; }
  .node-panopoly-page.view-mode-square h3 {
    color: #185C71;
    font-size: 1.5rem;
    margin-top: 0; }
    .node-panopoly-page.view-mode-square h3 a {
      text-decoration: none; }
      .node-panopoly-page.view-mode-square h3 a:hover {
        color: #DE4744; }
  .node-panopoly-page.view-mode-square .content-wrapper {
    border-left: 1px solid #D6E7E9;
    border-right: 1px solid #D6E7E9;
    border-bottom: 1px solid #D6E7E9; }
    @media (max-width: 1149px) {
      .node-panopoly-page.view-mode-square .content-wrapper {
        padding: 15px 20px 20px 20px; } }
    @media (min-width: 1100px) {
      .node-panopoly-page.view-mode-square .content-wrapper {
        padding: 25px 30px 30px 30px;
        flex: 1 1 auto; } }
  .node-panopoly-page.view-mode-square .image-wrapper {
    position: relative; }

.panel-pane.colored.lightblue {
  background-color: #E7F2F4; }
  @media (max-width: 1149px) {
    .panel-pane.colored.lightblue {
      padding: 25px; } }
  @media (min-width: 1100px) {
    .panel-pane.colored.lightblue {
      padding: 50px; } }
  .panel-pane.colored.lightblue .pane-title {
    color: #185C71; }

@media (max-width: 1149px) {
  .panel-pane.colored.darkblue {
    padding: 25px; } }

@media (min-width: 1100px) {
  .panel-pane.colored.darkblue {
    padding: 50px; } }

@media (min-width: 1150px) {
  .block-content--type-basic {
    padding-left: 15px; } }

.block-inline-blocktestimonial {
  margin: 0 auto;
  position: relative; }
  @media (min-width: 1100px) {
    .block-inline-blocktestimonial {
      max-width: 930px; }
      .block-inline-blocktestimonial:before {
        content: '';
        position: absolute;
        background: url("../images/icons/application/quote-marks.svg") no-repeat;
        width: 34px;
        height: 27px;
        top: 70px;
        left: -80px; } }
  .block-inline-blocktestimonial h2 {
    margin-top: 0; }
  .block-inline-blocktestimonial .field-name-field-quotee-img img {
    width: 140px;
    height: 140px;
    border: 5px solid white;
    border-radius: 50%;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2); }
  @media (min-width: 1100px) {
    .block-inline-blocktestimonial .col-md-6:nth-last-child(2) {
      width: 200px;
      flex: 0 0 200px;
      max-width: 200px; } }
  @media (min-width: 1100px) {
    .block-inline-blocktestimonial .col-md-6:last-child {
      width: calc(100%-200px);
      flex: 0 0 calc(100%-200px);
      max-width: calc(100%-200px); } }
  .block-inline-blocktestimonial p {
    margin-top: 0; }
  .block-inline-blocktestimonial .title-wrapper {
    margin-bottom: 10px; }
    @media (min-width: 1100px) {
      .block-inline-blocktestimonial .title-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline; } }
    @media (max-width: 1899px) {
      .block-inline-blocktestimonial .title-wrapper {
        width: calc(100%+50px); } }
    @media (min-width: 1150px) {
      .block-inline-blocktestimonial .title-wrapper {
        width: calc(100%+120px); } }

@media (max-width: 1149px) {
  .panel-row {
    margin: 40px 0; } }

@media (min-width: 1100px) {
  .panel-row {
    margin: 70px 0; } }

@media (max-width: 1149px) {
  .panel-row:first-child {
    margin-top: 10px; } }

@media (min-width: 1100px) {
  .panel-row:first-child {
    margin-top: 20px; } }

@media (max-width: 1149px) {
  .panel-row:last-child {
    margin-bottom: 40px; } }

@media (min-width: 1100px) {
  .panel-row:last-child {
    margin-bottom: 0; } }

@media (min-width: 1100px) {
  .panel-row .col-sm-6 .panel-pane.pane-bundle-free-block {
    padding-right: 50px; } }

.panel-row .col-sm-6 .panel-pane.pane-bundle-free-block p {
  margin: 10px 0; }

@media (max-width: 1149px) {
  .panel-row .col-sm-6 .panel-pane.pane-bundle-free-block .field-name-field-link {
    margin-bottom: 30px; } }

@media (max-width: 1149px) {
  .panel-row .panel-pane + .panel-pane {
    margin-bottom: 40px; } }

@media (min-width: 1100px) {
  .panel-row .panel-pane + .panel-pane {
    margin-bottom: 70px; } }

.panel-row .panel-pane {
  position: relative; }
  .panel-row .panel-pane.youtube .pane-title {
    background: url("../images/icons/social/youtube-title.svg") no-repeat 0 50%;
    padding-left: 40px;
    color: #ffffff; }
  .panel-row .panel-pane.colored.lightblue {
    background-color: #E7F2F4; }
    @media (max-width: 1149px) {
      .panel-row .panel-pane.colored.lightblue {
        padding: 25px; } }
    @media (min-width: 1100px) {
      .panel-row .panel-pane.colored.lightblue {
        padding: 50px; } }
    .panel-row .panel-pane.colored.lightblue .pane-title {
      color: #185C71; }
  .panel-row .panel-pane.colored.yellow {
    background-color: #DAA32E;
    color: #ffffff; }
    @media (max-width: 1149px) {
      .panel-row .panel-pane.colored.yellow {
        padding: 25px; } }
    @media (min-width: 1100px) {
      .panel-row .panel-pane.colored.yellow {
        padding: 50px; } }
    .panel-row .panel-pane.colored.yellow p {
      font-weight: 500; }
    .panel-row .panel-pane.colored.yellow .field-name-field-link a {
      color: #DAA32E; }
      .panel-row .panel-pane.colored.yellow .field-name-field-link a:hover, .panel-row .panel-pane.colored.yellow .field-name-field-link a:focus, .panel-row .panel-pane.colored.yellow .field-name-field-link a:active {
        color: #DE4744; }
  .panel-row .panel-pane.colored.blue {
    background-color: #E7F2F4; }
    @media (max-width: 1149px) {
      .panel-row .panel-pane.colored.blue {
        padding: 25px; } }
    @media (min-width: 1100px) {
      .panel-row .panel-pane.colored.blue {
        padding: 50px; } }
  .panel-row .panel-pane.colored.darkblue {
    background: #185C71 url("../images/icons/application/bg-shape--dark.svg") no-repeat 90% 50%;
    background-size: 110% auto;
    color: #ffffff; }
    .panel-row .panel-pane.colored.darkblue p {
      font-weight: 500; }
  @media (max-width: 1149px) {
    .panel-row .panel-pane.colored .col.col-md-7 {
      padding: 25px 40px; } }
  @media (min-width: 1100px) {
    .panel-row .panel-pane.colored .col.col-md-7 {
      padding: 50px 60px 50px 70px; } }
  .panel-row .panel-pane.colored .pane-title {
    color: #ffffff;
    text-transform: none;
    font-weight: 600; }
    @media (max-width: 1149px) {
      .panel-row .panel-pane.colored .pane-title {
        font-size: 1.875rem; } }
    @media (min-width: 1100px) {
      .panel-row .panel-pane.colored .pane-title {
        font-size: 2.25rem; } }
  .panel-row .panel-pane.colored .field-name-field-basic-image-image img {
    vertical-align: top;
    width: 100%;
    height: auto; }
  @media (min-width: 1100px) {
    .panel-row .panel-pane .pane-title, .panel-row .panel-pane .field-name-field-short-description {
      max-width: 700px; } }
  @media (min-width: 1100px) {
    .panel-row .panel-pane .field-name-field-short-description + .field-name-manual-list {
      margin-top: 15px;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap; } }
  .panel-row .panel-pane .field-name-field-link a {
    color: #ffffff;
    text-decoration: none; }

@media (max-width: 1149px) {
  .panel-row.colored {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 0; } }

@media (min-width: 1100px) {
  .panel-row.colored {
    padding-top: 70px;
    padding-bottom: 70px;
    margin-bottom: 0; } }

.panel-row.colored.lightblue {
  background-color: #E7F2F4; }
  .panel-row.colored.lightblue .pane-title {
    color: #185C71; }

.panel-row.colored.blue {
  background-color: #E7F2F4; }
  .panel-row.colored.blue .node-news.view-mode-square {
    background-color: #ffffff; }

.panel-row.colored.darkblue {
  background: #185C71 url("../images/icons/application/bg-shape--dark.svg") no-repeat 90% 50%;
  background-size: 110% auto;
  color: #ffffff; }
  @media (min-width: 1100px) {
    .panel-row.colored.darkblue .col:nth-child(2) {
      text-align: right; } }
  .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text p {
    margin: 0; }
  .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text a {
    text-decoration: none; }
    .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text a:hover, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text a:focus, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text a:active {
      color: #DE4744; }
  .panel-row.colored.darkblue .pane-bundle-html-code {
    margin-top: 20px; }

.panel-row.colored.yellow {
  background-color: #DAA32E; }

.panel-row.colored .node-news.view-mode-square .content-wrapper {
  border: none; }

.panel-row.full-width .container {
  max-width: none; }

@media (max-width: 1149px) {
  .panel-row.colored:first-child {
    margin-top: -30px; } }

@media (min-width: 1100px) {
  .panel-row.colored:first-child {
    margin-top: -50px; } }

.panel-row.panel-bottom {
  margin-bottom: -50px;
  margin-top: 35px; }

.panel-row.colored + .panel-row.colored {
  margin-top: 0; }

@media (max-width: 1099px) {
  .pane-block:not(.pane-views-exp-scores-page):not(.pane-views-exp-nieuws-page) {
    padding-left: 20px;
    padding-right: 20px; } }

@media (min-width: 550px) {
  .pane-block:not(.pane-views-exp-scores-page):not(.pane-views-exp-nieuws-page) {
    width: 1340px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px; } }

@media (min-width: 1150px) {
  .pane-block:not(.pane-views-exp-scores-page):not(.pane-views-exp-nieuws-page) {
    padding-left: 50px;
    padding-right: 50px; } }

.bryant .panel-pane {
  margin-bottom: 30px; }

.bryant .entity-paragraphs-item:first-child {
  margin-top: 0; }

.pane-bundle-manual-list .field-name-manual-list {
  padding-top: 15px;
  position: relative; }

.pane-bundle-manual-list .col-md-4:not(.swiper-slide) .node-score.view-mode-square {
  height: calc(100% - 30px); }

.pane-bundle-manual-list .node-library-score.view-mode-square {
  height: calc(100% - 30px); }

.pane-bundle-automatic-list .field-name-automatic-list {
  padding-top: 15px;
  position: relative; }
  .pane-bundle-automatic-list .field-name-automatic-list .col {
    margin-bottom: 30px; }

.page .pane-node-comment-form {
  border-top: 1px solid #D6E7E9;
  padding-top: 40px;
  margin-top: 40px;
  position: relative; }
  .page .pane-node-comment-form h2.pane-title {
    text-transform: none;
    font-weight: 600; }
  .page .pane-node-comment-form form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block) label {
    display: none !important; }
  .page .pane-node-comment-form form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block) .form-textarea {
    padding: 10px 12px 10px 60px; }
  .page .pane-node-comment-form .user-image {
    position: absolute;
    top: 105px;
    left: -10px; }
    .page .pane-node-comment-form .user-image img {
      border-radius: 50%;
      width: 60px;
      height: 60px; }

.pane-node-comments {
  padding-top: 40px; }
  .pane-node-comments h2.pane-title {
    text-transform: none;
    font-weight: 600; }
  .pane-node-comments .comment {
    position: relative;
    border: 1px solid #D6E7E9;
    padding: 20px 20px 20px 70px;
    margin-top: -1px; }
    .pane-node-comments .comment .changed, .pane-node-comments .comment .links, .pane-node-comments .comment a.permalink {
      display: none; }
    .pane-node-comments .comment .name {
      font-weight: 600;
      font-size: 1.25rem; }
      .pane-node-comments .comment .name a {
        text-decoration: none; }
        .pane-node-comments .comment .name a:hover {
          color: #DE4744; }
    .pane-node-comments .comment .date {
      color: #78BDCA;
      text-transform: uppercase;
      font-size: 0.9375rem;
      margin: 0 0 7px 0;
      font-weight: 600; }
    .pane-node-comments .comment h3 {
      margin: 0; }
    .pane-node-comments .comment h3 a.permalink {
      display: block;
      text-decoration: none; }
    .pane-node-comments .comment .user-picture, .pane-node-comments .comment .field-name-ds-user-picture {
      position: absolute;
      top: 10px;
      left: -10px; }
      .pane-node-comments .comment .user-picture img, .pane-node-comments .comment .field-name-ds-user-picture img {
        border-radius: 50%;
        width: 60px;
        height: 60px; }

.question-form {
  position: relative; }
  .question-form .user-image {
    position: absolute;
    top: 40px;
    left: -10px; }
    .question-form .user-image img {
      border-radius: 50%;
      width: 60px;
      height: 60px; }
  .question-form form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block) .form-textarea {
    padding: 10px 12px 10px 60px !important; }
  .question-form form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block) .button, .question-form form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block) .field-name-user-edit a, .field-name-user-edit .question-form form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block) a {
    box-shadow: none !important; }

@media (max-width: 1099px) {
  .pane-pane-messages {
    padding-left: 20px;
    padding-right: 20px; } }

@media (min-width: 550px) {
  .pane-pane-messages {
    width: 1340px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px; } }

@media (min-width: 1150px) {
  .pane-pane-messages {
    padding-left: 50px;
    padding-right: 50px; } }

.panel-pane.pane-bundle-text {
  max-width: 930px;
  margin-left: auto;
  margin-right: auto; }

.pane-bundle-slogan .fieldable-panels-pane {
  background-color: #185C71;
  color: #ffffff;
  font-weight: 500; }
  @media (max-width: 1149px) {
    .pane-bundle-slogan .fieldable-panels-pane {
      padding: 25px; } }
  @media (min-width: 1100px) {
    .pane-bundle-slogan .fieldable-panels-pane {
      padding: 50px; } }

.pane-bundle-slogan .field-name-field-slogan-link a {
  margin: 20px 0 0 0 !important; }

.panel-pane.pane-bundle-faq {
  max-width: 930px;
  margin-left: auto;
  margin-right: auto; }

.panel-pane.pane-bundle-html-code {
  /*max-width: $max-content-width;
  margin-left: auto;
  margin-right: auto;
  .fieldable-panels-pane {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    iframe {
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
  }*/ }

.panel-pane.pane-bundle-image {
  max-width: 930px;
  margin-left: auto;
  margin-right: auto; }
  .panel-pane.pane-bundle-image img {
    vertical-align: top; }
  .panel-pane.pane-bundle-image p {
    margin: 7px 0 0 0; }

.panel-pane.pane-bundle-video {
  max-width: 930px;
  margin-left: auto;
  margin-right: auto; }
  .panel-pane.pane-bundle-video .fieldable-panels-pane {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0; }
    .panel-pane.pane-bundle-video .fieldable-panels-pane iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute; }

.panel-pane.pane-bundle-free-block .image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%; }

.panel-pane.pane-bundle-free-block .ratio {
  padding: 0 0 65%;
  height: 0;
  width: 100%; }

.panel-pane.pane-bundle-free-block .top.image .ratio {
  padding: 0 0 55%; }

.pane-bundle-logo-link .fieldable-panels-pane,
.pane-bundle-logo-s .fieldable-panels-pane {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  ms-flex-wrap: wrap; }
  @media (min-width: 1100px) {
    .pane-bundle-logo-link .fieldable-panels-pane,
    .pane-bundle-logo-s .fieldable-panels-pane {
      flex-wrap: nowrap;
      ms-flex-wrap: nowrap; } }
  .pane-bundle-logo-link .fieldable-panels-pane .img,
  .pane-bundle-logo-s .fieldable-panels-pane .img {
    width: 25%;
    padding: 10px;
    max-width: 195px; }
    @media (min-width: 550px) {
      .pane-bundle-logo-link .fieldable-panels-pane .img,
      .pane-bundle-logo-s .fieldable-panels-pane .img {
        width: 20%; } }
    @media (min-width: 1100px) {
      .pane-bundle-logo-link .fieldable-panels-pane .img,
      .pane-bundle-logo-s .fieldable-panels-pane .img {
        width: auto; } }
  .pane-bundle-logo-link .fieldable-panels-pane img,
  .pane-bundle-logo-s .fieldable-panels-pane img {
    max-width: 100%;
    height: auto;
    max-height: 175px; }

@media (max-width: 1149px) {
  .entity-paragraphs-item {
    margin-top: 25px;
    margin-bottom: 25px; } }

@media (min-width: 1100px) {
  .entity-paragraphs-item {
    margin-top: 45px;
    margin-bottom: 45px; } }

.entity-paragraphs-item h2:first-child {
  margin-top: 0; }

.entity-paragraphs-item .button a, .entity-paragraphs-item .field-name-user-edit a a, .field-name-user-edit .entity-paragraphs-item a a {
  color: #ffffff;
  text-decoration: none; }

.paragraphs-item-text {
  max-width: 930px;
  /*margin-left: auto;
  margin-right: auto;*/ }
  @media (max-width: 1149px) {
    .paragraphs-item-text img {
      margin-bottom: 15px; } }
  @media (min-width: 1100px) {
    .paragraphs-item-text img {
      margin: 0 20px 15px 20px; } }
  .paragraphs-item-text p:last-child {
    margin-bottom: 0; }

.paragraphs-item-paragraph-faq {
  max-width: 930px; }

.field-name-field-qa {
  margin-top: 20px; }

.field-collection-item-field-qa {
  border-top: 1px solid #D6E7E9;
  border-bottom: 1px solid #D6E7E9;
  margin: 0 0 -1px 0; }

.pane-bundle-faq h3,
.field-name-field-qa h3 {
  cursor: pointer;
  padding: 13px 30px 13px 0;
  margin: 0;
  font-weight: 500;
  position: relative; }
  .pane-bundle-faq h3:after,
  .field-name-field-qa h3:after {
    content: '';
    position: absolute;
    top: 22px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #185C71; }

.pane-bundle-faq .field-name-field-qa-answer,
.field-name-field-qa .field-name-field-qa-answer {
  display: none;
  padding: 0 30px 15px 0; }
  .pane-bundle-faq .field-name-field-qa-answer p:first-child,
  .field-name-field-qa .field-name-field-qa-answer p:first-child {
    margin-top: 0; }
  .pane-bundle-faq .field-name-field-qa-answer p:last-child,
  .field-name-field-qa .field-name-field-qa-answer p:last-child {
    margin-bottom: 0; }

.pane-bundle-faq .open .field-name-field-qa-answer,
.field-name-field-qa .open .field-name-field-qa-answer {
  display: block; }

.pane-bundle-faq .open h3,
.field-name-field-qa .open h3 {
  color: #DE4744; }
  .pane-bundle-faq .open h3:after,
  .field-name-field-qa .open h3:after {
    border-bottom: 6px solid #DE4744;
    border-top: none; }

.paragraphs-item-paragraph-gallery .field-name-ds-function-gallery-popup {
  margin-left: -5px;
  margin-right: -5px; }
  .paragraphs-item-paragraph-gallery .field-name-ds-function-gallery-popup .gallery-item {
    width: 25%;
    float: left;
    padding: 0 5px;
    position: relative;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s; }
    .paragraphs-item-paragraph-gallery .field-name-ds-function-gallery-popup .gallery-item:focus, .paragraphs-item-paragraph-gallery .field-name-ds-function-gallery-popup .gallery-item:hover {
      opacity: 0.8; }
    .paragraphs-item-paragraph-gallery .field-name-ds-function-gallery-popup .gallery-item .more {
      background: rgba(0, 0, 0, 0.3);
      opacity: 0.7;
      position: absolute;
      left: 5px;
      right: 5px;
      top: 0;
      bottom: 0;
      color: #fff;
      text-align: center;
      -webkit-transition: opacity 0.2s;
      -moz-transition: opacity 0.2s;
      -ms-transition: opacity 0.2s;
      -o-transition: opacity 0.2s;
      transition: opacity 0.2s; }
      .paragraphs-item-paragraph-gallery .field-name-ds-function-gallery-popup .gallery-item .more::before {
        content: "+";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        width: 100%;
        font-size: 40px;
        font-weight: 100; }
      .paragraphs-item-paragraph-gallery .field-name-ds-function-gallery-popup .gallery-item .more:hover, .paragraphs-item-paragraph-gallery .field-name-ds-function-gallery-popup .gallery-item .more:focus {
        opacity: 0.5; }

.paragraphs-item-video .media-youtube-video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0; }
  .paragraphs-item-video .media-youtube-video iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute; }

.paragraphs-item-paragraph-button .content {
  padding: 0; }

.one-sidebar .main .paragraphs-item-call-to-action,
.paragraphs-item-call-to-action {
  background-color: #DAA32E;
  position: relative; }
  @media (max-width: 1149px) {
    .one-sidebar .main .paragraphs-item-call-to-action,
    .paragraphs-item-call-to-action {
      padding: 25px 30px 30px 30px; } }
  @media (min-width: 1100px) {
    .one-sidebar .main .paragraphs-item-call-to-action,
    .paragraphs-item-call-to-action {
      padding: 35px 30px 40px 30px; } }
  .one-sidebar .main .paragraphs-item-call-to-action h2,
  .paragraphs-item-call-to-action h2 {
    color: #ffffff;
    margin-top: 0; }
    @media (max-width: 1149px) {
      .one-sidebar .main .paragraphs-item-call-to-action h2,
      .paragraphs-item-call-to-action h2 {
        font-size: 1.875rem; } }
    @media (min-width: 1100px) {
      .one-sidebar .main .paragraphs-item-call-to-action h2,
      .paragraphs-item-call-to-action h2 {
        font-size: 2.25rem; } }
  .one-sidebar .main .paragraphs-item-call-to-action h2,
  .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-basic-text-text,
  .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link,
  .paragraphs-item-call-to-action h2,
  .paragraphs-item-call-to-action .field-name-field-basic-text-text,
  .paragraphs-item-call-to-action .field-name-field-link {
    color: #ffffff; }
  .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-basic-text-text p,
  .paragraphs-item-call-to-action .field-name-field-basic-text-text p {
    margin: 10px 0 20px 0; }
    .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-basic-text-text p:last-child,
    .paragraphs-item-call-to-action .field-name-field-basic-text-text p:last-child {
      margin-bottom: 0; }
  .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link,
  .paragraphs-item-call-to-action .field-name-field-link {
    margin-top: 20px;
    margin-bottom: -10px; }
  .one-sidebar .main .paragraphs-item-call-to-action a,
  .paragraphs-item-call-to-action a {
    color: #ffffff; }

.paragraphs-item-paragraph-image img {
  width: 100%;
  vertical-align: top; }

.paragraphs-item-paragraph-image .content {
  padding: 0; }

.paragraphs-item-paragraph-quote {
  background-color: #E7F2F4;
  position: relative;
  overflow: hidden; }
  @media (max-width: 1149px) {
    .paragraphs-item-paragraph-quote {
      padding: 25px 30px 30px 30px; }
      .paragraphs-item-paragraph-quote:before {
        bottom: -20px;
        right: 20px; } }
  @media (min-width: 1100px) {
    .paragraphs-item-paragraph-quote {
      padding: 35px 30px 40px 30px; }
      .paragraphs-item-paragraph-quote:before {
        bottom: -20px;
        right: 100px; } }
  .paragraphs-item-paragraph-quote:before {
    content: '';
    position: absolute;
    background: url("../images/icons/application/quote-marks.svg") no-repeat;
    width: 107px;
    height: 90px; }
  .paragraphs-item-paragraph-quote .field-name-field-text {
    font-size: 1.375rem;
    line-height: 27px; }
    @media (min-width: 550px) {
      .paragraphs-item-paragraph-quote .field-name-field-text {
        max-width: 930px;
        margin-left: auto;
        margin-right: auto; } }
    .paragraphs-item-paragraph-quote .field-name-field-text p {
      margin: 0;
      font-weight: 500; }
  .paragraphs-item-paragraph-quote .field-name-field-quotee {
    margin-top: 10px; }
    @media (min-width: 550px) {
      .paragraphs-item-paragraph-quote .field-name-field-quotee {
        max-width: 930px;
        margin-left: auto;
        margin-right: auto; } }

.paragraphs-item-paragraph-text-image.image-position--left .row {
  flex-direction: row-reverse; }

@media (max-width: 1099px) {
  .paragraphs-item-paragraph-text-image .col:first-child {
    margin-bottom: 30px; } }

.paragraphs-item-paragraph-text-image h2 {
  color: #185C71;
  font-size: 1.5rem; }

.paragraphs-item-paragraph-text-image .field-name-field-subtitle {
  font-weight: 500;
  font-size: 1.125rem; }

.paragraphs-item-paragraph-text-image img {
  width: 100%; }

.paragraphs-item-paragraph-text-image p:first-child {
  margin-top: 7px; }

@media (max-width: 549px) {
  .paragraphs-item-embed .field-name-decoded-code {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0; }
    .paragraphs-item-embed .field-name-decoded-code iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute; } }

.paragraphs-item-attachment {
  max-width: 930px;
  /*margin-left: auto;
  margin-right: auto;*/ }

.paragraphs-item-paragraph-intro {
  max-width: 930px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #185c71;
  line-height: 28px; }
  .paragraphs-item-paragraph-intro p:last-child {
    margin-bottom: 0; }

.paragraphs-item-paragraph-manual-list {
  margin-bottom: 15px; }
  .paragraphs-item-paragraph-manual-list .col {
    margin-bottom: 30px; }
  .paragraphs-item-paragraph-manual-list .field-name-field-link a {
    color: #ffffff;
    text-decoration: none; }

.paragraphs-item-paragraph-manual-list + .entity-paragraphs-item {
  margin-top: 15px; }

.node-type-panopoly-landing-page {
  /* homepage visit the library */
  /* homepage composers */ }
  @media (min-width: 1100px) {
    .node-type-panopoly-landing-page .header {
      padding: 30px 0; } }
  @media (max-width: 1099px) {
    .node-type-panopoly-landing-page .site-logo img {
      width: 77px;
      height: 50px; } }
  @media (min-width: 550px) {
    .node-type-panopoly-landing-page .site-logo img {
      width: 153px;
      height: 100px; } }
  .node-type-panopoly-landing-page .panel-row .panel-pane.colored.yellow {
    padding: 0; }
  .node-type-panopoly-landing-page .panel-row .col-sm-6 .panel-pane.colored.lightblue {
    padding: 0; }

.node-composer .meta .field-name-field-data-of-birth,
.node-composer .meta .field-name-field-date-death {
  display: inline-block; }

.node-composer .meta .field-name-field-date-death {
  position: relative;
  padding-left: 15px; }
  .node-composer .meta .field-name-field-date-death:after {
    content: ' - ';
    position: absolute;
    top: -11px;
    left: 4px;
    color: #78BDCA;
    font-weight: 700;
    font-size: 0.9375rem; }

.node-composer .date-display-single {
  margin-top: -10px; }

.node-composer .date-display-single {
  display: block;
  color: #78BDCA;
  font-weight: 700;
  font-size: 0.9375rem; }

.node-composer.view-mode-search_result {
  max-width: 930px; }
  .node-composer.view-mode-search_result h2 {
    color: #185C71;
    font-size: 1.5rem;
    margin: 25px 0 5px 0; }

.node-composer.view-mode-list {
  position: relative; }
  @media (max-width: 1099px) {
    .node-composer.view-mode-list {
      margin-top: 20px; } }
  @media (min-width: 550px) {
    .node-composer.view-mode-list {
      margin-top: 30px; } }
  .node-composer.view-mode-list:hover .content-wrapper {
    cursor: hover;
    box-shadow: inset 0px -3px #DE4744; }
    .node-composer.view-mode-list:hover .content-wrapper h3 {
      color: #DE4744; }
  .node-composer.view-mode-list .field-name-field-photo {
    position: absolute;
    top: 15px;
    left: 0; }
    .node-composer.view-mode-list .field-name-field-photo img {
      max-width: 100px;
      height: auto;
      vertical-align: top; }
  .node-composer.view-mode-list .content-wrapper {
    border: 1px solid #D6E7E9;
    padding: 20px 20px 20px 100px;
    margin-left: 10px;
    min-height: 122px;
    background-color: #ffffff; }
    .node-composer.view-mode-list .content-wrapper h3 {
      margin: 5px 0 0 0; }
      .node-composer.view-mode-list .content-wrapper h3 a {
        text-decoration: none; }
        .node-composer.view-mode-list .content-wrapper h3 a:hover {
          color: #DE4744; }

.node-composer.view-mode-square {
  position: relative;
  height: 100%;
  background-color: #ffffff; }
  @media (max-width: 1149px) {
    .node-composer.view-mode-square .composer-image {
      margin-left: 20px; } }
  @media (min-width: 1100px) {
    .node-composer.view-mode-square .composer-image {
      position: absolute;
      top: 50px;
      left: -50px;
      width: 180px;
      height: auto; } }
  .node-composer.view-mode-square .composer-image img {
    vertical-align: top;
    max-width: 180px;
    height: auto; }
  .node-composer.view-mode-square .composer-content {
    background-color: #E7F2F4;
    height: 100%; }
    @media (max-width: 1149px) {
      .node-composer.view-mode-square .composer-content {
        padding: 60px 20px 20px 20px;
        margin-top: -40px; } }
    @media (min-width: 1100px) {
      .node-composer.view-mode-square .composer-content {
        padding: 50px 50px 50px 160px; } }
    .node-composer.view-mode-square .composer-content h3 {
      margin-top: 0;
      margin-bottom: 15px; }
      @media (max-width: 1149px) {
        .node-composer.view-mode-square .composer-content h3 {
          font-size: 1.875rem; } }
      @media (min-width: 1100px) {
        .node-composer.view-mode-square .composer-content h3 {
          font-size: 2.25rem; } }
      .node-composer.view-mode-square .composer-content h3 a {
        text-decoration: none;
        color: #185C71; }
    .node-composer.view-mode-square .composer-content .field-name-field-short-description {
      margin: 15px 0; }
    .node-composer.view-mode-square .composer-content .field-name-node-link a {
      margin-top: 15px;
      color: #ffffff;
      text-decoration: none; }

@media (max-width: 1149px) {
  .panel-row .col-sm-6:nth-child(1) .node-composer.view-mode-square {
    margin-bottom: 30px; } }

@media (min-width: 1100px) {
  .panel-row .col-sm-6:nth-child(1) .node-composer.view-mode-square {
    margin-left: 50px;
    margin-right: 30px; } }

@media (min-width: 1100px) {
  .panel-row .col-sm-6:nth-child(2) .node-composer.view-mode-square {
    margin-left: 80px; } }

.panel-row .col-sm-6 .panel-pane {
  height: 100%; }
  @media (min-width: 1100px) {
    .panel-row .col-sm-6 .panel-pane .fieldable-panels-pane {
      height: 100%; } }

.pane-composer-spotlight .node-composer.view-mode-square .composer-image {
  position: relative;
  top: auto;
  left: auto; }
  @media (max-width: 1149px) {
    .pane-composer-spotlight .node-composer.view-mode-square .composer-image {
      margin-left: 20px; } }
  @media (min-width: 1100px) {
    .pane-composer-spotlight .node-composer.view-mode-square .composer-image {
      margin-left: 50px; } }

.pane-composer-spotlight .node-composer.view-mode-square .composer-content {
  margin-top: -120px; }
  @media (max-width: 1149px) {
    .pane-composer-spotlight .node-composer.view-mode-square .composer-content {
      padding: 140px 20px 20px 20px; } }
  @media (min-width: 1100px) {
    .pane-composer-spotlight .node-composer.view-mode-square .composer-content {
      padding: 140px 50px 30px 50px; } }

.view-composer .filters {
  background: #E7F2F4 url("../images/icons/application/bg-shape--light.svg") no-repeat 90% 50%;
  background-size: 110% auto;
  padding: 20px;
  margin-bottom: 10px; }
  @media (min-width: 1100px) {
    .view-composer .filters {
      display: flex;
      flex-wrap: wrap;
      align-items: center; } }
  .view-composer .filters .views-exposed-form .views-exposed-widget {
    float: none;
    padding: 0; }
  @media (min-width: 1100px) {
    .view-composer .filters #views-exposed-form-composer-page {
      margin-bottom: 20px; } }
  @media (min-width: 1100px) {
    .view-composer .filters #views-exposed-form-composer-page {
      flex: 1 0 300px;
      max-width: 300px;
      margin-bottom: 0; } }
  .view-composer .filters #views-exposed-form-composer-page .views-exposed-widgets {
    margin: 0;
    position: relative; }
    .view-composer .filters #views-exposed-form-composer-page .views-exposed-widgets .form-item {
      margin: 0;
      position: relative; }
      .view-composer .filters #views-exposed-form-composer-page .views-exposed-widgets .form-item:before {
        content: '';
        position: absolute;
        top: 13px;
        left: 15px;
        background: url("../images/icons/application/search--dark.svg") no-repeat 90% 50%;
        width: 20px;
        height: 20px; }
      .view-composer .filters #views-exposed-form-composer-page .views-exposed-widgets .form-item .form-text {
        padding: 10px 12px 10px 42px; }
  .view-composer .filters #views-exposed-form-composer-page .views-submit-button {
    position: absolute;
    top: 2px;
    right: 2px;
    background: transparent url("../images/icons/application/arrow--right--dark.svg") no-repeat 60% 50%;
    width: 40px;
    height: 40px;
    text-indent: -9999px; }
  @media (max-width: 1099px) {
    .view-composer .filters .item-list {
      display: none; } }
  @media (min-width: 1100px) {
    .view-composer .filters .item-list {
      flex: 1 0 calc(100%-300px);
      max-width: calc(100%-300px);
      padding-left: 25px; } }
  .view-composer .filters .item-list .views-summary {
    list-style-type: none; }
    .view-composer .filters .item-list .views-summary li {
      display: inline-block;
      margin: 0;
      padding: 0; }
      .view-composer .filters .item-list .views-summary li a {
        padding: 5px;
        text-decoration: none; }

@media (min-width: 550px) {
  .view-composer .views-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; } }

@media (min-width: 550px) {
  .view-composer .views-content .views-row {
    flex: 1 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px; } }

@media (min-width: 1100px) {
  .view-composer .views-content .views-row {
    flex: 1 0 33.33%;
    max-width: 33.33%; } }

@media (max-width: 549px) {
  .view-composer .views-content .views-row:first-child .node-composer.view-mode-list {
    margin-top: 0; } }

@media (min-width: 1100px) {
  .field-name-manual-list .node-composer.view-mode-list:nth-child(3n+1) {
    display: inline-block;
    width: calc(33% - 10px);
    margin-right: 10px; } }

@media (min-width: 1100px) {
  .field-name-manual-list .node-composer.view-mode-list:nth-child(3n+2) {
    display: inline-block;
    width: calc(33% - 10px);
    margin-left: 10px; } }

@media (min-width: 1100px) {
  .field-name-manual-list .node-composer.view-mode-list:nth-child(3n+3) {
    display: inline-block;
    width: calc(33% - 10px);
    margin-left: 10px; } }

@media (min-width: 1100px) {
  .view-nieuws {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; } }

@media (min-width: 1100px) {
  .view-nieuws .views-row {
    flex: 1 0 33.333%;
    max-width: 33.333%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px; } }

.node-news img {
  vertical-align: top;
  width: 100%;
  height: auto; }

.node-news .field-name-post-date {
  color: #78BDCA;
  text-transform: uppercase;
  font-size: 0.9375rem;
  margin: 0 0 20px 0;
  font-weight: 700; }

.node-news.view-mode-search_result {
  max-width: 930px; }
  .node-news.view-mode-search_result h2 {
    color: #185C71;
    font-size: 1.5rem;
    margin: 25px 0 5px 0; }

.node-news.view-mode-square {
  position: relative;
  height: 100%;
  display: inline-flex;
  flex-flow: column;
  align-items: stretch;
  background-color: #ffffff; }
  @media (max-width: 1149px) {
    .node-news.view-mode-square {
      margin-bottom: 15px; } }
  .node-news.view-mode-square:hover {
    cursor: hover;
    box-shadow: inset 0px -3px #DE4744; }
    .node-news.view-mode-square:hover h3 {
      color: #DE4744; }
  .node-news.view-mode-square h3 {
    color: #185C71;
    font-size: 1.5rem;
    margin-top: 0; }
    .node-news.view-mode-square h3 a {
      text-decoration: none; }
      .node-news.view-mode-square h3 a:hover {
        color: #DE4744; }
  .node-news.view-mode-square .content-wrapper {
    border-left: 1px solid #D6E7E9;
    border-right: 1px solid #D6E7E9;
    border-bottom: 1px solid #D6E7E9;
    height: 100%; }
    @media (max-width: 1149px) {
      .node-news.view-mode-square .content-wrapper {
        padding: 15px 20px 20px 20px; } }
    @media (min-width: 1100px) {
      .node-news.view-mode-square .content-wrapper {
        padding: 25px 30px 30px 30px;
        flex: 1 1 auto; } }
  .node-news.view-mode-square .image-wrapper {
    position: relative; }
  .node-news.view-mode-square .field-name-news-label {
    background-color: #DAA32E;
    text-transform: uppercase;
    font-size: 0.9375rem;
    color: #ffffff;
    font-weight: 700;
    padding: 1px 5px;
    position: absolute;
    right: 0;
    bottom: 0; }

.node-news.node-teaser:hover {
  cursor: hover; }
  .node-news.node-teaser:hover h2 {
    color: #DE4744; }
  .node-news.node-teaser:hover .group-right {
    box-shadow: inset 0px -3px #DE4744; }

.node-news.node-teaser h2 {
  color: #185C71;
  font-size: 1.5rem; }

.node-news.node-teaser .group-right {
  border-left: 1px solid #D6E7E9;
  border-right: 1px solid #D6E7E9;
  border-bottom: 1px solid #D6E7E9; }
  @media (max-width: 1149px) {
    .node-news.node-teaser .group-right {
      padding: 20px; } }
  @media (min-width: 1100px) {
    .node-news.node-teaser .group-right {
      padding: 30px; } }

@media (min-width: 1100px) {
  .views-row-last + .element-invisible + .item-list {
    flex: 1 0 100%;
    padding-left: 15px; } }

.pane-views-exp-nieuws-page {
  background: #E7F2F4 url("../images/icons/application/bg-shape--light.svg") no-repeat 90% 50%;
  background-size: 110% auto;
  padding: 20px; }
  @media (min-width: 550px) {
    .pane-views-exp-nieuws-page .bef-checkboxes {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 550px) {
    .pane-views-exp-nieuws-page .bef-checkboxes .form-item {
      margin-right: 15px !important; } }
  .pane-views-exp-nieuws-page .bef-checkboxes .form-type-select:after {
    display: none !important; }

#views-exposed-form-nieuws-page .form-type-select:after {
  display: none !important; }

/*.paragraphs-item-paragraph-manual-list.view-mode-full {
  .node-news.view-mode-square {
    marginn-bottom: 30px;
  }
}*/
.node-score .field-name-field-composer {
  text-transform: uppercase;
  font-size: 0.9375rem;
  color: #78BDCA;
  font-weight: 700; }

.node-score h2 {
  color: #185C71;
  font-size: 1.875rem;
  margin: 7px 0; }
  @media (min-width: 1100px) {
    .node-score h2 {
      display: inline-block; } }

.node-score .field-name-bezetting-begeleiding {
  margin-top: 7px;
  color: #185C71;
  text-transform: uppercase;
  font-size: 0.9375rem; }

@media (max-width: 549px) {
  .node-score .field-name-field-soundcloud {
    overflow: hidden;
    padding-bottom: 60%;
    position: relative;
    height: 0; }
    .node-score .field-name-field-soundcloud iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute; } }

.node-score .media-youtube-video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0; }
  .node-score .media-youtube-video iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute; }

.node-score.view-mode-square {
  border: 1px solid #D6E7E9;
  background-color: #ffffff;
  width: 100%;
  margin: 15px 0; }
  @media (max-width: 1149px) {
    .node-score.view-mode-square {
      padding: 20px; } }
  @media (min-width: 1100px) {
    .node-score.view-mode-square {
      padding: 30px; } }
  .node-score.view-mode-square:hover {
    box-shadow: inset 0px -3px #DE4744; }
    .node-score.view-mode-square:hover h2 {
      color: #DE4744; }

.node-score.view-mode-list, .node-score.view-mode-search_result {
  background-color: #ffffff; }

@media (min-width: 550px) and (max-width: 1099px) {
  .node-score.view-mode-list img, .node-score.view-mode-search_result img {
    max-width: 400px; } }
  .node-score.view-mode-list h3, .node-score.view-mode-search_result h3 {
    margin: 5px 0; }
    .node-score.view-mode-list h3 a, .node-score.view-mode-search_result h3 a {
      text-decoration: none; }
      .node-score.view-mode-list h3 a:hover, .node-score.view-mode-search_result h3 a:hover {
        color: #DE4744; }
  .node-score.view-mode-list .field-name-field-short-description p, .node-score.view-mode-search_result .field-name-field-short-description p {
    margin: 15px 0; }

.score-count {
  color: #7A959D; }

.page-partituren .pane-pm-existing-pages .node-score {
  margin: 0;
  border-top: 1px solid #D6E7E9; }
  @media (max-width: 1149px) {
    .page-partituren .pane-pm-existing-pages .node-score {
      padding: 20px 0; } }
  @media (min-width: 1100px) {
    .page-partituren .pane-pm-existing-pages .node-score {
      padding: 40px 0; } }

.filter-wrapper + .extra-wrapper {
  color: #7A959D;
  text-decoration: none;
  margin: 15px 0; }
  @media (min-width: 1100px) {
    .filter-wrapper + .extra-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .filter-wrapper + .extra-wrapper .pane-score-sort {
    position: relative;
    padding-right: 15px;
    cursor: pointer; }
    .filter-wrapper + .extra-wrapper .pane-score-sort:after {
      content: '';
      position: absolute;
      top: 12px;
      right: 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #7A959D; }
    .filter-wrapper + .extra-wrapper .pane-score-sort:hover ul {
      display: block; }
    .filter-wrapper + .extra-wrapper .pane-score-sort ul {
      position: absolute;
      top: 100%;
      right: 0;
      width: 180px;
      display: none;
      margin: 0 -2px;
      padding: 0;
      border: 1px solid #D6E7E9;
      border-top: 0;
      z-index: 2;
      background-color: #ffffff; }
      .filter-wrapper + .extra-wrapper .pane-score-sort ul li:before {
        display: none; }
      .filter-wrapper + .extra-wrapper .pane-score-sort ul li a {
        display: block;
        padding: 7px 10px;
        text-decoration: none;
        border-top: 1px solid #D6E7E9;
        color: #7A959D; }
        .filter-wrapper + .extra-wrapper .pane-score-sort ul li a:hover, .filter-wrapper + .extra-wrapper .pane-score-sort ul li a:active, .filter-wrapper + .extra-wrapper .pane-score-sort ul li a:focus {
          color: #DE4744; }
  .filter-wrapper + .extra-wrapper + .panel-pane.pane-pm-existing-pages {
    padding-top: 0; }

.filter-wrapper {
  background: #E7F2F4 url("../images/icons/application/bg-shape--light.svg") no-repeat 90% 50%;
  background-size: 110% auto;
  padding: 20px; }
  @media (min-width: 550px) {
    .filter-wrapper .filters {
      display: flex;
      flex-wrap: wrap; } }
  .filter-wrapper .block-filter {
    position: relative;
    /*label.option {
      width: calc(100% - 20px);
    }*/ }
    @media (min-width: 550px) {
      .filter-wrapper .block-filter {
        flex: 1 0 33.33%;
        max-width: 33.33%; }
        .filter-wrapper .block-filter:not(:nth-child(1)):not(:nth-child(4)) .label {
          margin-left: 10px; }
        .filter-wrapper .block-filter:not(:nth-child(1)):not(:nth-child(4)) .item-list {
          left: 10px;
          width: calc(100% - 10px); } }
    @media (min-width: 1100px) {
      .filter-wrapper .block-filter {
        flex: 1 0 20%;
        max-width: 20%; }
        .filter-wrapper .block-filter:not(:nth-child(1)) .label {
          margin-left: 10px; }
        .filter-wrapper .block-filter:not(:nth-child(1)) .item-list {
          left: 10px;
          width: calc(100% - 10px); } }
    .filter-wrapper .block-filter .button.form-submit, .filter-wrapper .block-filter .field-name-user-edit a.form-submit, .field-name-user-edit .filter-wrapper .block-filter a.form-submit {
      box-shadow: none !important;
      height: 46px !important;
      padding: 10px 12px !important; }
      @media (max-width: 1149px) {
        .filter-wrapper .block-filter .button.form-submit, .filter-wrapper .block-filter .field-name-user-edit a.form-submit, .field-name-user-edit .filter-wrapper .block-filter a.form-submit {
          margin: 8px 0 0 0 !important;
          width: 100%; } }
      @media (min-width: 1100px) {
        .filter-wrapper .block-filter .button.form-submit, .filter-wrapper .block-filter .field-name-user-edit a.form-submit, .field-name-user-edit .filter-wrapper .block-filter a.form-submit {
          margin: 0 !important; } }
    .filter-wrapper .block-filter.open .item-list {
      display: block; }
    .filter-wrapper .block-filter.active .item-list {
      display: block;
      top: 1px;
      background-color: #ffffff;
      box-shadow: none; }
      .filter-wrapper .block-filter.active .item-list ul {
        padding: 5px 0; }
      .filter-wrapper .block-filter.active .item-list a.facetapi-active {
        font-weight: 500;
        color: #185C71;
        font-size: 0.9375rem; }
      .filter-wrapper .block-filter.active .item-list a.facetapi-inactive {
        display: none; }
    .filter-wrapper .block-filter .label {
      margin-top: 1px;
      padding: 10px 12px;
      background-color: #ffffff;
      border: 1px solid #D6E7E9;
      font-family: "brandon-grotesque", sans-serif;
      font-size: 0.9375rem;
      font-weight: 500 !important;
      border-radius: 0;
      display: block;
      transition: all 0.25s ease;
      color: #185C71 !important;
      position: relative; }
      .filter-wrapper .block-filter .label:after {
        content: "";
        position: absolute;
        right: 12px;
        bottom: 18px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #185C71;
        pointer-events: none; }
      .filter-wrapper .block-filter .label:focus {
        outline: none;
        border-color: #78BDCA; }
    .filter-wrapper .block-filter .item-list {
      display: none;
      position: absolute;
      z-index: 10;
      top: 48px;
      left: 0;
      right: 0;
      width: 100%;
      background-color: #ffffff;
      border: 1px solid #D6E7E9;
      box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2);
      padding: 0; }
      .filter-wrapper .block-filter .item-list .form-type-select:after {
        display: none; }
      .filter-wrapper .block-filter .item-list ul {
        list-style: none;
        margin: 0;
        padding: 5px; }
        .filter-wrapper .block-filter .item-list ul li {
          margin: 0; }
        .filter-wrapper .block-filter .item-list ul a {
          text-decoration: none;
          padding: 5px 7px;
          display: block;
          font-size: 0.9375rem; }
          .filter-wrapper .block-filter .item-list ul a:hover {
            color: #DE4744; }
          .filter-wrapper .block-filter .item-list ul a.facetapi-active {
            background: transparent url(../images/icons/application/close-blue.svg) no-repeat center right 8px;
            background-size: 10px auto;
            font-weight: bold; }
    .filter-wrapper .block-filter .bef-checkboxes .form-item.form-type-bef-checkbox {
      margin: 2px 0 !important; }

/* OLD: TO REMOVE */
.pane-views-exp-scores-page {
  background: #E7F2F4 url("../images/icons/application/bg-shape--light.svg") no-repeat 90% 50%;
  background-size: 110% auto;
  padding: 20px; }
  @media (min-width: 1100px) {
    .pane-views-exp-scores-page .views-exposed-widgets {
      display: flex;
      flex-wrap: wrap; } }
  .pane-views-exp-scores-page .views-exposed-widget {
    position: relative; }
    @media (min-width: 1100px) {
      .pane-views-exp-scores-page .views-exposed-widget {
        flex: 1 0 20%;
        max-width: 20%; }
        .pane-views-exp-scores-page .views-exposed-widget:not(:nth-child(1)) label:not(.option) {
          margin-left: 10px; }
        .pane-views-exp-scores-page .views-exposed-widget:not(:nth-child(1)) .views-widget {
          left: 10px;
          width: calc(100% - 10px); } }
    .pane-views-exp-scores-page .views-exposed-widget .button.form-submit, .pane-views-exp-scores-page .views-exposed-widget .field-name-user-edit a.form-submit, .field-name-user-edit .pane-views-exp-scores-page .views-exposed-widget a.form-submit {
      box-shadow: none !important;
      height: 46px !important;
      padding: 10px 12px !important; }
      @media (max-width: 1149px) {
        .pane-views-exp-scores-page .views-exposed-widget .button.form-submit, .pane-views-exp-scores-page .views-exposed-widget .field-name-user-edit a.form-submit, .field-name-user-edit .pane-views-exp-scores-page .views-exposed-widget a.form-submit {
          margin: 8px 0 0 0 !important;
          width: 100%; } }
      @media (min-width: 1100px) {
        .pane-views-exp-scores-page .views-exposed-widget .button.form-submit, .pane-views-exp-scores-page .views-exposed-widget .field-name-user-edit a.form-submit, .field-name-user-edit .pane-views-exp-scores-page .views-exposed-widget a.form-submit {
          margin: 0 !important; } }
    .pane-views-exp-scores-page .views-exposed-widget.open .views-widget {
      display: block; }
    .pane-views-exp-scores-page .views-exposed-widget label:not(.option) {
      margin-top: 1px;
      padding: 10px 12px;
      background-color: #ffffff;
      border: 1px solid #D6E7E9;
      font-family: "brandon-grotesque", sans-serif;
      font-size: 0.9375rem;
      font-weight: 500 !important;
      border-radius: 0;
      display: block;
      transition: all 0.25s ease;
      color: #185C71 !important;
      position: relative; }
      .pane-views-exp-scores-page .views-exposed-widget label:not(.option):after {
        content: "";
        position: absolute;
        right: 12px;
        bottom: 20px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #185C71;
        pointer-events: none; }
      .pane-views-exp-scores-page .views-exposed-widget label:not(.option):focus {
        outline: none;
        border-color: #78BDCA; }
    .pane-views-exp-scores-page .views-exposed-widget label.option {
      width: calc(100% - 20px); }
    .pane-views-exp-scores-page .views-exposed-widget .views-widget {
      display: none;
      position: absolute;
      z-index: 10;
      top: 48px;
      left: 0;
      right: 0;
      width: 100%;
      background-color: #ffffff;
      border: 1px solid #D6E7E9;
      box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2);
      padding: 20px; }
      .pane-views-exp-scores-page .views-exposed-widget .views-widget .form-type-select:after {
        display: none; }
    .pane-views-exp-scores-page .views-exposed-widget .bef-checkboxes .form-item.form-type-bef-checkbox {
      margin: 2px 0 !important; }

.group-aan-de-slag {
  padding-top: 15px; }
  .group-aan-de-slag .field-name-field-author {
    font-style: italic; }
    @media (min-width: 1100px) {
      .group-aan-de-slag .field-name-field-author {
        display: inline-block;
        margin-left: 15px; } }
    .group-aan-de-slag .field-name-field-author .label-inline,
    .group-aan-de-slag .field-name-field-author .field-name-user-name-and-role {
      display: inline-block;
      margin-right: 4px; }

.field-name-need-help {
  margin-top: 1.5rem;
  font-style: italic; }

@media (max-width: 1149px) {
  .field-name-score-icons {
    margin: 10px 0; } }

@media (min-width: 1100px) {
  .field-name-score-icons {
    float: right; } }

.field-name-score-icons .icon {
  display: inline-block;
  text-indent: -9999px;
  overflow: hidden;
  width: 36px;
  height: 24px; }
  @media (max-width: 1149px) {
    .field-name-score-icons .icon {
      margin-right: 10px; } }
  @media (min-width: 1100px) {
    .field-name-score-icons .icon {
      margin-left: 10px; } }
  .field-name-score-icons .icon.icon-pdf {
    background: url("../images/icons/application/content-pdf.svg") no-repeat center center; }
  .field-name-score-icons .icon.icon-video {
    background: url("../images/icons/application/content-video.svg") no-repeat center center; }
  .field-name-score-icons .icon.icon-sheet {
    background: url("../images/icons/application/content-music.svg") no-repeat center center; }
  .field-name-score-icons .icon.icon-description {
    background: url("../images/icons/application/content-aan-de-slag.svg") no-repeat center center; }

.field-name-field-pdf {
  margin-top: 5px; }
  .field-name-field-pdf .file {
    display: block; }

.view-questions .item-list {
  padding: 20px 0; }
  .view-questions .item-list ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .view-questions .item-list .views-row {
    border: 1px solid #D6E7E9;
    margin: -1px 0 0 0;
    padding: 0;
    list-style-type: none; }
    @media (max-width: 1149px) {
      .view-questions .item-list .views-row {
        padding: 20px; } }
    @media (min-width: 1100px) {
      .view-questions .item-list .views-row {
        padding: 25px 30px; } }
    .view-questions .item-list .views-row:before {
      display: none; }

.view-questions .field-name-question-link a {
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 25px; }
  .view-questions .field-name-question-link a:hover {
    color: #DE4744; }

.view-questions .field-name-node-author-name-and-role a {
  text-decoration: none;
  color: #78BDCA;
  text-transform: uppercase;
  font-size: 0.9375rem;
  margin: 7px 0 0 0;
  font-weight: 600;
  display: inline-block; }
  .view-questions .field-name-node-author-name-and-role a:hover {
    color: #DE4744; }

.view-questions .field-name-comment-count {
  color: #7A959D; }

.field-name-post-date {
  color: #78BDCA;
  text-transform: uppercase;
  font-size: 0.9375rem;
  margin: 0 0 7px 0;
  font-weight: 600; }

.node-question.view-mode-search_result {
  max-width: 930px; }
  .node-question.view-mode-search_result h2 {
    color: #185C71;
    font-size: 1.5rem;
    margin: 25px 0 5px 0; }

/* In manual list */
.node-question.view-mode-list {
  border: 1px solid #D6E7E9;
  margin: -1px 0 0 0;
  padding: 0;
  list-style-type: none; }
  @media (max-width: 1149px) {
    .node-question.view-mode-list {
      padding: 20px; } }
  @media (min-width: 1100px) {
    .node-question.view-mode-list {
      padding: 25px 30px; } }
  .node-question.view-mode-list:before {
    display: none; }
  .node-question.view-mode-list .field-name-question-link a {
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1875rem;
    line-height: 25px; }
    .node-question.view-mode-list .field-name-question-link a:hover {
      color: #DE4744; }
  .node-question.view-mode-list .field-name-node-author-name-and-role a {
    text-decoration: none;
    color: #78BDCA;
    text-transform: uppercase;
    font-size: 0.9375rem;
    margin: 7px 0 0 0;
    font-weight: 600;
    display: inline-block; }
    .node-question.view-mode-list .field-name-node-author-name-and-role a:hover {
      color: #DE4744; }
  .node-question.view-mode-list .field-name-comment-count {
    color: #7A959D; }

.page-search-content .search-form {
  background-color: #E7F2F4;
  padding: 25px; }
  @media (max-width: 1149px) {
    .page-search-content .search-form {
      margin-bottom: 20px; } }
  @media (min-width: 1100px) {
    .page-search-content .search-form {
      margin-bottom: 40px; } }
  .page-search-content .search-form .form-item {
    margin: 0; }
    @media (min-width: 1100px) {
      .page-search-content .search-form .form-item.form-item-keys {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1 0 calc(100% - 90px);
        max-width: calc(100% - 90px);
        padding-right: 10px; }
        .page-search-content .search-form .form-item.form-item-keys label {
          flex: 1 0 180px;
          max-width: 180px; }
        .page-search-content .search-form .form-item.form-item-keys input {
          flex: 1 0 calc(100% - 180px);
          max-width: calc(100% - 180px);
          margin: 0; } }
  @media (min-width: 1100px) {
    .page-search-content .search-form .form-wrapper {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 1100px) {
    .page-search-content .search-form .button.form-submit, .page-search-content .search-form .field-name-user-edit a.form-submit, .field-name-user-edit .page-search-content .search-form a.form-submit {
      flex: 1 0 90px;
      max-width: 90px;
      height: 44px !important; } }

.page {
  /* Profile fieldset */ }
  .page #user-pass-reset {
    max-width: 930px;
    margin: 0 auto; }
  .page #edit-account {
    max-width: 930px; }
  .page #edit-profile-main-field-music-profile table th, .page #edit-profile-main-field-music-profile table td,
  .page #edit-profile-main table th,
  .page #edit-profile-main table td {
    padding: 0 0 5px 0; }
  .page #edit-profile-main-field-music-profile table tr th,
  .page #edit-profile-main table tr th {
    background-color: transparent;
    border: none; }
  .page #edit-profile-main-field-music-profile tr.odd,
  .page #edit-profile-main tr.odd {
    background-color: transparent;
    border: none; }
  .page #edit-profile-main-field-music-profile tbody,
  .page #edit-profile-main tbody {
    border: none; }
  .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button, .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a, .field-name-user-edit .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a,
  .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button,
  .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a,
  .field-name-user-edit .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    padding: 12px;
    vertical-align: middle;
    border: 0;
    font-family: "brandon-grotesque", sans-serif;
    font-size: 0.9375rem;
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: #78BDCA;
    border-radius: 0;
    -webkit-appearance: none;
    cursor: pointer;
    transition: all 0.25s ease;
    height: 40px;
    box-shadow: none; }
    .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button:hover, .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a:hover, .field-name-user-edit .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a:hover, .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button:focus, .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a:focus, .field-name-user-edit .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a:focus,
    .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button:hover,
    .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a:hover,
    .field-name-user-edit .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a:hover,
    .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button:focus,
    .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a:focus,
    .field-name-user-edit .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a:focus {
      color: #ffffff;
      text-decoration: none;
      background-color: #92cad4; }
    .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button:active, .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a:active, .field-name-user-edit .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a:active, .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button:focus, .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a:focus, .field-name-user-edit .page #edit-profile-main-field-music-profile form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a:focus,
    .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button:active,
    .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a:active,
    .field-name-user-edit .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a:active,
    .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .button:focus,
    .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .field-name-user-edit a:focus,
    .field-name-user-edit .page #edit-profile-main form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) a:focus {
      outline: 0; }

/* User forms */
.page-user-edit .field-type-field-collection .field-multiple-table tr,
.page-user-edit .field-type-field-collection .field-multiple-table tr td,
.page-user-register .field-type-field-collection .field-multiple-table tr,
.page-user-register .field-type-field-collection .field-multiple-table tr td {
  background: transparent !important;
  border-radius: 0 !important; }

.page-user-edit .field-type-field-collection .field-multiple-table td > *,
.page-user-edit .field-type-field-collection .field-multiple-table td .ajax-new-content > *,
.page-user-register .field-type-field-collection .field-multiple-table td > *,
.page-user-register .field-type-field-collection .field-multiple-table td .ajax-new-content > * {
  margin-bottom: 5px; }

@media (min-width: 1100px) {
  .page-user-edit .field-type-field-collection .field-multiple-table .form-wrapper,
  .page-user-register .field-type-field-collection .field-multiple-table .form-wrapper {
    margin: 0 1em 0 0; }
  .page-user-edit .field-type-field-collection .field-multiple-table .form-text,
  .page-user-register .field-type-field-collection .field-multiple-table .form-text {
    max-width: 220px; }
  .page-user-edit .field-type-field-collection .field-multiple-table td > *,
  .page-user-edit .field-type-field-collection .field-multiple-table td .ajax-new-content > *,
  .page-user-register .field-type-field-collection .field-multiple-table td > *,
  .page-user-register .field-type-field-collection .field-multiple-table td .ajax-new-content > * {
    float: left;
    margin-bottom: 0; }
  .page-user-edit .field-type-field-collection .field-multiple-table tr,
  .page-user-register .field-type-field-collection .field-multiple-table tr {
    padding: 0;
    border: 0 none; } }

.page-user-edit .field-type-field-collection .field-multiple-table td,
.page-user-register .field-type-field-collection .field-multiple-table td {
  padding: 10px 0; }
  .page-user-edit .field-type-field-collection .field-multiple-table td .form-item,
  .page-user-register .field-type-field-collection .field-multiple-table td .form-item {
    margin-top: 0; }

.page-user-edit .field-type-field-collection .field-multiple-table td input.button,
.page-user-register .field-type-field-collection .field-multiple-table td input.button {
  border: 0 none;
  padding: 0;
  box-shadow: none;
  background: transparent url(../images/icons/application/delete.png) no-repeat center center;
  background-size: 15px 17px;
  text-indent: -10000px;
  overflow: hidden;
  width: 30px;
  height: 30px; }
  @media (min-width: 1100px) {
    .page-user-edit .field-type-field-collection .field-multiple-table td input.button,
    .page-user-register .field-type-field-collection .field-multiple-table td input.button {
      margin: 29px 0 0 0; } }
  .page-user-edit .field-type-field-collection .field-multiple-table td input.button:hover,
  .page-user-register .field-type-field-collection .field-multiple-table td input.button:hover {
    background-color: transparent; }

.page-user-edit .field-type-field-collection .button.field-add-more-submit, .page-user-edit .field-type-field-collection .field-name-user-edit a.field-add-more-submit, .field-name-user-edit .page-user-edit .field-type-field-collection a.field-add-more-submit,
.page-user-register .field-type-field-collection .button.field-add-more-submit,
.page-user-register .field-type-field-collection .field-name-user-edit a.field-add-more-submit,
.field-name-user-edit .page-user-register .field-type-field-collection a.field-add-more-submit {
  padding: 10px 10px 10px 0;
  background: transparent;
  text-decoration: underline;
  color: #185C71;
  font-weight: normal;
  box-shadow: none;
  text-transform: none; }
  .page-user-edit .field-type-field-collection .button.field-add-more-submit:hover, .page-user-edit .field-type-field-collection .field-name-user-edit a.field-add-more-submit:hover, .field-name-user-edit .page-user-edit .field-type-field-collection a.field-add-more-submit:hover, .page-user-edit .field-type-field-collection .button.field-add-more-submit:focus, .page-user-edit .field-type-field-collection .field-name-user-edit a.field-add-more-submit:focus, .field-name-user-edit .page-user-edit .field-type-field-collection a.field-add-more-submit:focus,
  .page-user-register .field-type-field-collection .button.field-add-more-submit:hover,
  .page-user-register .field-type-field-collection .field-name-user-edit a.field-add-more-submit:hover,
  .field-name-user-edit .page-user-register .field-type-field-collection a.field-add-more-submit:hover,
  .page-user-register .field-type-field-collection .button.field-add-more-submit:focus,
  .page-user-register .field-type-field-collection .field-name-user-edit a.field-add-more-submit:focus,
  .field-name-user-edit .page-user-register .field-type-field-collection a.field-add-more-submit:focus {
    color: #027b91;
    background-color: transparent; }

.page-user-edit .field-type-field-collection .order-weight,
.page-user-register .field-type-field-collection .order-weight {
  display: none; }

.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-0--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-1--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-2--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-3--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-4--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-5--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-6--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-7--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-8--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-9--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-10--weight::after,
.page-user-edit .field-type-field-collection .form-item-profile-main-field-music-profile-und-11--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-0--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-1--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-2--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-3--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-4--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-5--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-6--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-7--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-8--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-9--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-10--weight::after,
.page-user-register .field-type-field-collection .form-item-profile-main-field-music-profile-und-11--weight::after {
  display: none !important; }

/* Registration form */
.page-user-register .page-content form,
.page-user-edit .page-content form {
  max-width: 930px; }
  .page-user-register .page-content form .profile-name,
  .page-user-edit .page-content form .profile-name {
    display: flex;
    margin: 0 -10px; }
    .page-user-register .page-content form .profile-name > div,
    .page-user-edit .page-content form .profile-name > div {
      width: calc(50% - 20px);
      margin: 0 10px; }
  .page-user-register .page-content form .form-item-field-country-und,
  .page-user-edit .page-content form .form-item-field-country-und {
    margin: 10px 0 20px !important; }

.group-contact {
  display: flex;
  flex-wrap: wrap; }
  .group-contact h3 {
    width: 100%; }
  .group-contact > div {
    width: calc(50% - 15px);
    margin-right: 15px; }

/* User detail page */
.view-mode-account .field {
  margin: 0; }
  .view-mode-account .field .field-collection-view {
    border-bottom: 0 none;
    border-top: 1px solid #f4f2f1;
    margin: 1em 0 0.5em;
    padding: 1em 0 0.5em; }

.pane-user-profile > .pane-inner > h3 {
  display: none; }

.pane-user-profile .field-name-ds-user-picture {
  margin-bottom: 1em; }

.pane-user-profile .label-inline {
  font-weight: bold; }

/**
 * Password strength indicator.
 */
.password-strength {
  width: 17em;
  float: right;
  /* LTR */ }

.password-strength-title {
  display: inline; }

.password-strength-text {
  float: right;
  /* LTR */
  font-weight: bold; }

.password-indicator {
  background-color: #C4C4C4;
  height: 0.3em;
  width: 100%; }

.password-indicator div {
  height: 100%;
  width: 0%;
  background-color: #47C965; }

input.password-confirm,
input.password-field {
  width: 16em;
  margin-bottom: 0.4em; }

div.password-confirm {
  float: right;
  /* LTR */
  visibility: hidden;
  width: 17em; }

div.form-item div.password-suggestions {
  padding: 0.2em 0.5em;
  margin: 0.7em 0;
  width: 38.5em;
  border: 1px solid #B4B4B4; }

div.password-suggestions ul {
  margin-bottom: 0; }

.confirm-parent,
.password-parent {
  clear: left;
  /* LTR */
  margin: 0;
  width: 750px; }

/* Registration */
.page-bijna-geregistreerd .pane-bundle-text,
.page-user-reset .pane-bundle-text {
  max-width: none; }

.field-name-field-user-picture input.form-file {
  display: inline-block !important; }

.i18n-en {
  /*
  .top-bar {
    z-index: 2;
    position: relative;
    background-color: rgba(0,0,0,.95);
    color: $white;
    a {
      color: $white;
    }
    .user {
      @include media-breakpoint-up(md) {
        text-align: right;
        padding-right: 15px;
      }
    }
  }
  .main-language .language-switcher-locale-url {
    background-color: #111;
  }
  .footer-top {
    background: $primary-color-en;
    .col-md-3:nth-child(1), .col-md-3:nth-child(2) {
      a {
        color: $white;
        text-decoration: none;
        text-shadow: 0.03em 0 $primary-color-en, -0.03em 0 $primary-color-en, 0 0.03em $primary-color-en, 0 -0.03em $primary-color-en, 0.06em 0 $primary-color-en, -0.06em 0 $primary-color-en, 0.09em 0 $primary-color-en, -0.09em 0 $primary-color-en, 0.12em 0 $primary-color-en, -0.12em 0 $primary-color-en, 0.15em 0 $primary-color-en, -0.15em 0 $primary-color-en;
        background: linear-gradient($primary-color-en,$primary-color-en),linear-gradient($primary-color-en,$primary-color-en),linear-gradient($white,$white);
        background-size: .05em 1px,.05em 1px,1px 1px;
        background-repeat: no-repeat,no-repeat,repeat-x;
        background-position: 0 95%,100% 95%,0 95%;
        &:hover, &:focus, &:active {
          background: linear-gradient($primary-color-en,$primary-color-en),linear-gradient($primary-color-en,$primary-color-en),linear-gradient($white,$white);
          background-size: .05em 1px,.05em 1px,1px 1px;
          background-repeat: no-repeat,no-repeat,repeat-x;
          background-position: 0 95%,100% 95%,0 95%;
        }
      }
    }
  }
  .pane-bundle-slogan .fieldable-panels-pane {
    background: $primary-color-en;
  }
  .btn-blue {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    padding: 12px 25px 10px 12px;
    vertical-align: middle;
    border: 0;
    font-family: $font-family--headers;
    font-size: rem-calc(15);
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: $primary-color-en;
    border-radius: 0;
    -webkit-appearance: none;
    cursor: pointer;
    transition: $transition;
    height: 40px;
    &:after {
      content: '';
      position: absolute;
      top: 15px;
      right: 10px;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 6px solid $white;
    }
    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
      background-color: lighten($primary-color-en, 7%);
    }
    &:active,
    &:focus {
      outline: 0;
    }
  }
  h2.pane-title,
  .node-score:not(.view-mode-search_result) h2,
  .node-composer.view-mode-square .composer-content h3 a,
  h1,
  .one-sidebar .main h2 {
    color: $primary-color-en;
  }
  .node-score:not(.view-mode-search_result) .field-name-bezetting-begeleiding {
    color: #111;
  }
  .panel-row.colored.darkblue {
    background: $primary-color-en url('../images/icons/application/bg-shape--dark-pink.svg') no-repeat 90% 50%;
  }
  .node-score:not(.view-mode-search_result) .field-name-field-composer,
  .one-sidebar .main h3,
  .panel-pane.pane-score-info h3,
  .panel-pane.related:not(.pane-news-related) h3,
  .node-composer .date-display-single,
  .view-questions .field-name-node-author-name-and-role a,
  h2 {
    color: $secondary-color-en;
  }
  .node-score:not(.view-mode-search_result).view-mode-square:hover {
    box-shadow: inset 0 -3px $primary-color-en;
  }
  #sliding-popup.sliding-popup-bottom {
    background-color: darken($primary-color-en,10%) !important;
  }
  .panel-row .panel-pane.colored.yellow {
    background-color: darken($secondary-color-en,7%);
    .field-name-field-link {
      &:after {
        border-left: 6px solid $secondary-color-en;
      }
      a {
        color: $secondary-color-en;
        &:hover, &:focus, &:active {
          color: $primary-color-en;
        }
      }
    }
  }
  .pane-bundle-slogan .field-name-field-slogan-link {
    a {
      color: $primary-color-en;
      &:after {
        border-left: 6px solid $primary-color-en;
      }
      &:hover, &:focus, &:active {
        color: $primary-color-en;
        &:after {
          border-left: 6px solid $primary-color-en;
        }
      }
    }
  }
  a {
    color: $primary-color-en;
    &:hover {
      color: darken($primary-color-en, 7%);
    }
  }
  .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text a:hover {
    color: darken($primary-color-en, 7%);
  }
  .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text:after {
    border-left: 6px solid $primary-color-en;
  }
  h3 {
    color: #111;
  }
  .field-name-field-qa h3:after, .pane-bundle-faq h3:after {
    border-top: 6px solid #111;
  }
  .field-name-field-qa .open h3, .pane-bundle-faq .open h3 {
    color: darken($secondary-color-en,7%);
  }
  .btn-white:after {
    border-left: 6px solid $primary-color-en;
  }
  .field-name-field-qa .open h3:after, .pane-bundle-faq .open h3:after {
    border-bottom: 6px solid $secondary-color-en;
    border-top: none;
  }
  .swiper-container .swiper-button-prev {
    background-image: url(../images/icons/application/swiper--prev--en.svg);
  }
  .swiper-container .swiper-button-next {
    background-image: url(../images/icons/application/swiper--next--en.svg);
  }
  .pane-views-exp-scores-page .views-exposed-widget label:not(.option) {
    color: #111 !important;
  }
  .pane-views-exp-scores-page .views-exposed-widget label:not(.option):after {
    border-top: 5px solid #111;
  }
  .page form:not(.ctools-use-modal-processed) input, .page form:not(.ctools-use-modal-processed) select, .page form:not(.ctools-use-modal-processed) textarea {
    color: #111;
  }
  .node-news.view-mode-square h3 a:hover {
    color: $primary-color-en;
  }
  .node-news.view-mode-square:hover {
    box-shadow: inset 0 -3px $primary-color-en;
  }
  .node-composer.view-mode-list .content-wrapper h3 a:hover {
    color: $primary-color-en;
  }
  .node-composer.view-mode-list:hover .content-wrapper {
    box-shadow: inset 0 -3px $primary-color-en;
  }
  .score-count {
    color: #111;
  }
  .download-list li:before, .links li:before, ul:not([class]) li:before {
    background-color: #111;
  }
  div.horizontal-tabs li.horizontal-tab-button a, div.horizontal-tabs li.horizontal-tab-button a strong {
    color: #111;
  }
  div.horizontal-tabs li.horizontal-tab-button.selected a strong, div.horizontal-tabs li.horizontal-tab-button a.active strong {
    color: $primary-color-en;
  }
  div.horizontal-tabs li.horizontal-tab-button.selected a:before, div.horizontal-tabs li.horizontal-tab-button a.active:before {
    background: $primary-color-en;
  }
  div.horizontal-tabs li.horizontal-tab-button.selected a:after, div.horizontal-tabs li.horizontal-tab-button a.active:after {
    border-top: 7px solid $primary-color-en;
  }
  .page form:not(.ctools-use-modal-processed) .js-hide {
    display: none;
  }
  .node-score:not(.view-mode-search_result).view-mode-square:hover h2 {
    color: $primary-color-en;
  }
  .node-news .field-name-post-date {
    color: darken($secondary-color-en,7%);
  }
  div.horizontal-tabs li.horizontal-tab-button a:active, div.horizontal-tabs li.horizontal-tab-button a:focus, div.horizontal-tabs li.horizontal-tab-button a:hover {
    color: $primary-color-en;
  }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-bef-checkbox label, form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-checkbox label {
    color: #111;
  }*/ }
  .i18n-en .menu-wrapper {
    background-color: #66b4c3; }
  .i18n-en .main-header {
    background-color: #78bdca; }
    .i18n-en .main-header:before {
      background-color: #5DA3B0;
      opacity: .65; }
    .i18n-en .main-header:after {
      background-color: #5DA3B0;
      opacity: .65; }
    .i18n-en .main-header .header-intro-width .button, .i18n-en .main-header .header-intro-width .field-name-user-edit a, .field-name-user-edit .i18n-en .main-header .header-intro-width a {
      color: #5DA3B0;
      background-color: #ffffff; }
      .i18n-en .main-header .header-intro-width .button:after, .i18n-en .main-header .header-intro-width .field-name-user-edit a:after, .field-name-user-edit .i18n-en .main-header .header-intro-width a:after {
        border-left: 6px solid #5DA3B0; }
      .i18n-en .main-header .header-intro-width .button:hover, .i18n-en .main-header .header-intro-width .field-name-user-edit a:hover, .field-name-user-edit .i18n-en .main-header .header-intro-width a:hover, .i18n-en .main-header .header-intro-width .button:active, .i18n-en .main-header .header-intro-width .field-name-user-edit a:active, .field-name-user-edit .i18n-en .main-header .header-intro-width a:active, .i18n-en .main-header .header-intro-width .button:focus, .i18n-en .main-header .header-intro-width .field-name-user-edit a:focus, .field-name-user-edit .i18n-en .main-header .header-intro-width a:focus {
        color: #4c909d; }
        .i18n-en .main-header .header-intro-width .button:hover:after, .i18n-en .main-header .header-intro-width .field-name-user-edit a:hover:after, .field-name-user-edit .i18n-en .main-header .header-intro-width a:hover:after, .i18n-en .main-header .header-intro-width .button:active:after, .i18n-en .main-header .header-intro-width .field-name-user-edit a:active:after, .field-name-user-edit .i18n-en .main-header .header-intro-width a:active:after, .i18n-en .main-header .header-intro-width .button:focus:after, .i18n-en .main-header .header-intro-width .field-name-user-edit a:focus:after, .field-name-user-edit .i18n-en .main-header .header-intro-width a:focus:after {
          border-left: 6px solid #5DA3B0; }
  .i18n-en.node-type-panopoly-landing-page .site-logo {
    background: url("../images/logo/logo--white.svg") no-repeat; }
    @media (max-width: 1099px) {
      .i18n-en.node-type-panopoly-landing-page .site-logo {
        width: 77px;
        height: 50px;
        background-size: 77px 50px; } }
    @media (min-width: 550px) {
      .i18n-en.node-type-panopoly-landing-page .site-logo {
        width: 153px;
        height: 100px;
        background-size: 153px 100px; } }
    .i18n-en.node-type-panopoly-landing-page .site-logo img {
      display: none; }
  .i18n-en .site-logo {
    background: url("../images/logo/logo--white.svg") no-repeat; }
    @media (max-width: 1099px) {
      .i18n-en .site-logo {
        width: 77px;
        height: 50px;
        background-size: 77px 50px; } }
    @media (min-width: 550px) {
      .i18n-en .site-logo {
        width: 123px;
        height: 80px;
        background-size: 123px 80px; } }
    .i18n-en .site-logo img {
      display: none; }

#search-form {
  max-width: none; }
  #search-form .results-info {
    display: flex;
    padding: 20px 0 0; }
    @media (max-width: 1099px) {
      #search-form .results-info a.text-button {
        display: none; } }
    @media (min-width: 550px) {
      #search-form .results-info a.text-button {
        display: block;
        text-align: right;
        margin-left: auto; } }

.library-filter {
  background: #E7F2F4;
  width: 100%;
  padding: 20px 40px 30px; }
  .library-filter .form-item {
    margin: 10px 0 !important; }
  .library-filter .text-button {
    margin: 0 30px; }

@media (min-width: 550px) {
  .page-bibliotheek .pane-pm-existing-pages {
    display: flex;
    flex-wrap: wrap; } }

.node-library-composer .label-inline {
  font-weight: 600; }

.node-library-composer .content-inline,
.node-library-composer .label-inline {
  display: inline-block; }

@media (min-width: 550px) {
  .bib-search-results {
    display: flex;
    flex-wrap: wrap; }
    .bib-search-results .node {
      width: calc(50% - 15px); }
      .bib-search-results .node:nth-child(2n+1) {
        margin-right: 15px; }
      .bib-search-results .node:nth-child(2n) {
        margin-left: 15px; } }

.node-library-score .label-inline {
  font-weight: 600; }

.node-library-score .content-inline,
.node-library-score .label-inline {
  display: inline-block; }

@media (max-width: 1099px) {
  .node-library-score.view-mode-full .group-composition,
  .node-library-score.view-mode-full .group-edition {
    margin-top: 30px;
    margin-bottom: 30px; } }

@media (min-width: 550px) {
  .node-library-score.view-mode-full .group-composition,
  .node-library-score.view-mode-full .group-edition {
    margin-top: 30px; } }

.node-library-score.view-mode-full .group-bib.highlight {
  background: #E7F2F4 url("../images/icons/application/bg-shape--light.svg") no-repeat 90% 50%;
  background-size: 110% auto;
  margin-top: 30px; }
  @media (max-width: 1149px) {
    .node-library-score.view-mode-full .group-bib.highlight {
      padding: 25px; } }
  @media (min-width: 1100px) {
    .node-library-score.view-mode-full .group-bib.highlight {
      padding: 30px; } }
  .node-library-score.view-mode-full .group-bib.highlight h3 {
    margin-top: 0; }
  .node-library-score.view-mode-full .group-bib.highlight .label-inline {
    font-weight: 400; }

.node-library-score.view-mode-square, .node-library-score.view-mode-search_result {
  position: relative;
  border: 1px solid #D6E7E9; }
  .node-library-score.view-mode-square .field-name-bundle, .node-library-score.view-mode-search_result .field-name-bundle {
    background-color: #DAA32E;
    text-transform: uppercase;
    font-size: 0.9375rem;
    color: #ffffff;
    font-weight: 700;
    padding: 1px 5px;
    position: absolute;
    right: 0;
    bottom: 0; }
  @media (max-width: 1099px) {
    .node-library-score.view-mode-square, .node-library-score.view-mode-search_result {
      padding: 20px;
      margin-bottom: 15px; } }
  @media (min-width: 550px) {
    .node-library-score.view-mode-square, .node-library-score.view-mode-search_result {
      padding: 30px;
      margin-bottom: 30px; } }
  .node-library-score.view-mode-square:hover h2 a, .node-library-score.view-mode-search_result:hover h2 a {
    color: #DE4744; }
  .node-library-score.view-mode-square h2, .node-library-score.view-mode-search_result h2 {
    margin: 0 0 7px 0;
    font-size: 1.5rem; }
    .node-library-score.view-mode-square h2 a, .node-library-score.view-mode-search_result h2 a {
      text-decoration: none; }
      .node-library-score.view-mode-square h2 a:hover, .node-library-score.view-mode-square h2 a:focus, .node-library-score.view-mode-square h2 a:active, .node-library-score.view-mode-search_result h2 a:hover, .node-library-score.view-mode-search_result h2 a:focus, .node-library-score.view-mode-search_result h2 a:active {
        color: #DE4744; }

.node-calendar.view-mode-search_result {
  max-width: 930px; }
  .node-calendar.view-mode-search_result h2 {
    color: #185C71;
    font-size: 1.5rem;
    margin: 25px 0 5px 0; }

.node-faq.node-teaser h3 {
  color: #78BDCA; }

.btn-red, .main-header .header-intro-width .button, .main-header .header-intro-width .field-name-user-edit a, .field-name-user-edit .main-header .header-intro-width a {
  position: relative;
  display: inline-block;
  margin: 10px 0 0 0;
  padding: 12px 25px 10px 12px;
  vertical-align: middle;
  border: 0;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: #DE4744;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: all 0.25s ease;
  height: 40px; }
  .btn-red:after, .main-header .header-intro-width .button:after, .main-header .header-intro-width .field-name-user-edit a:after, .field-name-user-edit .main-header .header-intro-width a:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #ffffff; }
  .btn-red:hover, .main-header .header-intro-width .button:hover, .main-header .header-intro-width .field-name-user-edit a:hover, .field-name-user-edit .main-header .header-intro-width a:hover, .btn-red:focus, .main-header .header-intro-width .button:focus, .main-header .header-intro-width .field-name-user-edit a:focus, .field-name-user-edit .main-header .header-intro-width a:focus {
    color: #DE4744;
    text-decoration: none;
    background-color: #ffffff; }
    .btn-red:hover:after, .main-header .header-intro-width .button:hover:after, .main-header .header-intro-width .field-name-user-edit a:hover:after, .field-name-user-edit .main-header .header-intro-width a:hover:after, .btn-red:focus:after, .main-header .header-intro-width .button:focus:after, .main-header .header-intro-width .field-name-user-edit a:focus:after, .field-name-user-edit .main-header .header-intro-width a:focus:after {
      border-left: 6px solid #DE4744; }
  .btn-red:active, .main-header .header-intro-width .button:active, .main-header .header-intro-width .field-name-user-edit a:active, .field-name-user-edit .main-header .header-intro-width a:active, .btn-red:focus, .main-header .header-intro-width .button:focus, .main-header .header-intro-width .field-name-user-edit a:focus, .field-name-user-edit .main-header .header-intro-width a:focus {
    outline: 0; }

.btn-blue, .panel-pane.pane-user-profile .field-name-user-edit a, .panel-row .panel-pane .field-name-field-link, .entity-paragraphs-item .button, .entity-paragraphs-item .field-name-user-edit a, .field-name-user-edit .entity-paragraphs-item a, .paragraphs-item-paragraph-button a, .paragraphs-item-paragraph-text-image .field-name-field-link a, .paragraphs-item-paragraph-manual-list .field-name-field-link, .node-composer.view-mode-square .composer-content .field-name-node-link, .button, .field-name-user-edit a, .page form:not(.ctools-use-modal-processed) .button, .page form:not(.ctools-use-modal-processed) .field-name-user-edit a, .field-name-user-edit .page form:not(.ctools-use-modal-processed) a {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 12px 25px 10px 12px;
  vertical-align: middle;
  border: 0;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: #78BDCA;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: all 0.25s ease;
  height: 40px; }
  .btn-blue:after, .panel-pane.pane-user-profile .field-name-user-edit a:after, .panel-row .panel-pane .field-name-field-link:after, .entity-paragraphs-item .button:after, .entity-paragraphs-item .field-name-user-edit a:after, .field-name-user-edit .entity-paragraphs-item a:after, .paragraphs-item-paragraph-button a:after, .paragraphs-item-paragraph-text-image .field-name-field-link a:after, .paragraphs-item-paragraph-manual-list .field-name-field-link:after, .node-composer.view-mode-square .composer-content .field-name-node-link:after, .button:after, .field-name-user-edit a:after, .page form:not(.ctools-use-modal-processed) .button:after, .page form:not(.ctools-use-modal-processed) .field-name-user-edit a:after, .field-name-user-edit .page form:not(.ctools-use-modal-processed) a:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #ffffff; }
  .btn-blue:hover, .panel-pane.pane-user-profile .field-name-user-edit a:hover, .panel-row .panel-pane .field-name-field-link:hover, .paragraphs-item-paragraph-button a:hover, .paragraphs-item-paragraph-text-image .field-name-field-link a:hover, .paragraphs-item-paragraph-manual-list .field-name-field-link:hover, .node-composer.view-mode-square .composer-content .field-name-node-link:hover, .button:hover, .field-name-user-edit a:hover, .page form:not(.ctools-use-modal-processed) .button:hover, .page form:not(.ctools-use-modal-processed) .field-name-user-edit a:hover, .field-name-user-edit .page form:not(.ctools-use-modal-processed) a:hover, .btn-blue:focus, .panel-pane.pane-user-profile .field-name-user-edit a:focus, .panel-row .panel-pane .field-name-field-link:focus, .paragraphs-item-paragraph-button a:focus, .paragraphs-item-paragraph-text-image .field-name-field-link a:focus, .paragraphs-item-paragraph-manual-list .field-name-field-link:focus, .node-composer.view-mode-square .composer-content .field-name-node-link:focus, .button:focus, .field-name-user-edit a:focus, .page form:not(.ctools-use-modal-processed) .button:focus, .page form:not(.ctools-use-modal-processed) .field-name-user-edit a:focus, .field-name-user-edit .page form:not(.ctools-use-modal-processed) a:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #92cad4; }
  .btn-blue:active, .panel-pane.pane-user-profile .field-name-user-edit a:active, .panel-row .panel-pane .field-name-field-link:active, .paragraphs-item-paragraph-button a:active, .paragraphs-item-paragraph-text-image .field-name-field-link a:active, .paragraphs-item-paragraph-manual-list .field-name-field-link:active, .node-composer.view-mode-square .composer-content .field-name-node-link:active, .button:active, .field-name-user-edit a:active, .page form:not(.ctools-use-modal-processed) .button:active, .page form:not(.ctools-use-modal-processed) .field-name-user-edit a:active, .field-name-user-edit .page form:not(.ctools-use-modal-processed) a:active, .btn-blue:focus, .panel-pane.pane-user-profile .field-name-user-edit a:focus, .panel-row .panel-pane .field-name-field-link:focus, .paragraphs-item-paragraph-button a:focus, .paragraphs-item-paragraph-text-image .field-name-field-link a:focus, .paragraphs-item-paragraph-manual-list .field-name-field-link:focus, .node-composer.view-mode-square .composer-content .field-name-node-link:focus, .button:focus, .field-name-user-edit a:focus, .page form:not(.ctools-use-modal-processed) .button:focus, .page form:not(.ctools-use-modal-processed) .field-name-user-edit a:focus, .field-name-user-edit .page form:not(.ctools-use-modal-processed) a:focus {
    outline: 0; }

.btn-white, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text, .pane-bundle-slogan .field-name-field-slogan-link a {
  position: relative;
  display: inline-block;
  margin: 0 0 15px 0;
  padding: 12px 25px 10px 12px;
  vertical-align: middle;
  border: 0;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: #185C71;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: #ffffff;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: all 0.25s ease;
  height: 40px; }
  .btn-white:after, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text:after, .pane-bundle-slogan .field-name-field-slogan-link a:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 12px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #185C71; }
  .btn-white:hover, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text:hover, .pane-bundle-slogan .field-name-field-slogan-link a:hover, .btn-white:focus, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text:focus, .pane-bundle-slogan .field-name-field-slogan-link a:focus {
    color: #DE4744;
    text-decoration: none;
    background-color: #ffffff; }
    .btn-white:hover:after, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text:hover:after, .pane-bundle-slogan .field-name-field-slogan-link a:hover:after, .btn-white:focus:after, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text:focus:after, .pane-bundle-slogan .field-name-field-slogan-link a:focus:after {
      border-left: 6px solid #DE4744; }
  .btn-white:active, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text:active, .pane-bundle-slogan .field-name-field-slogan-link a:active, .btn-white:focus, .panel-row.colored.darkblue .col:nth-child(2) .field-name-field-basic-text-text:focus, .pane-bundle-slogan .field-name-field-slogan-link a:focus {
    outline: 0; }

.btn-white--yellow-text, .panel-row .panel-pane.colored.yellow .field-name-field-link, .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link a,
.paragraphs-item-call-to-action .field-name-field-link a {
  position: relative;
  display: inline-block;
  margin: 0 15px 15px 0;
  padding: 12px 25px 10px 12px;
  vertical-align: middle;
  border: 0;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: #DAA32E;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: #ffffff;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: all 0.25s ease;
  height: 40px; }
  .btn-white--yellow-text:after, .panel-row .panel-pane.colored.yellow .field-name-field-link:after, .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link a:after,
  .paragraphs-item-call-to-action .field-name-field-link a:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 12px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #DAA32E; }
  .btn-white--yellow-text:hover, .panel-row .panel-pane.colored.yellow .field-name-field-link:hover, .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link a:hover,
  .paragraphs-item-call-to-action .field-name-field-link a:hover, .btn-white--yellow-text:focus, .panel-row .panel-pane.colored.yellow .field-name-field-link:focus, .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link a:focus,
  .paragraphs-item-call-to-action .field-name-field-link a:focus {
    color: #DE4744;
    text-decoration: none;
    background-color: #ffffff; }
    .btn-white--yellow-text:hover:after, .panel-row .panel-pane.colored.yellow .field-name-field-link:hover:after, .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link a:hover:after,
    .paragraphs-item-call-to-action .field-name-field-link a:hover:after, .btn-white--yellow-text:focus:after, .panel-row .panel-pane.colored.yellow .field-name-field-link:focus:after, .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link a:focus:after,
    .paragraphs-item-call-to-action .field-name-field-link a:focus:after {
      border-left: 6px solid #DE4744; }
  .btn-white--yellow-text:active, .panel-row .panel-pane.colored.yellow .field-name-field-link:active, .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link a:active,
  .paragraphs-item-call-to-action .field-name-field-link a:active, .btn-white--yellow-text:focus, .panel-row .panel-pane.colored.yellow .field-name-field-link:focus, .one-sidebar .main .paragraphs-item-call-to-action .field-name-field-link a:focus,
  .paragraphs-item-call-to-action .field-name-field-link a:focus {
    outline: 0; }

/* Button style - legacy */
.btn-blue--back, .node-library-score.view-mode-full .group-header .field-name-back a {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 12px 12px 10px 25px;
  vertical-align: middle;
  border: 0;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: #78BDCA;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: all 0.25s ease;
  height: 40px; }
  .btn-blue--back:after, .node-library-score.view-mode-full .group-header .field-name-back a:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid #ffffff; }
  .btn-blue--back:hover, .node-library-score.view-mode-full .group-header .field-name-back a:hover, .btn-blue--back:focus, .node-library-score.view-mode-full .group-header .field-name-back a:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #92cad4; }
  .btn-blue--back:active, .node-library-score.view-mode-full .group-header .field-name-back a:active, .btn-blue--back:focus, .node-library-score.view-mode-full .group-header .field-name-back a:focus {
    outline: 0; }

.user-login-form {
  max-width: 930px;
  margin: 60px auto; }
  .user-login-form input:-webkit-autofill,
  .user-login-form input:-webkit-autofill:hover,
  .user-login-form input:-webkit-autofill:focus,
  .user-login-form textarea:-webkit-autofill,
  .user-login-form textarea:-webkit-autofill:hover,
  .user-login-form textarea:-webkit-autofill:focus,
  .user-login-form select:-webkit-autofill,
  .user-login-form select:-webkit-autofill:hover,
  .user-login-form select:-webkit-autofill:focus {
    border: 2px solid #D6E7E9;
    -webkit-text-fill-color: #185C71;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    transition: background-color 5000s ease-in-out 0s; }

.page form:not(.ctools-use-modal-processed) {
  /*fieldset legend + .fieldset-wrapper {
    .form-item:only-child {
      margin: 0;
    }
  }*/ }
  .page form:not(.ctools-use-modal-processed) ::-webkit-input-placeholder {
    color: #111111; }
  .page form:not(.ctools-use-modal-processed) ::-moz-placeholder {
    color: #111111; }
  .page form:not(.ctools-use-modal-processed) :-ms-input-placeholder {
    color: #111111; }
  .page form:not(.ctools-use-modal-processed) :-moz-placeholder {
    color: #111111; }
  .page form:not(.ctools-use-modal-processed) ::placeholder {
    color: #111111; }
  .page form:not(.ctools-use-modal-processed) .form-item {
    margin: 10px 0; }
  .page form:not(.ctools-use-modal-processed) .fieldset-legend {
    font-size: 1.5rem; }
    .page form:not(.ctools-use-modal-processed) .fieldset-legend a {
      text-decoration: none; }
  .page form:not(.ctools-use-modal-processed) fieldset {
    border: none;
    padding: 0;
    margin-bottom: 1em; }
  .page form:not(.ctools-use-modal-processed) fieldset.collapsed {
    height: 2em; }
  .page form:not(.ctools-use-modal-processed) .fieldset-description p {
    margin-top: 0; }
  .page form:not(.ctools-use-modal-processed) label, .page form:not(.ctools-use-modal-processed) legend {
    position: relative;
    display: block;
    font-weight: 600;
    font-family: "brandon-grotesque", sans-serif;
    font-size: 1rem;
    color: #185C71; }
    .page form:not(.ctools-use-modal-processed) label.form-required:after, .page form:not(.ctools-use-modal-processed) legend.form-required:after {
      color: #111111;
      content: ' *';
      display: inline;
      font-weight: 400;
      font-size: 0.875rem; }
  .page form:not(.ctools-use-modal-processed) input, .page form:not(.ctools-use-modal-processed) textarea, .page form:not(.ctools-use-modal-processed) select {
    margin-top: 1px;
    padding: 10px 12px;
    background-color: #ffffff;
    border: 1px solid #D6E7E9;
    font-family: "brandon-grotesque", sans-serif;
    font-size: 0.9375rem;
    color: #185C71;
    border-radius: 0;
    display: block;
    box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.15);
    transition: all 0.25s ease; }
    .page form:not(.ctools-use-modal-processed) input:focus, .page form:not(.ctools-use-modal-processed) textarea:focus, .page form:not(.ctools-use-modal-processed) select:focus {
      outline: none;
      border-color: #78BDCA; }
    .page form:not(.ctools-use-modal-processed) input.invalid, .page form:not(.ctools-use-modal-processed) textarea.invalid, .page form:not(.ctools-use-modal-processed) select.invalid {
      border-color: red;
      animation: shake 1s linear; }
  .page form:not(.ctools-use-modal-processed) .form-text, .page form:not(.ctools-use-modal-processed) .form-textarea, .page form:not(.ctools-use-modal-processed) .form-email, .page form:not(.ctools-use-modal-processed) .form-select {
    width: 100%; }
  .page form:not(.ctools-use-modal-processed) .form-number {
    min-width: 150px; }
  .page form:not(.ctools-use-modal-processed) .form-type-select {
    position: relative; }
    .page form:not(.ctools-use-modal-processed) .form-type-select:after {
      content: "";
      position: absolute;
      right: 12px;
      bottom: 20px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #111111;
      pointer-events: none; }
  .page form:not(.ctools-use-modal-processed) .form-select, .page form:not(.ctools-use-modal-processed) .form-number {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer; }
  .page form:not(.ctools-use-modal-processed) .form-select.day,
  .page form:not(.ctools-use-modal-processed) .form-select.month,
  .page form:not(.ctools-use-modal-processed) .form-select.year {
    width: auto; }
    .page form:not(.ctools-use-modal-processed) .form-select.day::after,
    .page form:not(.ctools-use-modal-processed) .form-select.month::after,
    .page form:not(.ctools-use-modal-processed) .form-select.year::after {
      display: none; }
  .page form:not(.ctools-use-modal-processed) .webform-datepicker .form-type-select {
    display: inline-block; }
    .page form:not(.ctools-use-modal-processed) .webform-datepicker .form-type-select::after {
      display: none; }
  .page form:not(.ctools-use-modal-processed) .webform-datepicker .webform-calendar {
    display: inline-block;
    border: 0 none;
    box-shadow: none; }
  .page form:not(.ctools-use-modal-processed) .webform-address--wrapper .fieldset-wrapper {
    /* street */
    /* street number */
    /* city */
    /* zip */ }
    @media (min-width: 550px) {
      .page form:not(.ctools-use-modal-processed) .webform-address--wrapper .fieldset-wrapper {
        display: flex;
        flex-wrap: wrap; } }
    @media (min-width: 550px) {
      .page form:not(.ctools-use-modal-processed) .webform-address--wrapper .fieldset-wrapper .form-item-adres-address {
        flex: 1 0 70%;
        max-width: 70%;
        padding-right: 20px; } }
    @media (min-width: 550px) {
      .page form:not(.ctools-use-modal-processed) .webform-address--wrapper .fieldset-wrapper .form-item-adres-address-2 {
        flex: 1 0 30%;
        max-width: 30%; } }
    @media (min-width: 550px) {
      .page form:not(.ctools-use-modal-processed) .webform-address--wrapper .fieldset-wrapper .form-item-adres-city {
        flex: 1 0 70%;
        max-width: 70%;
        padding-right: 20px; } }
    @media (min-width: 550px) {
      .page form:not(.ctools-use-modal-processed) .webform-address--wrapper .fieldset-wrapper .form-item-adres-postal-code {
        flex: 1 0 30%;
        max-width: 30%; } }
  .page form:not(.ctools-use-modal-processed) .description {
    font-size: 0.8125rem;
    margin-top: 3px; }
  .page form:not(.ctools-use-modal-processed) input[type="number"] {
    -moz-appearance: textfield; }
  .page form:not(.ctools-use-modal-processed) input[type="number"]::-webkit-inner-spin-button,
  .page form:not(.ctools-use-modal-processed) input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .page form:not(.ctools-use-modal-processed) .js-hide {
    display: none; }

.page input[type=number]::-webkit-inner-spin-button, input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none; }

.form-actions {
  margin: 0;
  position: relative;
  text-align: right; }
  .form-actions:after {
    content: '';
    position: absolute;
    top: 25px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #ffffff; }
  .form-actions .button, .form-actions .field-name-user-edit a, .field-name-user-edit .form-actions a {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    padding: 12px 25px 10px 12px;
    vertical-align: middle;
    border: 0;
    font-family: "brandon-grotesque", sans-serif;
    font-size: 0.9375rem;
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: #78BDCA;
    border-radius: 0;
    -webkit-appearance: none;
    cursor: pointer;
    transition: all 0.25s ease;
    height: 40px; }
    .form-actions .button:hover, .form-actions .field-name-user-edit a:hover, .field-name-user-edit .form-actions a:hover, .form-actions .button:focus, .form-actions .field-name-user-edit a:focus, .field-name-user-edit .form-actions a:focus {
      color: #ffffff;
      text-decoration: none;
      background-color: #92cad4; }
    .form-actions .button:active, .form-actions .field-name-user-edit a:active, .field-name-user-edit .form-actions a:active, .form-actions .button:focus, .form-actions .field-name-user-edit a:focus, .field-name-user-edit .form-actions a:focus {
      outline: 0; }

.page form.search-form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) {
  max-width: 930px; }
  .page form.search-form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) input.button.form-submit {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    padding: 12px;
    vertical-align: middle;
    border: 0;
    font-family: "brandon-grotesque", sans-serif;
    font-size: 0.9375rem;
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: #78BDCA;
    border-radius: 0;
    -webkit-appearance: none;
    cursor: pointer;
    transition: all 0.25s ease;
    height: 40px;
    box-shadow: none; }
    .page form.search-form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) input.button.form-submit:after {
      content: '';
      position: absolute;
      top: 15px;
      right: 10px;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 6px solid #ffffff; }
    .page form.search-form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) input.button.form-submit:hover, .page form.search-form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) input.button.form-submit:focus {
      color: #ffffff;
      text-decoration: none;
      background-color: #92cad4; }
    .page form.search-form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) input.button.form-submit:active, .page form.search-form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) input.button.form-submit:focus {
      outline: 0; }

/* Chosen fields */
.chosen-container {
  width: 100% !important; }
  .chosen-container .chosen-single,
  .chosen-container .chosen-choices {
    border: 1px solid #D6E7E9;
    border-radius: 0;
    background: #fff;
    padding: 5px 15px;
    height: 48px;
    line-height: 40px;
    color: #185C71;
    font-size: 100%;
    box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.15); }
    .chosen-container .chosen-single div b,
    .chosen-container .chosen-choices div b {
      background: none !important; }
  .chosen-container .chosen-drop {
    border-color: #D6E7E9;
    box-shadow: none;
    border-radius: 0;
    font-size: 15px;
    line-height: 28px;
    background: #fff; }
  .chosen-container .chosen-results li {
    padding: 8px 6px; }
    .chosen-container .chosen-results li.highlighted {
      background: none;
      color: #185C71; }

.chosen-container-active.chosen-with-drop .chosen-single {
  background: #fff;
  border: 1px solid #D6E7E9; }

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -16px 15px; }

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  color: #185C71;
  font-size: 100%;
  font-family: "brandon-grotesque", sans-serif; }

.chosen-container .chosen-choices,
.chosen-container .chosen-single {
  height: 46px;
  box-shadow: none;
  border: 1px solid #D6E7E9;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  color: #185C71;
  padding: 3px 15px; }

.chosen-container-single .chosen-single {
  box-shadow: none;
  position: relative; }
  .chosen-container-single .chosen-single:after {
    content: "";
    position: absolute;
    right: 12px;
    bottom: 18px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #185c71;
    pointer-events: none; }

.chosen-container-active.chosen-with-drop .chosen-single {
  box-shadow: none;
  position: relative; }
  .chosen-container-active.chosen-with-drop .chosen-single:after {
    content: "";
    position: absolute;
    right: 12px;
    bottom: 18px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #185c71;
    pointer-events: none; }

.chosen-container-single .chosen-search input[type="text"] {
  border: 1px solid #D6E7E9;
  height: 40px;
  background: url("../images/icons/application/search--dark.svg") no-repeat 7px 50%;
  padding-left: 35px; }
  .chosen-container-single .chosen-search input[type="text"]:focus {
    outline: none;
    border-color: #78BDCA; }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi) {
  .chosen-container-single .chosen-search input[type="text"] {
    background-image: url("../images/icons/application/search--dark.svg") !important;
    background-position: 7px 50% !important;
    background-size: auto !important; } }

.chosen-container .chosen-results li.active-result {
  font-size: 0.9375rem; }
  .chosen-container .chosen-results li.active-result:hover {
    color: #DE4744; }

#autocomplete {
  background-color: #ffffff;
  border: 1px solid #D6E7E9; }
  #autocomplete ul:not([class]) {
    padding: 5px 0; }
    #autocomplete ul:not([class]) li {
      color: #185C71;
      padding: 1px 12px;
      cursor: pointer; }
      #autocomplete ul:not([class]) li:before {
        display: none; }
      #autocomplete ul:not([class]) li.selected {
        background-color: #185C71;
        color: #ffffff; }

/* hide arrow */
html.js fieldset.collapsible .fieldset-legend {
  background: none;
  padding-left: 0; }

form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-checkboxes .form-item {
  margin: 0; }

form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-item.form-type-select {
  margin: 0; }

form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-item.form-type-checkbox label {
  font-weight: 400; }

form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-checkbox label,
form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-bef-checkbox label {
  position: relative;
  padding: 2px 0 2px 26px;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  color: #185C71;
  font-weight: 500; }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-checkbox label:before,
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-bef-checkbox label:before {
    content: "";
    position: absolute;
    top: 3px;
    left: -3px;
    width: 22px;
    height: 22px;
    border: 1px solid #D6E7E9;
    background: #ffffff; }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-checkbox label:after,
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-bef-checkbox label:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 2px;
    left: -3px;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    transform: rotate(-45deg);
    transform-origin: 18px -1px; }

form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-checkbox input,
form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-bef-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
  display: inline; }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-checkbox input:checked + label:after,
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-type-bef-checkbox input:checked + label:after {
    width: 10px;
    height: 6px;
    border-color: #185C71;
    transition: height .08s ease-out, width .08s ease-out .1s; }

form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-item.form-type-radio {
  padding: 4px 0;
  margin: 0; }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-item.form-type-radio input[type=radio] {
    position: absolute;
    opacity: 0; }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-item.form-type-radio input[type=radio]:checked + label:before {
    background-color: #185C71;
    box-shadow: inset 0 0 0 3px #fff; }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-item.form-type-radio label {
    cursor: pointer;
    font-weight: 400; }
    form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-item.form-type-radio label:before {
      content: "";
      background: #ffffff;
      border-radius: 100%;
      border: 1px solid #c5dde0;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;
      top: 2px;
      margin-right: 2px;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all .25s ease; }

.form-type-webform-rating label {
  margin-bottom: 5px; }

/*
html.js .form-webform-rating {
  display: none;
}

div.svg div.rateit-range {
  background: url(../images/rating/star-normal.svg);
}

div.svg div.rateit-hover {
  background: url(../images/rating/star-hover.svg);
}

div.svg div.rateit-selected {
  background: url(../images/rating/star-selected.svg);
}

div.svg div.rateit-preset {
  background: url(../images/rating/star-preset.svg);
}

div.svg button.rateit-reset {
  background: url(../images/rating/reset-normal.svg);
}

div.svg button.rateit-reset:hover {
  background: url(../images/rating/reset-hover.svg);
}

div.svg.rateit-small {
  height: 16px;
}

div.svg.rateit-small div.rateit-range,
div.svg.rateit-small div.rateit-hover,
div.svg.rateit-small div.rateit-selected,
div.svg.rateit-small div.rateit-preset {
  background-size: 16px 16px;
}

div.svg.rateit-small button.rateit-reset {
  height: 16px;
  width: 16px;
  background-size: 16px 16px;
}

div.svg.rateit-medium {
  height: 24px;
}

div.svg.rateit-medium div.rateit-range,
div.svg.rateit-medium div.rateit-hover,
div.svg.rateit-medium div.rateit-selected,
div.svg.rateit-medium div.rateit-preset {
  background-size: 24px 24px;
}

div.svg.rateit-medium button.rateit-reset {
  height: 24px;
  width: 24px;
  background-size: 24px 24px;
}

div.svg.rateit-large {
  height: 36px;
}

div.svg.rateit-large div.rateit-range,
div.svg.rateit-large div.rateit-hover,
div.svg.rateit-large div.rateit-selected,
div.svg.rateit-large div.rateit-preset {
  background-size: 32px 32px;
}

div.svg.rateit-large button.rateit-reset {
  height: 32px;
  width: 32px;
  background-size: 32px 32px;
}*/
.rateit {
  display: inline-block;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none; }

.rateit .rateit-range {
  position: relative;
  display: inline-block;
  background: url("../images/rating/star.gif");
  height: 16px;
  outline: none; }

.rateit .rateit-range * {
  display: block; }

* html .rateit, * html .rateit .rateit-range {
  display: inline; }

* + html .rateit, * + html .rateit .rateit-range {
  display: inline; }

.rateit .rateit-hover, .rateit .rateit-selected {
  position: absolute;
  left: 0;
  top: 0;
  width: 0; }

.rateit .rateit-hover-rtl, .rateit .rateit-selected-rtl {
  left: auto;
  right: 0; }

.rateit .rateit-hover {
  background: url("../images/rating/star.gif") left -32px;
  color: #efc529; }

.rateit .rateit-hover-rtl {
  background-position: right -32px; }

.rateit .rateit-selected {
  background: url("../images/rating/star.gif") left -16px;
  color: #bf4242; }

.rateit .rateit-selected-rtl {
  background-position: right -16px; }

.rateit .rateit-preset {
  background: url("../images/rating/star.gif") left -48px;
  color: #ef6b22; }

.rateit .rateit-preset-rtl {
  background: url("../images/rating/star.gif") left -48px; }

.rateit button.rateit-reset {
  background: url("../images/rating/star.gif") 0 0;
  width: 16px;
  height: 16px;
  display: -moz-inline-box;
  display: inline-block;
  float: left;
  outline: none;
  border: none;
  padding: 0; }

.rateit .rateit-reset span {
  display: none; }

.rateit button.rateit-reset:hover, .rateit button.rateit-reset:focus {
  background-position: 0 -16px; }

.rateit-font {
  font-size: 24px;
  line-height: 1em; }

.rateit-font .rateit-range {
  background: none;
  height: auto; }

.rateit-font .rateit-empty {
  color: #ccc; }

.rateit-font .rateit-range > div {
  background: none;
  overflow: hidden;
  cursor: default; }

.rateit.rateit-font .rateit-reset {
  font-size: inherit;
  background: none;
  width: 0.6em;
  height: 0.6em;
  margin-top: 0.2em;
  background: gray;
  border-radius: 50%;
  position: relative; }

.rateit.rateit-font .rateit-reset span {
  display: block;
  font-weight: bold;
  color: white;
  height: calc(50% - 0.045em);
  top: 0;
  position: absolute;
  border-bottom: 0.09em solid white;
  width: 72%;
  margin-left: 14%; }

.rateit.rateit-font .rateit-reset:hover, .rateit.rateit-font button.rateit-reset:focus {
  background: #e6574b;
  /* Old browsers */
  background: radial-gradient(ellipse at center, #e6574b 55%, #f6836b 77%, #f9d3cc 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-position: 0 0; }

form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-managed-file .file {
  padding-right: 10px;
  display: inline-block; }

form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-managed-file .image-widget-data > .button, form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-managed-file .field-name-user-edit .image-widget-data > a, .field-name-user-edit form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-managed-file .image-widget-data > a {
  display: inline-block;
  border: none;
  padding: 0;
  border-radius: 0;
  width: 30px;
  height: 30px;
  margin: 0;
  background: transparent url("../images/layout-builder/delete.svg") no-repeat center center;
  text-indent: -9999px;
  font-size: 0;
  overflow: hidden;
  box-shadow: none; }
  form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-managed-file .image-widget-data > .button.js-hide, form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-managed-file .field-name-user-edit .image-widget-data > a.js-hide, .field-name-user-edit form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-managed-file .image-widget-data > a.js-hide {
    display: none; }

form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) .form-managed-file .image-widget-data > .form-type-textfield {
  display: none; }

.page-content > .pane-newsletter {
  margin-top: 30px; }
  @media (max-width: 1099px) {
    .page-content > .pane-newsletter {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (min-width: 550px) {
    .page-content > .pane-newsletter {
      max-width: 1340px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 1150px) {
    .page-content > .pane-newsletter {
      padding-left: 50px;
      padding-right: 50px; } }

.pane-bundle-newsletter .newsletter-wrapper,
.pane-newsletter .newsletter-wrapper {
  background: #E7F2F4 url("../images/icons/application/bg-shape--light.svg") no-repeat 90% 50%;
  background-size: 110% auto; }
  @media (max-width: 1149px) {
    .pane-bundle-newsletter .newsletter-wrapper,
    .pane-newsletter .newsletter-wrapper {
      padding: 25px; } }
  @media (min-width: 1100px) {
    .pane-bundle-newsletter .newsletter-wrapper,
    .pane-newsletter .newsletter-wrapper {
      padding: 50px; } }
  .pane-bundle-newsletter .newsletter-wrapper .pane-title,
  .pane-newsletter .newsletter-wrapper .pane-title {
    text-transform: none;
    font-weight: 600; }
    @media (max-width: 1149px) {
      .pane-bundle-newsletter .newsletter-wrapper .pane-title,
      .pane-newsletter .newsletter-wrapper .pane-title {
        font-size: 1.875rem; } }
    @media (min-width: 1100px) {
      .pane-bundle-newsletter .newsletter-wrapper .pane-title,
      .pane-newsletter .newsletter-wrapper .pane-title {
        font-size: 2.25rem; } }

.pane-bundle-newsletter .field-name-field-newsletter-benefits,
.pane-bundle-newsletter p,
.pane-newsletter .field-name-field-newsletter-benefits,
.pane-newsletter p {
  max-width: 780px; }

@media (min-width: 1100px) {
  .pane-bundle-newsletter .campaignmonitor-subscribe-form div:not([class]),
  .pane-newsletter .campaignmonitor-subscribe-form div:not([class]) {
    display: flex;
    flex-wrap: wrap; } }

.pane-bundle-newsletter .campaignmonitor-subscribe-form div:not([class]) .form-item,
.pane-newsletter .campaignmonitor-subscribe-form div:not([class]) .form-item {
  margin: 0 !important; }
  @media (min-width: 1100px) {
    .pane-bundle-newsletter .campaignmonitor-subscribe-form div:not([class]) .form-item,
    .pane-newsletter .campaignmonitor-subscribe-form div:not([class]) .form-item {
      flex: 1 0 50%;
      max-width: 50%; } }

.pane-bundle-newsletter .campaignmonitor-subscribe-form div:not([class]) input,
.pane-newsletter .campaignmonitor-subscribe-form div:not([class]) input {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  box-shadow: none !important; }

.pane-bundle-newsletter .campaignmonitor-subscribe-form div:not([class]) input.button.form-submit,
.pane-newsletter .campaignmonitor-subscribe-form div:not([class]) input.button.form-submit {
  padding: 12px !important;
  height: 44px !important; }
  @media (max-width: 1149px) {
    .pane-bundle-newsletter .campaignmonitor-subscribe-form div:not([class]) input.button.form-submit,
    .pane-newsletter .campaignmonitor-subscribe-form div:not([class]) input.button.form-submit {
      margin-top: 10px !important; } }
  @media (min-width: 1100px) {
    .pane-bundle-newsletter .campaignmonitor-subscribe-form div:not([class]) input.button.form-submit,
    .pane-newsletter .campaignmonitor-subscribe-form div:not([class]) input.button.form-submit {
      margin-left: 15px; } }

.pane-bundle-newsletter .campaignmonitor-subscribe-form div:not([class]) label,
.pane-newsletter .campaignmonitor-subscribe-form div:not([class]) label {
  display: none !important; }

::-ms-clear {
  display: none; }

/* Or */
input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

/* Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

/* or  */
::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none; }

.footer-top {
  background-color: #185C71;
  color: #ffffff; }
  @media (max-width: 1149px) {
    .footer-top {
      padding: 40px 0; } }
  @media (min-width: 1100px) {
    .footer-top {
      padding: 60px 0; } }
  .footer-top h3 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 900;
    margin-top: 0; }
  .footer-top .col-md-3:nth-child(1) a, .footer-top .col-md-3:nth-child(2) a {
    color: #ffffff;
    text-decoration: none;
    text-shadow: 0.03em 0 #185C71, -0.03em 0 #185C71, 0 0.03em #185C71, 0 -0.03em #185C71, 0.06em 0 #185C71, -0.06em 0 #185C71, 0.09em 0 #185C71, -0.09em 0 #185C71, 0.12em 0 #185C71, -0.12em 0 #185C71, 0.15em 0 #185C71, -0.15em 0 #185C71;
    background: linear-gradient(#185C71, #185C71), linear-gradient(#185C71, #185C71), linear-gradient(#749DA9, #749DA9);
    background-size: .05em 1px,.05em 1px,1px 1px;
    background-repeat: no-repeat,no-repeat,repeat-x;
    background-position: 0 95%,100% 95%,0 95%; }
    .footer-top .col-md-3:nth-child(1) a:hover, .footer-top .col-md-3:nth-child(1) a:focus, .footer-top .col-md-3:nth-child(1) a:active, .footer-top .col-md-3:nth-child(2) a:hover, .footer-top .col-md-3:nth-child(2) a:focus, .footer-top .col-md-3:nth-child(2) a:active {
      background: linear-gradient(#185C71, #185C71), linear-gradient(#185C71, #185C71), linear-gradient(#ffffff, #ffffff);
      background-size: .05em 1px,.05em 1px,1px 1px;
      background-repeat: no-repeat,no-repeat,repeat-x;
      background-position: 0 95%,100% 95%,0 95%; }
  @media (min-width: 1100px) {
    .footer-top .col-md-3:nth-child(4) {
      text-align: right; } }
  @media (max-width: 1149px) {
    .footer-top .col:not(:last-child) {
      margin-bottom: 30px; } }
  .footer-top .koorenstem-logo {
    background: url("../images/logo/koorenstem--white.svg") no-repeat 100% 50%;
    width: 181px;
    height: 74px;
    text-indent: -999px;
    font-size: 0; }
  .footer-top p:last-child {
    margin-bottom: 0; }

.footer-bottom {
  padding: 15px 0;
  font-weight: 500; }
  @media (min-width: 1100px) {
    .footer-bottom .container {
      display: flex;
      flex-wrap: wrap; } }
  .footer-bottom .menu {
    list-style-type: none;
    padding: 0; }
    @media (max-width: 1149px) {
      .footer-bottom .menu {
        margin-to: 30px; } }
    @media (min-width: 1100px) {
      .footer-bottom .menu {
        margin: 0 0 0 auto; } }
    @media (min-width: 1100px) {
      .footer-bottom .menu li {
        display: inline-block;
        padding-left: 15px; } }
    .footer-bottom .menu li a {
      text-decoration: none; }
      .footer-bottom .menu li a:hover, .footer-bottom .menu li a:focus, .footer-bottom .menu li a:active {
        color: #DE4744; }

.main-footer {
  background-color: #7A959D;
  background-position: top center;
  background-size: 100%;
  position: relative; }
  @media (max-width: 1149px) {
    .main-footer {
      padding: 30px 0;
      margin-top: 30px; } }
  @media (min-width: 1100px) {
    .main-footer {
      padding: 60px 0;
      margin-top: 80px; } }
  .main-footer ul, .main-footer ul:not([class]) {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .main-footer ul li:before, .main-footer ul:not([class]) li:before {
      display: none; }

.block-ek-footer-block {
  max-width: 1340px;
  margin: 0 auto;
  min-height: 300px; }
  @media (max-width: 1099px) {
    .block-ek-footer-block {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (min-width: 550px) {
    .block-ek-footer-block {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 1150px) {
    .block-ek-footer-block {
      padding-left: 50px;
      padding-right: 50px; } }

@media (max-width: 1099px) {
  .block-ek-footer-block--logo img {
    width: 133px;
    height: 34px; } }

@media (min-width: 1150px) {
  .block-ek-footer-block--logo img {
    width: 193px;
    height: 50px; } }

.block-ek-footer-block--slogan-social {
  padding: 30px 0; }

@media (min-width: 1100px) and (max-width: 1149px) {
  .block-ek-footer-block--slogan-social {
    padding: 30px 0; } }
  @media (min-width: 1100px) {
    .block-ek-footer-block--slogan-social {
      padding: 30px 0;
      display: flex;
      align-items: top;
      justify-content: space-between; } }

.block-ek-footer-block--slogan {
  font-weight: bold;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.6875rem;
  line-height: 27px;
  color: #111111; }

.block-ek-footer-block--nav {
  font-size: 0.9375rem; }
  @media (min-width: 1100px) {
    .block-ek-footer-block--nav {
      display: flex;
      flex-wrap: wrap; } }
  .block-ek-footer-block--nav a {
    color: rgba(17, 17, 17, 0.8);
    text-decoration: none;
    line-height: 36px; }
    .block-ek-footer-block--nav a:hover {
      color: #185C71; }
  @media (min-width: 1100px) {
    .block-ek-footer-block--nav .item-list {
      flex: 1 0 25%;
      max-width: 25%;
      padding-right: 15px; } }

.subfooter {
  max-width: 1340px;
  margin: 0 auto;
  font-size: 0.9375rem; }
  @media (max-width: 1099px) {
    .subfooter {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (min-width: 550px) {
    .subfooter {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 1150px) {
    .subfooter {
      padding-left: 50px;
      padding-right: 50px; } }

.main-header {
  background-color: #265C6D;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  /*background-image: url('../images/header/koorklank-header.jpg') no-repeat 100% 50%;*/ }
  .main-header:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #265C6D;
    opacity: .7;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .main-header:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #265C6D;
    opacity: .6;
    width: 100%;
    height: 100%;
    z-index: 0;
    mix-blend-mode: color; }
  .main-header ul, .main-header ul:not([class]) {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .main-header ul li:before, .main-header ul:not([class]) li:before {
      display: none; }
  .main-header a {
    text-decoration: none; }
  .main-header .header {
    z-index: 1;
    position: relative; }
    @media (max-width: 1099px) {
      .main-header .header {
        padding: 15px 0; } }
    @media (min-width: 550px) {
      .main-header .header {
        padding: 20px 0; } }
    @media (min-width: 1150px) {
      .main-header .header {
        padding: 23px 0 20px 0; } }
    .main-header .header .container {
      display: flex;
      flex-wrap: wrap; }
      @media (min-width: 1150px) {
        .main-header .header .container {
          align-items: center; } }
    .main-header .header .pane-pane-header {
      flex: 1 0 30%;
      max-width: 30%; }
    .main-header .header .pane-main-menu,
    .main-header .header .pane-menu-menu-main-menu-en {
      flex: 1 0 calc(70% - 50px);
      max-width: calc(70% - 50px);
      text-align: right; }
      @media (max-width: 1149px) {
        .main-header .header .pane-main-menu,
        .main-header .header .pane-menu-menu-main-menu-en {
          display: none; } }
    .main-header .header .pane-pane-search {
      position: relative; }
      @media (max-width: 1149px) {
        .main-header .header .pane-pane-search {
          margin-left: auto; } }
      @media (min-width: 1150px) {
        .main-header .header .pane-pane-search {
          flex: 1 0 50px;
          max-width: 50px; } }
      .main-header .header .pane-pane-search.open #search-block-form {
        visibility: visible;
        opacity: 1; }
      .main-header .header .pane-pane-search .search-toggle {
        display: block;
        background: url("../images/icons/application/search--white.svg") no-repeat 100% 50%;
        width: 40px;
        height: 50px;
        font-size: 0;
        text-indent: -9999px;
        overflow: hidden; }
        @media (max-width: 1899px) {
          .main-header .header .pane-pane-search .search-toggle {
            margin-right: 40px; } }
      .main-header .header .pane-pane-search #search-block-form {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 50px;
        right: 0;
        width: 250px;
        border: 5px solid #78BDCA;
        z-index: 400; }
        .main-header .header .pane-pane-search #search-block-form .form-text {
          border: none;
          margin: 0; }
        .main-header .header .pane-pane-search #search-block-form .form-actions {
          position: absolute;
          top: 0;
          right: 0; }
          .main-header .header .pane-pane-search #search-block-form .form-actions:after {
            display: none; }
          .main-header .header .pane-pane-search #search-block-form .form-actions input.button {
            margin: 0;
            background: transparent url("../images/icons/application/arrow--right--dark.svg") no-repeat 60% 50%;
            width: 40px;
            height: 40px;
            text-indent: -9999px; }
            .main-header .header .pane-pane-search #search-block-form .form-actions input.button:after {
              display: none; }
  .main-header .hero-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .main-header .header-intro {
    z-index: 1;
    position: relative;
    color: #ffffff; }
    @media (max-width: 1149px) {
      .main-header .header-intro {
        padding: 40px 0 30px 0; } }
    @media (min-width: 1100px) {
      .main-header .header-intro {
        padding: 80px 0 60px 0; } }
    .main-header .header-intro h1, .main-header .header-intro h2, .main-header .header-intro h3, .main-header .header-intro h4 {
      margin-top: 0;
      color: #ffffff; }
      @media (max-width: 1149px) {
        .main-header .header-intro h1, .main-header .header-intro h2, .main-header .header-intro h3, .main-header .header-intro h4 {
          font-size: 1.875rem; } }
      @media (min-width: 1100px) {
        .main-header .header-intro h1, .main-header .header-intro h2, .main-header .header-intro h3, .main-header .header-intro h4 {
          font-size: 2.625rem; } }
    .main-header .header-intro p {
      margin-top: 0; }
      @media (max-width: 1149px) {
        .main-header .header-intro p {
          font-size: 1.1875rem; } }
      @media (min-width: 1100px) {
        .main-header .header-intro p {
          font-size: 1.375rem;
          line-height: 30px; } }
      .main-header .header-intro p a {
        color: #ffffff;
        text-decoration: underline; }
  .main-header .header-intro-width {
    z-index: 1;
    position: relative;
    max-width: 620px; }

.site-logo {
  display: block;
  position: relative;
  z-index: 403; }
  .site-logo img {
    transition: all 0.25s ease;
    vertical-align: top; }
  @media (max-width: 1099px) {
    .site-logo img {
      width: 77px;
      height: 50px; } }
  @media (min-width: 550px) {
    .site-logo img {
      width: 123px;
      height: 80px; } }

.name-and-slogan {
  display: none; }

.top-bar {
  z-index: 2;
  position: relative;
  background-color: rgba(14, 63, 79, 0.8);
  color: #C0D3D9;
  font-weight: 500; }
  @media (max-width: 1149px) {
    .top-bar {
      display: none; } }
  .top-bar a {
    color: #C0D3D9;
    font-weight: 500; }
    .top-bar a:hover {
      color: #ffffff; }
  .top-bar .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  @media (min-width: 1100px) {
    .top-bar .menu {
      flex-grow: 1;
      text-align: right; }
      .top-bar .menu li {
        display: inline-block;
        margin-right: 10px; } }
  @media (min-width: 1100px) {
    .top-bar .user a {
      margin-right: 10px; }
      .top-bar .user a:last-child {
        margin-right: 0; } }

.pane-main-menu,
.pane-menu-menu-main-menu-en {
  transition: all 0.25s ease;
  position: relative; }
  .pane-main-menu .pane-title,
  .pane-menu-menu-main-menu-en .pane-title {
    display: none; }
  .pane-main-menu ul.menu,
  .pane-menu-menu-main-menu-en ul.menu {
    position: relative; }
    .pane-main-menu ul.menu li,
    .pane-menu-menu-main-menu-en ul.menu li {
      margin: 0;
      padding: 0;
      display: block;
      text-align: left; }
      @media (min-width: 1150px) {
        .pane-main-menu ul.menu li,
        .pane-menu-menu-main-menu-en ul.menu li {
          display: inline-block; }
          .pane-main-menu ul.menu li:nth-child(1),
          .pane-menu-menu-main-menu-en ul.menu li:nth-child(1) {
            display: none; } }
    .pane-main-menu ul.menu a,
    .pane-menu-menu-main-menu-en ul.menu a {
      display: inline-block;
      font-weight: 600;
      font-family: "brandon-grotesque", sans-serif;
      text-transform: uppercase;
      font-size: 1.0625rem;
      color: #ffffff;
      margin: 0 7px;
      padding: 5px 0;
      text-decoration: none;
      line-height: 12px;
      position: relative;
      border-bottom: 1px solid transparent; }
      .pane-main-menu ul.menu a:hover, .pane-main-menu ul.menu a:focus, .pane-main-menu ul.menu a:active,
      .pane-menu-menu-main-menu-en ul.menu a:hover,
      .pane-menu-menu-main-menu-en ul.menu a:focus,
      .pane-menu-menu-main-menu-en ul.menu a:active {
        color: #ffffff; }
        .pane-main-menu ul.menu a:hover:after, .pane-main-menu ul.menu a:focus:after, .pane-main-menu ul.menu a:active:after,
        .pane-menu-menu-main-menu-en ul.menu a:hover:after,
        .pane-menu-menu-main-menu-en ul.menu a:focus:after,
        .pane-menu-menu-main-menu-en ul.menu a:active:after {
          content: '';
          position: absolute;
          background: url("../images/icons/application/nav-active.svg") no-repeat 100% 50%;
          width: 12px;
          height: 4px;
          left: 0;
          bottom: -5px; }
      .pane-main-menu ul.menu a.active,
      .pane-menu-menu-main-menu-en ul.menu a.active {
        color: #ffffff; }
        .pane-main-menu ul.menu a.active:after,
        .pane-menu-menu-main-menu-en ul.menu a.active:after {
          content: '';
          position: absolute;
          background: url("../images/icons/application/nav-active.svg") no-repeat 100% 50%;
          width: 12px;
          height: 4px;
          left: 0;
          bottom: -5px; }
      .pane-main-menu ul.menu a:last-of-type,
      .pane-menu-menu-main-menu-en ul.menu a:last-of-type {
        padding-right: 0; }

@media (max-width: 1899px) {
  .pane-mobile-menu .nav-toggle--menu, .pane-mobile-menu .nav-toggle {
    display: block; } }

@media (min-width: 1150px) {
  .pane-mobile-menu {
    display: none; } }

.nav-toggle--menu {
  display: none; }

.nav-toggle {
  display: block;
  padding: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  overflow: hidden;
  margin: 0;
  position: absolute;
  background-color: transparent;
  border: none;
  color: #ffffff;
  border-radius: none;
  outline: none;
  z-index: 20; }
  @media (max-width: 1099px) {
    .nav-toggle {
      top: 11px;
      right: 5px; } }
  @media (min-width: 550px) {
    .nav-toggle {
      top: 16px;
      right: 5px; } }
  @media (min-width: 1150px) {
    .nav-toggle {
      display: none; } }
  .nav-toggle span {
    background: #ffffff;
    display: block;
    width: 20px;
    height: 2px;
    right: 17px;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    border-radius: 3px; }
    .nav-toggle span:first-child {
      top: 20px; }
    .nav-toggle span:nth-child(2), .nav-toggle span:nth-child(3) {
      top: 27px; }
    .nav-toggle span:nth-child(4) {
      top: 34px; }
  .nav-toggle.open span:nth-child(1), .nav-toggle.open span:nth-child(2), .nav-toggle.open span:nth-child(3), .nav-toggle.open span:nth-child(4) {
    top: 27px; }

@media (max-width: 1149px) {
  .menu-wrapper {
    opacity: 0;
    visibility: hidden;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow: scroll;
    right: 0;
    background: #185C71;
    z-index: 18;
    padding: 20px 0; } }

@media (min-width: 550px) and (max-width: 1099px) {
  .menu-wrapper {
    height: 100vh;
    top: 0; } }

@media (min-width: 1150px) {
  .menu-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    visibility: visible;
    opacity: 1; } }

.menu-wrapper.open {
  visibility: visible;
  opacity: 1;
  top: 0; }

.menu-wrapper .main-menu,
.menu-wrapper .top-menu,
.menu-wrapper .language-switcher-locale-url,
.menu-wrapper .user {
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: #ffffff; }
  .menu-wrapper .main-menu .links li,
  .menu-wrapper .top-menu .links li,
  .menu-wrapper .language-switcher-locale-url .links li,
  .menu-wrapper .user .links li {
    list-style-type: none; }
    .menu-wrapper .main-menu .links li:before,
    .menu-wrapper .top-menu .links li:before,
    .menu-wrapper .language-switcher-locale-url .links li:before,
    .menu-wrapper .user .links li:before {
      display: none; }
  .menu-wrapper .main-menu li,
  .menu-wrapper .top-menu li,
  .menu-wrapper .language-switcher-locale-url li,
  .menu-wrapper .user li {
    list-style-type: none; }
    .menu-wrapper .main-menu li:before,
    .menu-wrapper .top-menu li:before,
    .menu-wrapper .language-switcher-locale-url li:before,
    .menu-wrapper .user li:before {
      display: none; }
  .menu-wrapper .main-menu a,
  .menu-wrapper .top-menu a,
  .menu-wrapper .language-switcher-locale-url a,
  .menu-wrapper .user a {
    color: #ffffff;
    text-decoration: none;
    padding: 3px 0;
    display: block; }

.menu-wrapper .user {
  padding: 0 0 20px 20px; }

.menu-wrapper .language-switcher-locale-url {
  padding: 0 0 0 20px; }

.main-language {
  position: relative;
  display: block; }
  .main-language:hover .language-switcher-locale-url {
    display: block; }
  .main-language .current-language {
    cursor: pointer;
    padding: 12px 0; }
  .main-language .language-switcher-locale-url {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    text-align: left;
    background-color: rgba(14, 63, 79, 0.8);
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.1);
    padding: 10px 15px; }

.locale-untranslated {
  text-decoration: none;
  cursor: hover; }

@media (max-width: 1099px) {
  .container, .content {
    padding-left: 20px;
    padding-right: 20px; } }

@media (min-width: 550px) {
  .container, .content {
    max-width: 1340px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px; } }

@media (min-width: 1150px) {
  .container, .content {
    padding-left: 50px;
    padding-right: 50px; } }

@media (max-width: 1099px) {
  .main,
  .one-sidebar {
    padding-left: 20px;
    padding-right: 20px; } }

@media (min-width: 550px) {
  .main,
  .one-sidebar {
    max-width: 1340px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px; } }

@media (min-width: 1150px) {
  .main,
  .one-sidebar {
    padding-left: 50px;
    padding-right: 50px; } }

.one-sidebar .main {
  padding-left: 0;
  padding-right: 0; }
  .one-sidebar .main h2 {
    color: #185C71;
    font-size: 1.875rem; }
  .one-sidebar .main h3 {
    color: #78BDCA; }

/*.inview {
  transform: translateY(40px);
  animation: inview-animation 1.2s ease forwards;
}

@keyframes inview-animation {
  to { transform: translateY(0); }
}*/
.animation-slide-up {
  animation: slide-up ease forwards;
  animation-duration: .7s;
  opacity: 0;
  transform: translateY(12px); }

.animation-button-enlarge {
  animation: button-enlarge ease forwards;
  animation-duration: 1.3s; }

.animation-slide-up-blocks, .block-inline-blocktestimonial.inview-block-active {
  /*  animation: slide-up ease forwards;
  animation-duration: 1.3s;
  opacity: .9;
  transform: translateY(25px);*/ }

@keyframes slide-up {
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes button-enlarge {
  50% {
    padding: 17px 30px; }
  75% {
    padding: 17px 44px; }
  100% {
    padding: 17px 30px; } }

.breadcrumbs {
  max-width: 930px;
  margin: 20px auto 10px auto; }

.breadcrumbs__item {
  position: relative;
  padding-right: 20px;
  display: inline-block; }
  .breadcrumbs__item:not(:last-child):after {
    content: '';
    position: absolute;
    background: url("../images/icons/application/arrow--right.svg") no-repeat 100% 50%;
    width: 8px;
    height: 14px;
    right: 5px;
    top: 7px; }

.breadcrumbs__item--link {
  font-weight: bold;
  font-size: 0.9375rem;
  color: #111111;
  text-decoration: none;
  white-space: nowrap; }

a.breadcrumbs__item--link:hover, a.breadcrumbs__item--link:focus {
  color: #185C71; }

#sliding-popup {
  background: #86cdda;
  color: #185C71;
  width: 100%;
  padding: 20px 0;
  position: fixed;
  z-index: 99999;
  font-size: 15px; }
  #sliding-popup label {
    color: #185C71; }

.eu-cookie-compliance-content,
.eu-cookie-compliance-banner {
  clearfix: both;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px; }
  @media (min-width: 550px) {
    .eu-cookie-compliance-content,
    .eu-cookie-compliance-banner {
      padding: 0 20px; } }
  @media (min-width: 1100px) {
    .eu-cookie-compliance-content,
    .eu-cookie-compliance-banner {
      padding: 0 30px; } }
  @media (min-width: 1150px) {
    .eu-cookie-compliance-content,
    .eu-cookie-compliance-banner {
      max-width: 1800px;
      padding: 0 40px; } }
  .eu-cookie-compliance-content #popup-text,
  .eu-cookie-compliance-banner #popup-text {
    color: #185C71; }
    .eu-cookie-compliance-content #popup-text a, .eu-cookie-compliance-content #popup-text p,
    .eu-cookie-compliance-banner #popup-text a,
    .eu-cookie-compliance-banner #popup-text p {
      color: #185C71;
      margin: 0; }
    .eu-cookie-compliance-content #popup-text p,
    .eu-cookie-compliance-banner #popup-text p {
      display: inline-block; }

.eu-cookie-compliance-message {
  margin-bottom: 10px; }
  @media (min-width: 1100px) {
    .eu-cookie-compliance-message {
      display: flex;
      align-items: center; } }
  .eu-cookie-compliance-message p {
    margin: 0;
    display: inline-block; }

.eu-cookie-compliance-more-button {
  background: transparent;
  border: none;
  border-radius: none;
  text-decoration: underline;
  padding: 0;
  color: #185C71;
  font-size: 0.9375rem; }
  @media (min-width: 1100px) {
    .eu-cookie-compliance-more-button {
      margin-left: 3px; } }

.eu-cookie-compliance-save-preferences-button {
  background: transparent;
  border: none;
  border-radius: none;
  text-decoration: underline;
  padding: 0;
  color: #185C71;
  font-size: 0.9375rem;
  margin-bottom: 10px; }

.agree-button.eu-cookie-compliance-default-button {
  background: #185C71;
  border: none;
  padding: 5px 12px;
  color: #ffffff;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0; }
  @media (min-width: 550px) {
    .agree-button.eu-cookie-compliance-default-button {
      width: auto;
      margin: 0 10px 0 0; } }

.eu-cookie-withdraw-button {
  background: #ffffff;
  color: #185C71;
  border: none;
  padding: 5px 12px;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0; }
  @media (min-width: 550px) {
    .eu-cookie-withdraw-button {
      width: auto;
      margin: 0; } }

.eu-cookie-compliance-category {
  margin-bottom: 10px; }
  .eu-cookie-compliance-category label {
    position: relative;
    padding: 2px 0 2px 23px;
    display: inline-block;
    cursor: pointer;
    margin: 0;
    line-height: 21px;
    font-weight: 600; }
    .eu-cookie-compliance-category label:before {
      content: "";
      position: absolute;
      top: 3px;
      left: -3px;
      width: 20px;
      height: 20px;
      border: 1px solid #185C71;
      background: transparent; }
    .eu-cookie-compliance-category label:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 1px;
      left: -4px;
      border-bottom: 2px solid transparent;
      border-left: 2px solid transparent;
      transform: rotate(-45deg);
      transform-origin: 18px -1px; }
  .eu-cookie-compliance-category input {
    opacity: 0;
    padding: 0;
    min-height: 0;
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    border: 0; }
    .eu-cookie-compliance-category input:checked + label:after {
      width: 10px;
      height: 6px;
      border-color: #185C71;
      transition: height .08s ease-out, width .08s ease-out .1s; }

.eu-cookie-compliance-category-description {
  font-size: 14px;
  line-height: 20px;
  padding-left: 23px; }

dialog {
  z-index: 300;
  display: block;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  padding: 1em;
  color: inherit;
  border: 0; }

dialog:not([open]) {
  display: none; }

dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

dialog + .backdrop {
  background: rgba(17, 17, 17, 0.8); }

::backdrop {
  background: rgba(17, 17, 17, 0.8);
  animation: .4s ease fade; }

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: .4s ease-in-out;
  width: 100%;
  height: 100%;
  padding: 0.9375rem 1.875rem;
  background: #ffffff; }
  .dialog.-media {
    width: 80vw;
    max-width: none;
    padding: 0;
    background: #111111; }
    .dialog.-media::before {
      content: "loading...";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    @media (min-width: 1150px) {
      .dialog.-media {
        padding: 0; } }

.dialog[open] {
  animation: .6s ease dialog-slide-in; }

.dialog-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3.125rem 1.5625rem; }
  @media (min-width: 1100px) {
    .dialog-content {
      padding: 3.125rem; } }

.dialog__close-btn {
  position: absolute;
  top: 1.875rem;
  right: 3.125rem;
  width: 21px;
  height: 53px;
  padding: 0;
  color: #111111;
  background: transparent;
  border: 0;
  outline: 0; }
  .dialog__close-btn span {
    background-color: #111111;
    display: block;
    position: absolute;
    height: 0.125rem;
    width: 1.1875rem;
    top: 29px; }
  .dialog__close-btn span:nth-child(1) {
    transform: rotate(45deg); }
  .dialog__close-btn span:nth-child(2) {
    transform: rotate(-45deg); }
  .dialog__close-btn:hover span, .dialog__close-btn:focus span {
    background-color: #185C71; }

.dialog__media {
  margin: 0;
  padding: 0; }
  .dialog__media.-iframe-video {
    position: relative;
    padding-top: 56.26%;
    overflow: hidden; }

.dialog__video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

@keyframes dialog-slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden; }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0); } }

@keyframes dialog-slide {
  from {
    transform: translate3d(0, -48%, 0); }
  to {
    transform: translate3d(0, -50%, 0); } }

@keyframes dialog-fade {
  from {
    opacity: 0.3; }
  to {
    opacity: 1; } }

.download-list {
  margin: 0;
  padding: 0; }
  .download-list li {
    border-bottom: 1px solid #D6E7E9; }

.file {
  display: block; }
  .file a {
    display: block;
    padding: 7px 0 8px 0;
    min-height: 16px;
    padding-left: 37px;
    position: relative;
    transition: none; }
    .file a:before {
      content: '';
      position: absolute;
      top: 13px;
      left: 0;
      width: 30px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: 0; }
    .file a:hover {
      color: #2385a3; }
  .file.file--mime-application-pdf a:before {
    background-image: url("../images/file/file-pdf.svg"); }
  .file.file--mime-audio-mpeg a:before {
    background-image: url("../images/file/file-mp3.svg"); }
  .file.file--mime-application-zip a:before {
    background-image: url("../images/file/file-zip.svg"); }
  .file.file--mime-text-plain a:before {
    background-image: url("../images/file/file-txt.svg"); }
  .file.file--x-office-document a:before {
    background-image: url("../images/file/file-doc.svg"); }
  .file.file--mime-application-octet-stream a:before {
    background-image: url("../images/file/file-mscz.svg"); }

/*.inview {
  transform: translateY(40px);
  animation: inview-animation 1.2s ease forwards;
}

@keyframes inview-animation {
  to { transform: translateY(0); }
}*/
.animation-slide-up {
  animation: slide-up ease forwards;
  animation-duration: .7s;
  opacity: 0;
  transform: translateY(12px); }

.animation-button-enlarge {
  animation: button-enlarge ease forwards;
  animation-duration: 1.3s; }

.animation-slide-up-blocks, .block-inline-blocktestimonial.inview-block-active {
  /*  animation: slide-up ease forwards;
  animation-duration: 1.3s;
  opacity: .9;
  transform: translateY(25px);*/ }

@keyframes slide-up {
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes button-enlarge {
  50% {
    padding: 17px 30px; }
  75% {
    padding: 17px 44px; }
  100% {
    padding: 17px 30px; } }

/*.embedded-entity {
  &.align-right {
    float: none;
    img {
      @include media-breakpoint-up(sm)  {
        float: right;
        padding: 0 0 rem-calc(20) rem-calc(20);
      }
    }
    figcaption {
      @include media-breakpoint-up(sm)  {
        float: right;
        clear: right;
      }
    }
  }
  &.align-left {
    float: none;
    img {
      @include media-breakpoint-up(sm)  {
        float: left;
        padding: 0 rem-calc(20) rem-calc(20) 0;
      }
    }
  }
  &.align-center {
    text-align: center;
    img {
      display: inline-block;
      margin: 0 0 rem-calc(15);
    }
  }
  &.caption {
    display: block;
  }
  img.image-style-one-third-width {
    @include media-breakpoint-up(sm)  {
      width: 33%;
    }
  }
  img.image-style-half-width {
    @include media-breakpoint-up(sm)  {
      width: 50%;
    }
  }
  img.image-style-full-width {
    margin-bottom: rem-calc(15);
    width: 100%;
  }
  figcaption {
    display: block;
    margin-top: -rem-calc(20);
    font-size: rem-calc(11);
    font-weight: 400;
    font-style: italic;
    color: lighten($black, 20%);
  }
}*/
.file img.file-icon {
  width: 15px;
  vertical-align: middle; }

/* Media */
.field-name-field-image-caption,
.media-element-container,
.field-name-field-caption {
  color: #999;
  font-size: 0.9em;
  line-height: 1.3em;
  font-weight: normal; }

.field-name-field-caption {
  padding-top: 10px; }

.media-element-container {
  max-width: 100%; }

/* Half width image style */
.media-half_width {
  height: auto; }
  @media (min-width: 550px) {
    .media-half_width {
      max-width: 50%;
      width: 50%; } }
  .media-half_width img.panopoly-image-half,
  .media-half_width img.half-width,
  .media-half_width img.file-half-width {
    width: auto;
    max-width: 100%;
    margin: 0;
    float: none; }

.cke_editable img.file-half-width,
.mceContentBody img.file-half-width {
  max-width: 50%;
  width: 50%; }

/* Full width image style */
.media-full_width {
  height: auto; }
  @media (min-width: 550px) {
    .media-full_width {
      max-width: 95%;
      width: 95%; } }
  .media-full_width img.panopoly-image-full,
  .media-full_width img.full-width,
  .media-full_width img.file-full-width {
    width: 100%;
    max-width: 100%;
    margin: 0;
    float: none; }

.cke_editable img.file-full-width,
.mceContentBody img.file-full-width {
  max-width: 90%;
  width: 90%; }

/* Quarter width image style */
.media-quarter_width {
  height: auto; }
  @media (min-width: 550px) {
    .media-quarter_width {
      max-width: 25%;
      width: 25%; } }
  .media-quarter_width img.panopoly-image-quarter,
  .media-quarter_width img.quarter-width,
  .media-quarter_width img.file-quarter-width {
    width: auto;
    max-width: 100%;
    margin: 0;
    float: none; }

.cke_editable img.file-quarter-width,
.mceContentBody img.file-quarter-width {
  max-width: 25%;
  width: 25%; }

/* Third width image style */
.media-third {
  height: auto; }
  @media (min-width: 550px) {
    .media-third {
      max-width: 33%;
      width: 33%; } }
  .media-third img.third-width,
  .media-third img.file-third {
    width: auto;
    max-width: 100%;
    margin: 0;
    float: none; }

.cke_editable img.file-third,
.mceContentBody img.file-third {
  max-width: 33%;
  width: 33%; }

.file img.file-icon {
  display: inline-block !important;
  margin: 0 0.4rem 0 0 !important;
  *zoom: 1;
  *display: inline;
  vertical-align: bottom;
  height: 1.7rem; }

.media-wysiwyg-align-left,
.media-float-left {
  float: none;
  margin-right: 0; }
  @media (min-width: 550px) {
    .media-wysiwyg-align-left,
    .media-float-left {
      float: left;
      margin-right: 20px; } }

.media-wysiwyg-align-right,
.media-float-right {
  float: none;
  margin-left: 0; }
  @media (min-width: 550px) {
    .media-wysiwyg-align-right,
    .media-float-right {
      float: right;
      margin-left: 20px; } }

.messages {
  padding: 15px 25px;
  color: #ffffff;
  background-color: #0b2932;
  margin-bottom: 1.5em;
  /*position: fixed;
  top: 80px;
  right: 30px;*/
  font-weight: 700;
  z-index: 403; }
  .messages a {
    color: #ffffff; }
  .messages ul {
    margin-bottom: 0; }
  .messages em {
    font-style: normal; }

.pane-pane-messages {
  position: relative;
  z-index: 2; }
  .pane-pane-messages ul.tabs {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    @media (max-width: 1149px) {
      .pane-pane-messages ul.tabs li {
        display: block;
        margin-bottom: 3px; } }
    @media (min-width: 1100px) {
      .pane-pane-messages ul.tabs li {
        display: inline-block;
        margin-right: 12px; } }

.item-list .pager {
  padding-top: 30px;
  text-align: left; }
  .item-list .pager li {
    display: inline-block;
    margin: 0 15px 0 0;
    padding: 0;
    text-transform: capitalize; }
    .item-list .pager li.pager-current {
      color: #185C71;
      font-weight: bold; }
    .item-list .pager li a {
      color: #7A959D;
      text-decoration: none; }

.panel-pane.pane-pm-existing-pages .item-list ul.pager {
  padding-top: 30px; }

.social-nav {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: 0; }
  .social-nav li {
    list-style-type: none; }

.main-header .social-nav__item {
  font-size: 0;
  text-indent: -9999px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center; }
  .main-header .social-nav__item.facebook {
    background-image: url("../images/icons/social/facebook-header.svg");
    width: 25px;
    height: 50px; }
  .main-header .social-nav__item.youtube {
    background-image: url("../images/icons/social/youtube-header.svg");
    width: 32px;
    height: 50px; }
  .main-header .social-nav__item.soundcloud {
    background-image: url("../images/icons/social/soundcloud-header.svg");
    width: 62px;
    height: 50px; }

.footer-top .social-nav__item {
  font-size: 0;
  text-indent: -9999px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center; }
  .footer-top .social-nav__item.facebook {
    background-image: url("../images/icons/social/facebook.svg");
    width: 25px;
    height: 50px; }
  .footer-top .social-nav__item.youtube {
    background-image: url("../images/icons/social/youtube.svg");
    width: 40px;
    height: 50px; }
  .footer-top .social-nav__item.soundcloud {
    background-image: url("../images/icons/social/soundcloud.svg");
    width: 62px;
    height: 50px; }
  .footer-top .social-nav__item.musescore {
    background-image: url("../images/icons/social/musescore.svg");
    width: 50px;
    height: 50px; }
  .footer-top .social-nav__item.twitter {
    background-image: url("../images/icons/social/twitter.svg");
    width: 40px;
    height: 50px; }

@media (min-width: 1100px) {
  .pane-social-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0; } }

.pane-social-icons h5 {
  color: #7A959D;
  font-size: 1.0625rem;
  font-weight: 400;
  display: inline-block;
  margin: 0; }

.pane-social-icons .social-nav {
  padding-left: 10px; }

.pane-social-icons .social-nav__item {
  font-size: 0;
  text-indent: -9999px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center; }
  .pane-social-icons .social-nav__item.-facebook {
    background-image: url("../images/icons/social/facebook-share.svg");
    width: 25px;
    height: 50px; }
  .pane-social-icons .social-nav__item.-mail {
    background-image: url("../images/icons/social/mail-share.svg");
    width: 40px;
    height: 50px; }
  .pane-social-icons .social-nav__item.-twitter {
    background-image: url("../images/icons/social/twitter-share.svg");
    width: 40px;
    height: 50px; }

.arrow-link {
  color: #185C71;
  font-weight: bold;
  font-size: 0.9375rem;
  text-decoration: none;
  transition: all 0.25s ease;
  border: none; }
  .arrow-link:hover {
    color: #185C71; }
  .arrow-link.-left {
    background: url("../images/icons/application/arrow--left.svg") no-repeat 0% 50%;
    padding-left: 17px; }
    .arrow-link.-left:hover {
      padding-left: 21px; }
  .arrow-link.-right {
    background: url("../images/icons/application/arrow--right.svg") no-repeat 100% 50%;
    padding-right: 17px; }
    .arrow-link.-right:hover {
      padding-right: 21px; }

.arrow-link--left {
  color: #DE4744;
  font-weight: bold;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  text-decoration: none;
  transition: all 0.25s ease;
  border: none;
  background: url("../images/icons/application/arrow--left.svg") no-repeat 0% 50%;
  padding-left: 17px; }
  .arrow-link--left:hover {
    color: #185C71;
    padding-left: 21px; }

.arrow-link--right, .block-content--type-basic .field--name-field-more-link a, .block-inline-blocktestimonial .field--name-field-more-link a {
  color: #DE4744;
  font-weight: bold;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  text-decoration: none;
  transition: all 0.25s ease;
  border: none;
  background: url("../images/icons/application/arrow--right.svg") no-repeat 100% 50%;
  padding-right: 17px; }
  .arrow-link--right:hover, .block-content--type-basic .field--name-field-more-link a:hover, .block-inline-blocktestimonial .field--name-field-more-link a:hover {
    color: #185C71;
    padding-right: 21px; }

.big-header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  overflow: hidden; }
  .big-header .hero-video {
    object-fit: cover;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
    @media (max-width: 1149px) {
      .big-header .hero-video {
        display: none; } }
  .big-header .block-ek-header-block--content {
    position: relative; }
  .big-header .block-ek-header-block--content--inner {
    position: relative;
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 20px;
    min-height: 250px;
    display: flex;
    align-items: center; }
    @media (min-width: 550px) {
      .big-header .block-ek-header-block--content--inner {
        padding: 0 30px;
        min-height: 400px; } }
    @media (min-width: 1100px) {
      .big-header .block-ek-header-block--content--inner {
        padding: 0 50px;
        min-height: 540px; } }
    .big-header .block-ek-header-block--content--inner:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: -1px;
      width: 600px;
      margin-left: -650px;
      background-color: rgba(0, 0, 0, 0.05);
      z-index: 2; }
    .big-header .block-ek-header-block--content--inner:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: -1px;
      width: 100%;
      left: 0;
      background-color: rgba(0, 0, 0, 0.05);
      z-index: 2; }
      @media (min-width: 1100px) {
        .big-header .block-ek-header-block--content--inner:after {
          width: 33.8%; } }
  .big-header h1 {
    margin: 0;
    color: #ffffff;
    max-width: 800px;
    position: relative;
    z-index: 3;
    font-size: 1.875rem;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.3); }
    @media (min-width: 550px) {
      .big-header h1 {
        font-size: 3.125rem; } }
    @media (min-width: 1100px) {
      .big-header h1 {
        font-size: 4.75rem;
        line-height: 80px; } }

.small-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #7A959D; }
  @media (min-width: 550px) {
    .small-header .block-ek-header-block {
      display: flex;
      flex-wrap: wrap;
      align-items: center; } }
  @media (min-width: 550px) {
    .small-header .block-ek-header-block--image {
      flex: 1 0 35%;
      max-width: 35%; } }
  .small-header .block-ek-header-block--image img {
    vertical-align: top;
    width: 100%; }
  .small-header .block-ek-header-block--content {
    padding: 40px 30px; }
    @media (min-width: 550px) {
      .small-header .block-ek-header-block--content {
        flex: 65%;
        max-width: 65%;
        padding: 45px;
        display: inline-flex;
        align-items: center; } }
    @media (min-width: 1100px) {
      .small-header .block-ek-header-block--content {
        padding: 45px; } }
    @media (min-width: 1150px) {
      .small-header .block-ek-header-block--content {
        padding: 45px; } }
    @media (min-width: 1900px) {
      .small-header .block-ek-header-block--content {
        padding: 50px; } }
  .small-header .block-ek-header-block--content--inner h1, .small-header .block-ek-header-block--content--inner p {
    max-width: 740px; }
  .small-header .block-ek-header-block--content--inner h1 {
    margin-top: 0; }
  .small-header .block-ek-header-block--content--inner p {
    margin-bottom: 0; }

.no-header {
  background-color: #7A959D; }
  .no-header .block-ek-header-block--content {
    position: relative; }
    @media (max-width: 549px) {
      .no-header .block-ek-header-block--content {
        padding: 20px 20px 20px 20px; } }

@media (min-width: 550px) and (max-width: 1099px) {
  .no-header .block-ek-header-block--content {
    padding: 20px 25px 20px 25px; } }
    @media (min-width: 1100px) {
      .no-header .block-ek-header-block--content {
        padding: 20px 0 20px 0;
        max-width: 930px;
        margin: 0 auto; } }
    @media (min-width: 1150px) {
      .no-header .block-ek-header-block--content {
        padding: 20px 0 25px 0; } }
  .no-header .block-ek-header-block--content--inner {
    position: relative; }

.accordion {
  margin: 15px 0; }
  @media (min-width: 1100px) {
    .accordion {
      margin: 20px 0; } }
  @media (min-width: 1150px) {
    .accordion {
      margin: 30px auto; } }

.accordion-item {
  overflow: hidden;
  border-bottom: 1px solid #D6E7E9;
  padding: 1em 0; }
  .accordion-item:first-child {
    border-top: 1px solid #D6E7E9; }
  .accordion-item.-active .accordion-item__title:before {
    transform: rotate(135deg); }
  .accordion-item.-active .accordion-item__content {
    max-height: 999em;
    visibility: visible;
    animation: slide-down .6s ease-out;
    display: block;
    opacity: 1; }

@keyframes slide-down {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%); }
  100% {
    opacity: 1;
    -moz-transform: translateY(0); } }

.accordion-item__title {
  padding-left: 3em;
  color: #185C71;
  display: block;
  position: relative; }
  .accordion-item__title:before {
    content: '+';
    position: absolute;
    font-weight: bold;
    top: 2px;
    left: 0;
    padding: 0 .5em;
    transition: all .7s ease; }

.accordion-item__content {
  opacity: 0;
  max-height: 0;
  display: none;
  transition: all .7s ease;
  padding-left: 3em; }

.ajax-progress {
  display: inline-block;
  padding: 1px 5px 2px 5px; }

[dir="rtl"] .ajax-progress {
  float: right; }

.ajax-progress-throbber .throbber {
  background: transparent url(../../../images/core/throbber-active.gif) no-repeat 0 center;
  display: inline;
  padding: 1px 5px 2px; }

.ajax-progress-throbber .message {
  display: inline;
  padding: 1px 5px 2px; }

tr .ajax-progress-throbber .throbber {
  margin: 0 2px; }

.ajax-progress-bar {
  width: 16em; }

/* Full screen throbber */
.ajax-progress-fullscreen {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.55);
  background-image: url("../images/icons/application/loader.gif");
  background-position: 50% 40%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 5; }
  @media (max-width: 1099px) {
    .ajax-progress-fullscreen {
      top: 75px; } }
  @media (min-width: 1100px) {
    .ajax-progress-fullscreen {
      top: 104px; } }

[dir="rtl"] .ajax-progress-fullscreen {
  left: auto;
  right: 49%; }

/**
 * @file
 * Visual styles for animated throbber.
 *
 * @see autocomplete.js
 */
.js input.form-autocomplete {
  background-image: url(../../../images/core/throbber-inactive.png);
  background-position: 100% center;
  /* LTR */
  background-repeat: no-repeat; }

.js[dir="rtl"] input.form-autocomplete {
  background-position: 0% center; }

.js input.form-autocomplete.ui-autocomplete-loading {
  background-image: url(../../../images/core/throbber-active.gif);
  background-position: 100% center;
  /* LTR */ }

.js[dir="rtl"] input.form-autocomplete.ui-autocomplete-loading {
  background-position: 0% center; }

.view-filters {
  background: #E7F2F4 url("../images/icons/application/bg-shape--light.svg") no-repeat 90% 50%;
  background-size: 110% auto;
  padding: 20px; }

.swiper-overflow {
  position: relative; }

.swiper-container {
  overflow: visible !important;
  position: relative !important; }
  .swiper-container .swiper-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .swiper-container .swiper-slide {
    width: 100%;
    height: auto;
    position: relative;
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0; }
  .swiper-container .swiper-slide:not(.yottie-widget-feed-section-slide) {
    display: inline-flex;
    display: -webkit-inline-flex; }
  .swiper-container .swiper-button-prev,
  .swiper-container .swiper-button-next {
    background-size: 41px 41px;
    width: 41px;
    height: 41px;
    position: absolute;
    left: auto;
    z-index: 10;
    cursor: pointer;
    margin-top: 0;
    outline: none; }
    @media (max-width: 1149px) {
      .swiper-container .swiper-button-prev,
      .swiper-container .swiper-button-next {
        top: -55px; } }
    @media (min-width: 1100px) {
      .swiper-container .swiper-button-prev,
      .swiper-container .swiper-button-next {
        top: -75px; } }
    .swiper-container .swiper-button-prev.swiper-button-disabled,
    .swiper-container .swiper-button-next.swiper-button-disabled {
      opacity: .5;
      cursor: auto;
      pointer-events: none; }
  .swiper-container .swiper-button-prev {
    background-image: url("../images/icons/application/swiper--prev.svg"); }
    @media (max-width: 1099px) {
      .swiper-container .swiper-button-prev {
        left: 0; } }
    @media (min-width: 550px) {
      .swiper-container .swiper-button-prev {
        right: 50px; } }
  .swiper-container .swiper-button-next {
    background-image: url("../images/icons/application/swiper--next.svg"); }
    @media (max-width: 1099px) {
      .swiper-container .swiper-button-next {
        left: 50px; } }
    @media (min-width: 550px) {
      .swiper-container .swiper-button-next {
        right: 0; } }

div.horizontal-tabs {
  margin: 0 0 1em 0;
  /* LTR */
  position: relative;
  /* IE6/7 */
  border: none;
  padding: 0; }
  @media (min-width: 550px) {
    div.horizontal-tabs {
      margin: 30px 0; } }
  div.horizontal-tabs ul.horizontal-tabs-list {
    margin: 0;
    border: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    height: auto;
    clear: both;
    position: relative;
    display: flex; }
    div.horizontal-tabs ul.horizontal-tabs-list:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #D6E7E9;
      width: 100%;
      height: 1px;
      z-index: 0; }
  div.horizontal-tabs li.horizontal-tab-button {
    padding: 0 1px 0;
    margin: 0 15px 0 0;
    color: #DE4744; }
    div.horizontal-tabs li.horizontal-tab-button.selected a,
    div.horizontal-tabs li.horizontal-tab-button a.active {
      position: relative;
      z-index: 1;
      color: #DE4744; }
      div.horizontal-tabs li.horizontal-tab-button.selected a:before,
      div.horizontal-tabs li.horizontal-tab-button a.active:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        z-index: 2;
        background: #DE4744; }
      div.horizontal-tabs li.horizontal-tab-button.selected a:after,
      div.horizontal-tabs li.horizontal-tab-button a.active:after {
        content: '';
        position: absolute;
        bottom: -7px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 7px solid #DE4744;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 2; }
      div.horizontal-tabs li.horizontal-tab-button.selected a strong,
      div.horizontal-tabs li.horizontal-tab-button a.active strong {
        color: #DE4744; }
    div.horizontal-tabs li.horizontal-tab-button a,
    div.horizontal-tabs li.horizontal-tab-button a strong {
      display: block;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 700;
      color: #7A959D; }
    div.horizontal-tabs li.horizontal-tab-button a {
      padding: 3px 0 5px 0; }
      div.horizontal-tabs li.horizontal-tab-button a:hover, div.horizontal-tabs li.horizontal-tab-button a:focus, div.horizontal-tabs li.horizontal-tab-button a:active {
        background: transparent;
        color: #DE4744; }
        div.horizontal-tabs li.horizontal-tab-button a:hover strong, div.horizontal-tabs li.horizontal-tab-button a:focus strong, div.horizontal-tabs li.horizontal-tab-button a:active strong {
          color: #DE4744; }

.horizontal-tabs fieldset.horizontal-tabs-pane {
  padding: 15px 0;
  margin: 0;
  border: 0; }

fieldset.horizontal-tabs-pane > legend,
fieldset.vertical-tabs-pane fieldset.horizontal-tabs-pane > legend {
  display: none; }

/**
 * tab content
 */
div.field-group-htabs-wrapper .field-group-format-wrapper {
  clear: both;
  padding: 0 0 0.6em; }

/*hide*/
.horizontal-tabs .horizontal-tab-hidden {
  display: block;
  position: absolute;
  top: -100000px;
  width: 100%; }

@media (min-width: 1100px) {
  .toc-layout {
    display: grid;
    grid-template-areas: "toc_aside toc_content";
    grid-template-columns: 25rem calc(100vw - 25rem - rem-calc(20)); }
    @supports (display: grid) {
      .toc-layout {
        grid-template-columns: 25rem calc(100vw - 25rem); } } }

.toc-layout__aside__nav {
  z-index: 99;
  position: sticky;
  top: 3.625rem;
  grid-area: toc_aside; }

.toc-layout__content {
  grid-area: toc_content; }

@media (min-width: 1100px) {
  .sidebar-layout {
    display: grid;
    grid-template-areas: "sidebar_aside sidebar_content";
    grid-template-columns: 25rem calc(90vw - 25rem); }
    @supports (display: grid) {
      .sidebar-layout {
        grid-template-columns: 25rem minmax(auto, 82.35294rem); } } }

.sidebar-layout__aside {
  grid-area: sidebar_aside; }

.sidebar-layout__content {
  grid-area: sidebar_content;
  padding: 0 1.25rem; }
  @media (min-width: 1100px) {
    .sidebar-layout__content {
      padding: 0 2.5rem 0 10vw; } }

.content-page-layout {
  margin: 0 auto;
  padding: 0 2.5rem;
  max-width: 82.35294rem; }
  @media (min-width: 1100px) {
    .content-page-layout {
      padding: 0 5rem; } }

/*@import "vendors/...";*/
