.main-language {
  position: relative;
  display: block;
  &:hover {
    .language-switcher-locale-url {
      display: block;
    }
  }
  .current-language {
    cursor: pointer;
    padding: 12px 0;
  }
  .language-switcher-locale-url {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    text-align: left;
    background-color: rgba(14,63,79,.8);
    box-shadow: 0 3px 3px -2px rgba(0,0,0,.1);
    padding: 10px 15px;
  }
}

.locale-untranslated {
  text-decoration: none;
  cursor: hover;
}