/*.inview {
  transform: translateY(40px);
  animation: inview-animation 1.2s ease forwards;
}

@keyframes inview-animation {
  to { transform: translateY(0); }
}*/

.animation-slide-up {
  animation: slide-up ease forwards;
  animation-duration: .7s;
  opacity: 0;
  transform: translateY(12px);
}

.animation-button-enlarge {
  animation: button-enlarge ease forwards;
  animation-duration: 1.3s;
}

.animation-slide-up-blocks {
/*  animation: slide-up ease forwards;
  animation-duration: 1.3s;
  opacity: .9;
  transform: translateY(25px);*/
}

@keyframes slide-up {
  to {
    opacity:1;
    transform:translateY(0)
  }
}

@keyframes button-enlarge {
  50% {
    padding: 17px 30px;
  }
  75% {
    padding: 17px 44px;
  }
  100% {
    padding: 17px 30px;
  }
}