.messages {
  padding: 15px 25px;
  color: $white;
  background-color: darken($primary-color,15%);
  margin-bottom: 1.5em;
  /*position: fixed;
  top: 80px;
  right: 30px;*/
  font-weight: 700;
  z-index: map-get($z-index, messages);
  a {
    color: $white;
  }
  ul {
    margin-bottom: 0;
  }
  em {
    font-style: normal;
  }
}

.pane-pane-messages {
  position: relative;
  z-index: 2;
  ul.tabs {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      @include media-breakpoint-down(md) {
        display: block;
        margin-bottom: 3px;
      }
      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-right: 12px;
      }
    }
  }
}