.view-questions {
  .item-list {
    padding: 20px 0;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    .views-row {
      border: 1px solid $border-color;
      margin: -1px 0 0 0;
      padding: 0;
      list-style-type: none;
      @include media-breakpoint-down(md) {
        padding: 20px;
      }
      @include media-breakpoint-up(md) {
        padding: 25px 30px;
      }
      &:before {
        display: none;
      }
    }
  }
  .field-name-question-link a {
    text-decoration: none;
    font-weight: 500;
    font-size: rem-calc(19);
    line-height: 25px;
    &:hover {
      color: $secondary-color;
    }
  }
  .field-name-node-author-name-and-role a {
    text-decoration: none;
    color: $tertiary-color;
    text-transform: uppercase;
    font-size: rem-calc(15);
    margin: 7px 0 0 0;
    font-weight: 600;
    display: inline-block;
    &:hover {
      color: $secondary-color;
    }
  }
  .field-name-comment-count {
    color: $quaternary-color;
  }
}

.field-name-post-date {
  color: $tertiary-color;
  text-transform: uppercase;
  font-size: rem-calc(15);
  margin: 0 0 7px 0;
  font-weight: 600;
}

.node-question {
  &.view-mode-search_result {
    max-width: $max-content-width;
    h2 {
      color: $primary-color;
      font-size: rem-calc(24);
      margin: 25px 0 5px 0;
    }
  }
}

/* In manual list */
.node-question.view-mode-list {
  border: 1px solid $border-color;
  margin: -1px 0 0 0;
  padding: 0;
  list-style-type: none;
  @include media-breakpoint-down(md) {
    padding: 20px;
  }
  @include media-breakpoint-up(md) {
    padding: 25px 30px;
  }
  &:before {
    display: none;
  }
  .field-name-question-link a {
    text-decoration: none;
    font-weight: 500;
    font-size: rem-calc(19);
    line-height: 25px;
    &:hover {
      color: $secondary-color;
    }
  }
  .field-name-node-author-name-and-role a {
    text-decoration: none;
    color: $tertiary-color;
    text-transform: uppercase;
    font-size: rem-calc(15);
    margin: 7px 0 0 0;
    font-weight: 600;
    display: inline-block;
    &:hover {
      color: $secondary-color;
    }
  }
  .field-name-comment-count {
    color: $quaternary-color;
  }
}