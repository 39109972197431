.big-header {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	position: relative;
	overflow:hidden;
	.hero-video {
		object-fit: cover;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	.block-ek-header-block--content {
		position: relative;
	}
	.block-ek-header-block--content--inner {
		position: relative;
		max-width: $max-site-width;
		margin: 0 auto;
		padding: 0 20px;
		min-height: 250px;
		display: flex;
		align-items: center;
		@include media-breakpoint-up(sm) {
			padding: 0 30px;
			min-height: 400px;
		}
		@include media-breakpoint-up(md) {
			padding: 0 50px;
			min-height: 540px;
		}
		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: -1px;
			width: 600px;
			margin-left: -650px;
			background-color: rgba(0,0,0,.05);
			z-index: 2;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: -1px;
			width: 100%;
			left: 0;
			background-color: rgba(0,0,0,.05);
			z-index: 2;
			@include media-breakpoint-up(md) {
				width: 33.8%;
			}
		}
	}
	h1 {
		margin: 0;
		color: $white;
		max-width: 800px;
		position: relative;
		z-index: 3;
		font-size: rem-calc(30);
		text-shadow: 0 0 30px rgba(0,0,0,.3);
		@include media-breakpoint-up(sm) {
			font-size: rem-calc(50);
		}
		@include media-breakpoint-up(md) {
			font-size: rem-calc(76);
			line-height: 80px;
		}
	}
}

.small-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background-color: $grey;
	.block-ek-header-block {
		@include media-breakpoint-up(sm) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
	}

	.block-ek-header-block--image {
		@include media-breakpoint-up(sm) {
			flex: 1 0 35%;
			max-width: 35%;
		}
		img {
			vertical-align: top;
			width: 100%;
		}
	}

	.block-ek-header-block--content {
		padding: 40px 30px;
		@include media-breakpoint-up(sm) {
			flex: 65%;
			max-width: 65%;
			padding: 45px;
			display: inline-flex;
			align-items: center;
		}
		@include media-breakpoint-up(md) {
			padding: 45px;
		}
		@include media-breakpoint-up(lg) {
			padding: 45px;
		}
		@include media-breakpoint-up(xl) {
			padding: 50px;
		}
	}

	.block-ek-header-block--content--inner {
		h1, p {
			max-width: 740px;
		}
		h1 {
			margin-top: 0;
		}
		p {
			@extend.intro-txt;
			margin-bottom: 0;
		}
	}
}

.no-header {
	background-color: $grey;
	.block-ek-header-block--content {
		position: relative;

		@include media-breakpoint-down(xs) {
			padding: 20px 20px 20px 20px;
		}
		@include media-breakpoint-only(sm) {
			padding: 20px 25px 20px 25px;
		}
		@include media-breakpoint-up(md) {
			padding: 20px 0 20px 0;
			max-width: $max-content-width;
			margin: 0 auto;
		}
		@include media-breakpoint-up(lg) {
			padding: 20px 0 25px 0;
		}
	}
	.block-ek-header-block--content--inner {
		position: relative;
	}
}