.paragraphs-item-paragraph-text-image  {
	&.image-position--left {
		.row {
			flex-direction: row-reverse;
		}
	}
	.col:first-child {
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}
	}
	h2 {
		color: $primary-color;
		font-size: rem-calc(24);
	}
	.field-name-field-subtitle {
		font-weight: 500;
		font-size: rem-calc(18);
	}
	.field-name-field-link {
		a {
			@extend.btn-blue;
		}
	}
	img {
		width: 100%;
	}
	p:first-child {
		margin-top: 7px;
	}
}