.pane-bundle-manual-list {
  .field-name-manual-list {
    padding-top: 15px;
    position: relative;
  }
  .col-md-4:not(.swiper-slide) {
    .node-score.view-mode-square {
      height: calc(100% - 30px);
    }
  }
  .node-library-score.view-mode-square {
    height: calc(100% - 30px);
  }
}