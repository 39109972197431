.entity-paragraphs-item {
 	@include media-breakpoint-down(md) {
 		margin-top: 25px;
 		margin-bottom: 25px;
 	}
 	@include media-breakpoint-up(md) {
 		margin-top: 45px;
 		margin-bottom: 45px;
 	}
 	h2:first-child {
 		margin-top: 0;
 	}
}

.entity-paragraphs-item {
	.button {
		@extend.btn-blue;
		a {
			color: $white;
			text-decoration: none;
		}
	}
}

@import "paragraphs/paragraph-text";
@import "paragraphs/paragraph-faq";
@import "paragraphs/paragraph-gallery";
@import "paragraphs/paragraph-video";
@import "paragraphs/paragraph-button";
@import "paragraphs/paragraph-cta";
@import "paragraphs/paragraph-download";
@import "paragraphs/paragraph-image";
@import "paragraphs/paragraph-quote";
//@import "paragraphs/paragraph-table";
@import "paragraphs/paragraph-text-image";
@import "paragraphs/paragraph-embed-code";
@import "paragraphs/paragraph-attachement";
@import "paragraphs/paragraph-intro";
@import "paragraphs/paragraph-manual-list";