.pane-bundle-logo-link .fieldable-panels-pane,
.pane-bundle-logo-s .fieldable-panels-pane {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  ms-flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    ms-flex-wrap: nowrap;
  }
  .img {
    width: 25%;
    padding: 10px;
    max-width: 195px;
    @include media-breakpoint-up(sm) {
      width: 20%;
    }
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    max-height: 175px;
  }
}
