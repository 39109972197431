dialog {
  z-index: map-get($z-index, dialog);
  display: block;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  padding: 1em;
  color: inherit;
  border: 0;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

dialog + .backdrop {
  background: rgba($black, 0.8);
}

::backdrop {
  background: rgba($black, 0.8);
  animation: .4s ease fade;
}

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: .4s ease-in-out;
  width: 100%;
  height: 100%;
  padding: rem-calc(15) rem-calc(30);
  background: $white;

  &.-media {
    width: 80vw;
    max-width: none;
    padding: 0;

    background: $black;

    &::before {
      content: "loading...";
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }

    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
}

.dialog[open] {
  animation: .6s ease dialog-slide-in;
}

.dialog-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: rem-calc(50) rem-calc(25);
  @include media-breakpoint-up(md) {
    padding: rem-calc(50);
  }
}

.dialog-slider {

}

.dialog__close-btn {
  position: absolute;
  top: rem-calc(30);
  right: rem-calc(50);
  width: 21px;
  height: 53px;
  padding: 0;
  color: $black;
  background: transparent;
  border: 0;
  outline: 0;

  span {
    background-color: $black;
    display: block;
    position: absolute;
    height: rem-calc(2);
    width: rem-calc(19);
    top: 29px;
  }
  span:nth-child(1) {
    transform: rotate(45deg);
  }
  span:nth-child(2) {
    transform: rotate(-45deg);
  }

  &:hover,
  &:focus {
    span {
      background-color: $primary-color;
    }
  }
}

.dialog__media {
  margin: 0;
  padding: 0;

  &.-iframe-video {
    position: relative;
    padding-top: 56.26%;
    overflow: hidden;
  }
}

.dialog__video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@keyframes dialog-slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

@keyframes dialog-slide {
  from {
    transform: translate3d(0, -48%, 0);
  }
  to {
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes dialog-fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
