.toc-layout {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-areas: "toc_aside toc_content";
    grid-template-columns: $default-aside-width calc(100vw - #{$default-aside-width} - rem-calc(20));

    @supports (display: grid) {
      grid-template-columns: $default-aside-width calc(100vw - #{$default-aside-width});
    }
  }
}

.toc-layout__aside__nav {
  z-index: map-get($z-index, toc-nav);
  position: sticky;
  top: $main-nav-height;

  grid-area: toc_aside;
}

.toc-layout__content {
  grid-area: toc_content;
}
