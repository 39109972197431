.page-search-content {
  .search-form {
    background-color: $blue-bg;
    padding: 25px;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
    .form-item {
      margin: 0;
      &.form-item-keys {
        @include media-breakpoint-up(md) {
          display: inline-flex;
          flex-wrap: wrap;
          align-items: center;
          flex: 1 0 calc(100% - 90px);
          max-width: calc(100% - 90px);
          padding-right: 10px;
          label {
            flex: 1 0 180px;
            max-width: 180px;
          }
          input {
            flex: 1 0 calc(100% - 180px);
            max-width: calc(100% - 180px);
            margin: 0;
          }
        }
      }
    }
    .form-wrapper {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .button.form-submit {
      @include media-breakpoint-up(md) {
        flex: 1 0 90px;
        max-width: 90px;
        height: 44px !important;
      }
    }
  }
}