.page {
  @include media-breakpoint-up(md) {
  }
}

.container, .content {
	@include media-breakpoint-down(sm) {
		padding-left: $phone-padding;
    padding-right: $phone-padding;
	}
	@include media-breakpoint-up(sm) {
		max-width: $max-site-width;
		margin: 0 auto;
		padding-left: $tablet-padding;
		padding-right: $tablet-padding;
	}
	@include media-breakpoint-up(lg) {
		padding-left: $desktop-padding;
    padding-right: $desktop-padding;
	}
}

.main,
.one-sidebar {
	@include media-breakpoint-down(sm) {
		padding-left: $phone-padding;
		padding-right: $phone-padding;
	}
	@include media-breakpoint-up(sm) {
		max-width: $max-site-width;
		margin: 0 auto;
		padding-left: $tablet-padding;
		padding-right: $tablet-padding;
	}
	@include media-breakpoint-up(lg) {
		padding-left: $desktop-padding;
		padding-right: $desktop-padding;
	}
}

.one-sidebar .main {
	padding-left: 0;
	padding-right: 0;
	h2 {
		color: $primary-color;
		font-size: rem-calc(30);
	}
	h3 {
		color: $tertiary-color;
	}
}