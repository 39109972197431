form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) {
  .form-managed-file {
    .file {
      padding-right: 10px;
      display: inline-block;
    }
    .image-widget-data {
      > .button {
        display: inline-block;
        border: none;
        padding: 0;
        border-radius: 0;
        width: 30px;
        height: 30px;
        margin: 0;
        background: transparent url('../images/layout-builder/delete.svg') no-repeat center center;
        text-indent: -9999px;
        font-size: 0;
        overflow: hidden;
        box-shadow: none;
        &.js-hide {
          display: none;
        }
      }
      > .form-type-textfield {
        display: none;
      }
    }
  }
}

