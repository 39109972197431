.block-inline-blocktestimonial {
  margin: 0 auto;
  position: relative;
  @include media-breakpoint-up(md) {
    max-width: $max-content-width;
    &:before {
      content: '';
      position: absolute;
      background: url('../images/icons/application/quote-marks.svg') no-repeat;
      width: 34px;
      height: 27px;
      top: 70px;
      left: -80px;
    }
  }
  &.inview-block-active {
    @extend.animation-slide-up-blocks;
  }
  h2 {
    margin-top: 0;
  }
  .field--name-field-more-link a {
    @extend.arrow-link--right;
  }
  .field-name-field-quotee-img {
    img {
      width: 140px;
      height: 140px;
      border: 5px solid white;
      border-radius: 50%;
      box-shadow: 0 0 2px 1px rgba(0,0,0,.2);
    }
  }
  .col-md-6 {
    &:nth-last-child(2) {
      @include media-breakpoint-up(md) {
        width: 200px;
        flex: 0 0 200px;
        max-width: 200px;
      }
    }
    &:last-child {
      @include media-breakpoint-up(md) {
        width: calc(100%-200px);
        flex: 0 0 calc(100%-200px);
        max-width: calc(100%-200px);
      }
    }
  }
  p {
    margin-top: 0;
  }
  .title-wrapper {
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
    }
    @include media-breakpoint-down(lg) {
      width: calc(100%+50px);
    }
    @include media-breakpoint-up(lg) {
      width: calc(100%+120px);
    }
  }
}