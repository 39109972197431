.form-type-webform-rating {
  label {
    margin-bottom: 5px;
  }
}
/*
html.js .form-webform-rating {
  display: none;
}

div.svg div.rateit-range {
  background: url(../images/rating/star-normal.svg);
}

div.svg div.rateit-hover {
  background: url(../images/rating/star-hover.svg);
}

div.svg div.rateit-selected {
  background: url(../images/rating/star-selected.svg);
}

div.svg div.rateit-preset {
  background: url(../images/rating/star-preset.svg);
}

div.svg button.rateit-reset {
  background: url(../images/rating/reset-normal.svg);
}

div.svg button.rateit-reset:hover {
  background: url(../images/rating/reset-hover.svg);
}

div.svg.rateit-small {
  height: 16px;
}

div.svg.rateit-small div.rateit-range,
div.svg.rateit-small div.rateit-hover,
div.svg.rateit-small div.rateit-selected,
div.svg.rateit-small div.rateit-preset {
  background-size: 16px 16px;
}

div.svg.rateit-small button.rateit-reset {
  height: 16px;
  width: 16px;
  background-size: 16px 16px;
}

div.svg.rateit-medium {
  height: 24px;
}

div.svg.rateit-medium div.rateit-range,
div.svg.rateit-medium div.rateit-hover,
div.svg.rateit-medium div.rateit-selected,
div.svg.rateit-medium div.rateit-preset {
  background-size: 24px 24px;
}

div.svg.rateit-medium button.rateit-reset {
  height: 24px;
  width: 24px;
  background-size: 24px 24px;
}

div.svg.rateit-large {
  height: 36px;
}

div.svg.rateit-large div.rateit-range,
div.svg.rateit-large div.rateit-hover,
div.svg.rateit-large div.rateit-selected,
div.svg.rateit-large div.rateit-preset {
  background-size: 32px 32px;
}

div.svg.rateit-large button.rateit-reset {
  height: 32px;
  width: 32px;
  background-size: 32px 32px;
}*/

.rateit {
  display: inline-block;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
}

.rateit .rateit-range {
  position: relative;
  display: inline-block;
  background: url('../images/rating/star.gif');
  height: 16px;
  outline: none;
}

.rateit .rateit-range * {
  display: block;
}

* html .rateit, * html .rateit .rateit-range {
  display: inline;
}

* + html .rateit, * + html .rateit .rateit-range {
  display: inline;
}

.rateit .rateit-hover, .rateit .rateit-selected {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
}

.rateit .rateit-hover-rtl, .rateit .rateit-selected-rtl {
  left: auto;
  right: 0;
}

.rateit .rateit-hover {
  background: url('../images/rating/star.gif') left -32px;
  color: rgb(239, 197, 41);
}

.rateit .rateit-hover-rtl {
  background-position: right -32px;
}

.rateit .rateit-selected {
  background: url('../images/rating/star.gif') left -16px;
  color: rgb(191,66,66);
}

.rateit .rateit-selected-rtl {
  background-position: right -16px;
}

.rateit .rateit-preset {
  background: url('../images/rating/star.gif') left -48px;
  color: rgb(239, 107, 34);
}

.rateit .rateit-preset-rtl {
  background: url('../images/rating/star.gif') left -48px;
}

.rateit button.rateit-reset {
  background: url('../images/rating/star.gif') 0 0;
  width: 16px;
  height: 16px;
  display: -moz-inline-box;
  display: inline-block;
  float: left;
  outline: none;
  border: none;
  padding: 0;
}

.rateit .rateit-reset span {
  display: none;
}

.rateit button.rateit-reset:hover, .rateit button.rateit-reset:focus {
  background-position: 0 -16px;
}


.rateit-font {
  font-size: 24px;
  line-height: 1em;
}

.rateit-font .rateit-range {
  background: none;
  height: auto;
}

.rateit-font .rateit-empty {
  color: #ccc;
}

.rateit-font .rateit-range > div {
  background: none;
  overflow: hidden;
  cursor: default;
}

.rateit.rateit-font .rateit-reset {
  font-size: inherit;
  background: none;
  width: 0.6em;
  height: 0.6em;
  margin-top: 0.2em;
  background: gray;
  border-radius: 50%;
  position: relative;
}

.rateit.rateit-font .rateit-reset span {
  display: block;
  font-weight: bold;
  color: white;
  height: calc(50% - 0.045em);
  top: 0;
  position: absolute;
  border-bottom: 0.09em solid white;
  width: 72%;
  margin-left: 14%;
}


.rateit.rateit-font .rateit-reset:hover, .rateit.rateit-font button.rateit-reset:focus {
  background: #e6574b; /* Old browsers */
  background: radial-gradient(ellipse at center, #e6574b 55%,#f6836b 77%,#f9d3cc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-position: 0 0;
}