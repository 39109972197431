.node-score {
  .field-name-field-composer {
    text-transform: uppercase;
    font-size: rem-calc(15);
    color: $tertiary-color;
    font-weight: 700;
  }
  h2 {
    color: $primary-color;
    font-size: rem-calc(30);
    margin: 7px 0;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
  .field-name-bezetting-begeleiding {
    margin-top: 7px;
    color: $primary-color;
    text-transform: uppercase;
    font-size: rem-calc(15);
  }
  .field-name-field-soundcloud {
    @include media-breakpoint-down(xs) {
      overflow:hidden;
      padding-bottom: 60%;
      position:relative;
      height:0;
      iframe {
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
      }
    }
  }
  .media-youtube-video {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    iframe {
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
  }
  &.view-mode-square {
    border: 1px solid $border-color;
    background-color: $white;
    width: 100%;
    margin: 15px 0;
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
    @include media-breakpoint-up(md) {
      padding: 30px;
    }
    &:hover {
      box-shadow: inset 0px -3px $secondary-color;
      h2 {
        color: $secondary-color;
      }
    }
  }
  &.view-mode-list,
  &.view-mode-search_result {
    background-color: $white;
    @include media-breakpoint-only(sm) {
      img {
        max-width: 400px;
      }
    }
    h3 {
      margin: 5px 0;
      a {
        text-decoration: none;
        &:hover {
          color: $secondary-color;
        }
      }
    }
    .field-name-field-short-description {
      p {
        margin: 15px 0;
      }
    }
  }
}

.score-count {
  color: #7A959D;
}

.page-partituren {
  .pane-pm-existing-pages {
    .node-score {
      margin: 0;
      border-top: 1px solid $border-color;
      @include media-breakpoint-down(md) {
        padding: 20px 0;
      }
      @include media-breakpoint-up(md) {
        padding: 40px 0;
      }
    }
  }
}

.filter-wrapper + .extra-wrapper {
  color: $quaternary-color;
  text-decoration: none;
  margin: 15px 0;
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .pane-score-sort {
    position: relative;
    padding-right: 15px;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 12px;
      right: 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $quaternary-color;
    }
    &:hover {
      ul {
        display: block;
      }
    }
    ul {
      position: absolute;
      top: 100%;
      right: 0;
      width: 180px;
      display: none;
      margin: 0 -2px;
      padding: 0;
      border: 1px solid $border-color;
      border-top: 0;
      z-index: 2;
      background-color: $white;
      li {
        &:before {
          display: none;
        }
        a {
          display: block;
          padding: 7px 10px;
          text-decoration: none;
          border-top: 1px solid $border-color;
          color: $quaternary-color;
          &:hover, &:active, &:focus {
            color: $secondary-color;
          }
        }
      }
    }
  }
  + .panel-pane.pane-pm-existing-pages {
     padding-top: 0;
  }
}

.filter-wrapper {
  background: #E7F2F4 url('../images/icons/application/bg-shape--light.svg') no-repeat 90% 50%;
  background-size: 110% auto;
  padding: 20px;
  .filters {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .block-filter {
    position: relative;
    @include media-breakpoint-up(sm) {
      flex: 1 0 33.33%;
      max-width: 33.33%;
      &:not(:nth-child(1)):not(:nth-child(4)) {
        .label {
          margin-left: 10px;
        }
        .item-list {
          left: 10px;
          width: calc(100% - 10px);
        }
      }
    }
    @include media-breakpoint-up(md) {
      flex: 1 0 20%;
      max-width: 20%;
      &:not(:nth-child(1)) {
        .label {
          margin-left: 10px;
        }
        .item-list {
          left: 10px;
          width: calc(100% - 10px);
        }
      }
    }
    .button.form-submit {
      box-shadow: none !important;
      height: 46px !important;
      padding: 10px 12px !important;
      @include media-breakpoint-down(md) {
        margin: 8px 0 0 0 !important;
        width: 100%;
      }
      @include media-breakpoint-up(md) {
        margin: 0 !important;
      }
    }
    &.open {
      .item-list {
        display: block;
      }
    }
    &.active {
      .item-list {
        display: block;
        top: 1px;
        background-color: $white;
        box-shadow: none;
        ul {
          padding: 5px 0;
        }
        a.facetapi-active {
          font-weight: 500;
          color: $primary-color;
          font-size: rem-calc(15);
        }
        a.facetapi-inactive {
          display: none;
        }
      }
    }
    .label {
      margin-top: 1px;
      padding: 10px 12px;
      background-color: $white;
      border: 1px solid $border-color;
      font-family: $font-family--base;
      font-size: rem-calc(15);
      font-weight: 500 !important;
      border-radius: 0;
      display: block;
      transition: $transition;
      color: $primary-color !important;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        right: 12px;
        bottom: 18px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $primary-color;
        pointer-events: none;
      }
      &:focus {
        outline: none;
        border-color: $tertiary-color;
      }
    }
    /*label.option {
      width: calc(100% - 20px);
    }*/
    .item-list {
      display: none;
      position: absolute;
      z-index: 10;
      top: 48px;
      left: 0;
      right: 0;
      width: 100%;
      background-color: $white;
      border: 1px solid $border-color;
      box-shadow: 0 3px 3px -2px rgba(0,0,0,.2);
      padding: 0;
      .form-type-select:after {
        display: none;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 5px;
        li {
          margin: 0;
        }
        a {
          text-decoration: none;
          padding: 5px 7px;
          display: block;
          font-size: rem-calc(15);
          &:hover {
            color: $secondary-color;
          }
          &.facetapi-active {
            background: transparent url(../images/icons/application/close-blue.svg) no-repeat center right 8px;
            background-size: 10px auto;
            font-weight: bold;
          }
        }
      }
    }
    .bef-checkboxes {
      .form-item.form-type-bef-checkbox {
        margin: 2px 0 !important;
      }
    }
  }
}

/* OLD: TO REMOVE */
.pane-views-exp-scores-page {
  background: #E7F2F4 url('../images/icons/application/bg-shape--light.svg') no-repeat 90% 50%;
  background-size: 110% auto;
  padding: 20px;
  .views-exposed-widgets {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .views-exposed-widget {
    position: relative;
    @include media-breakpoint-up(md) {
      flex: 1 0 20%;
      max-width: 20%;
      &:not(:nth-child(1)) {
        label:not(.option) {
          margin-left: 10px;
        }
        .views-widget {
          left: 10px;
          width: calc(100% - 10px);
        }
      }
    }
    .button.form-submit {
      box-shadow: none !important;
      height: 46px !important;
      padding: 10px 12px !important;
      @include media-breakpoint-down(md) {
        margin: 8px 0 0 0 !important;
        width: 100%;
      }
      @include media-breakpoint-up(md) {
        margin: 0 !important;
      }
    }
    &.open {
      .views-widget {
        display: block;
      }
    }
    label:not(.option) {
      margin-top: 1px;
      padding: 10px 12px;
      background-color: $white;
      border: 1px solid $border-color;
      font-family: $font-family--base;
      font-size: rem-calc(15);
      font-weight: 500 !important;
      border-radius: 0;
      display: block;
      transition: $transition;
      color: $primary-color !important;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 12px;
        bottom: 20px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $primary-color;
        pointer-events: none;
      }
      &:focus {
        outline: none;
        border-color: $tertiary-color;
      }
    }
    label.option {
      width: calc(100% - 20px);
    }
    .views-widget {
      display: none;
      position: absolute;
      z-index: 10;
      top: 48px;
      left: 0;
      right: 0;
      width: 100%;
      background-color: $white;
      border: 1px solid $border-color;
      box-shadow: 0 3px 3px -2px rgba(0,0,0,.2);
      padding: 20px;
      .form-type-select:after {
        display: none;
      }
    }
    .bef-checkboxes {
      .form-item.form-type-bef-checkbox {
        margin: 2px 0 !important;
      }
    }
  }
}

.group-aan-de-slag {
  padding-top: 15px;
  .field-name-field-author {
    font-style: italic;
    @include media-breakpoint-up(md) {
      display: inline-block;
      margin-left: 15px;
    }
    .label-inline,
    .field-name-user-name-and-role {
      display: inline-block;
      margin-right: 4px;
    }
  }
}

.field-name-need-help {
  margin-top: 1.5rem;
  font-style: italic;
}

.field-name-score-icons {
  @include media-breakpoint-down(md) {
    margin: 10px 0;
  }
  @include media-breakpoint-up(md) {
    float: right;
  }
  .icon {
    display: inline-block;
    text-indent: -9999px;
    overflow: hidden;
    width: 36px;
    height: 24px;
    @include media-breakpoint-down(md) {
      margin-right: 10px;
    }
    @include media-breakpoint-up(md) {
      margin-left: 10px;
    }
    &.icon-pdf {
      background: url('../images/icons/application/content-pdf.svg') no-repeat center center;
    }
    &.icon-video {
      background: url('../images/icons/application/content-video.svg') no-repeat center center;
    }
    &.icon-sheet {
      background: url('../images/icons/application/content-music.svg') no-repeat center center;
    }
    &.icon-description {
      background: url('../images/icons/application/content-aan-de-slag.svg') no-repeat center center;
    }
  }
}

.field-name-field-pdf {
  margin-top: 5px;
  .file {
    display: block;
  }
}