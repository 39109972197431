form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) {
  .form-checkboxes {
    .form-item {
      margin: 0;
    }
  }
  .form-item.form-type-select {
    margin: 0;
  }
  .form-item.form-type-checkbox {
    label {
      font-weight: 400;
    }
  }
  .form-type-checkbox,
  .form-type-bef-checkbox {
    label {
      position: relative;
      padding: 2px 0 2px 26px;
      display: inline-block;
      cursor: pointer;
      margin: 0;
      color: $primary-color;
      font-weight: 500;
      &:before {
        content: "";
        position: absolute;
        top: 3px;
        left: -3px;
        width: 22px;
        height: 22px;
        border: 1px solid $border-color;
        background: $white;
      }
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 2px;
        left: -3px;
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        transform: rotate(-45deg);
        transform-origin: 18px -1px;
      }
    }
    input {
      opacity: 0;
      width: 0;
      height: 0;
      display: inline;
      &:checked + label:after {
        width: 10px;
        height: 6px;
        border-color: $primary-color;
        transition: height .08s ease-out, width .08s ease-out .1s;
      }
    }
  }
}
