.btn-red {
  position: relative;
  display: inline-block;
  margin: 10px 0 0 0;
  padding: 12px 25px 10px 12px;
  vertical-align: middle;
  border: 0;
  font-family: $font-family--headers;
  font-size: rem-calc(15);
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: $white;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: $red;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: $transition;
  height: 40px;
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid $white;
  }
  &:hover,
  &:focus {
    color: $secondary-color;
    text-decoration: none;
    background-color: $white;
    &:after {
      border-left: 6px solid $secondary-color;
    }
  }
  &:active,
  &:focus {
    outline: 0;
  }
}

.btn-blue {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 12px 25px 10px 12px;
  vertical-align: middle;
  border: 0;
  font-family: $font-family--headers;
  font-size: rem-calc(15);
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: $white;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: $tertiary-color;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: $transition;
  height: 40px;
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid $white;
  }
  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
    background-color: lighten($tertiary-color, 7%);
  }
  &:active,
  &:focus {
    outline: 0;
  }
}

.btn-white {
  position: relative;
  display: inline-block;
  margin: 0 0 15px 0;
  padding: 12px 25px 10px 12px;
  vertical-align: middle;
  border: 0;
  font-family: $font-family--headers;
  font-size: rem-calc(15);
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: $primary-color;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: $white;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: $transition;
  height: 40px;
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 12px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid $primary-color;
  }
  &:hover,
  &:focus {
    color: $secondary-color;
    text-decoration: none;
    background-color: $white;
    &:after {
      border-left: 6px solid $secondary-color;
    }
  }
  &:active,
  &:focus {
    outline: 0;
  }
}

.btn-white--yellow-text {
  position: relative;
  display: inline-block;
  margin: 0 15px 15px 0;
  padding: 12px 25px 10px 12px;
  vertical-align: middle;
  border: 0;
  font-family: $font-family--headers;
  font-size: rem-calc(15);
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: $yellow;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: $white;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: $transition;
  height: 40px;
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 12px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid $yellow;
  }
  &:hover,
  &:focus {
    color: $secondary-color;
    text-decoration: none;
    background-color: $white;
    &:after {
      border-left: 6px solid $secondary-color;
    }
  }
  &:active,
  &:focus {
    outline: 0;
  }
}

/* Button style - legacy */
.button {
  @extend.btn-blue;
}

.btn-blue--back {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 12px 12px 10px 25px;
  vertical-align: middle;
  border: 0;
  font-family: $font-family--headers;
  font-size: rem-calc(15);
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: $white;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: $tertiary-color;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: $transition;
  height: 40px;
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid $white;
  }
  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
    background-color: lighten($tertiary-color, 7%);
  }
  &:active,
  &:focus {
    outline: 0;
  }
}