.paragraphs-item-text {
  max-width: $max-content-width;
  /*margin-left: auto;
  margin-right: auto;*/
  img {
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
    @include media-breakpoint-up(md) {
      margin: 0 20px 15px 20px;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
}