.panel-pane.pane-bundle-free-block{
  .image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
  }
  .ratio {
    padding: 0 0 65%;
    height: 0;
    width: 100%;
  }
  .top.image {
    .ratio {
      padding: 0 0 55%;
    }
  }
}