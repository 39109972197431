.view-nieuws {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .views-row {
    @include media-breakpoint-up(md) {
      flex: 1 0 33.333%;
      max-width: 33.333%;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;
    }
  }
}

.node-news {
  img {
    vertical-align: top;
    width: 100%;
    height: auto;
  }
  .field-name-post-date {
    color: $tertiary-color;
    text-transform: uppercase;
    font-size: rem-calc(15);
    margin: 0 0 20px 0;
    font-weight: 700;
  }
  &.view-mode-search_result {
    max-width: $max-content-width;
    h2 {
      color: $primary-color;
      font-size: rem-calc(24);
      margin: 25px 0 5px 0;
    }
  }
  &.view-mode-square {
    position: relative;
    height: 100%;
    display: inline-flex;
    flex-flow: column;
    align-items: stretch;
    background-color: $white;
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
    &:hover {
      cursor: hover;
      box-shadow: inset 0px -3px $secondary-color;
      h3 {
        color: $secondary-color;
      }
    }
    h3 {
      color: $primary-color;
      font-size: rem-calc(24);
      margin-top: 0;
      a {
        text-decoration: none;
        &:hover {
          color: $secondary-color;
        }
      }
    }
    .content-wrapper {
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      height: 100%;
      @include media-breakpoint-down(md) {
        padding: 15px 20px 20px 20px;
      }
      @include media-breakpoint-up(md) {
        padding: 25px 30px 30px 30px;
        flex: 1 1 auto;
      }
    }
    .image-wrapper {
      position: relative;
    }
    .field-name-news-label {
      background-color: $yellow;
      text-transform: uppercase;
      font-size: rem-calc(15);
      color: $white;
      font-weight: 700;
      padding: 1px 5px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &.node-teaser {
    &:hover {
      cursor: hover;
      h2 {
        color: $secondary-color;
      }
      .group-right {
        box-shadow: inset 0px -3px $secondary-color;
      }
    }
    h2 {
      color: $primary-color;
      font-size: rem-calc(24);
    }
    .group-right {
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      @include media-breakpoint-down(md) {
        padding: 20px;
      }
      @include media-breakpoint-up(md) {
        padding: 30px;
      }
    }
  }
}

.views-row-last + .element-invisible + .item-list {
  @include media-breakpoint-up(md) {
    flex: 1 0 100%;
    padding-left: 15px;
  }
}

.pane-views-exp-nieuws-page {
  background: #E7F2F4 url('../images/icons/application/bg-shape--light.svg') no-repeat 90% 50%;
  background-size: 110% auto;
  padding: 20px;
  .bef-checkboxes {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
    }
    .form-item {
      @include media-breakpoint-up(sm) {
        margin-right: 15px !important;
      }
    }
    .form-type-select:after {
      display: none !important;
    }
  }
}

#views-exposed-form-nieuws-page {
  .form-type-select:after {
    display: none !important;
  }
}

/*.paragraphs-item-paragraph-manual-list.view-mode-full {
  .node-news.view-mode-square {
    marginn-bottom: 30px;
  }
}*/