form:not(.layout-builder-add-block):not(.layout-builder-form):not(.layout-builder-update-block):not(.ctools-use-modal-processed) {
  .form-item.form-type-radio {
    padding: 4px 0;
    margin: 0;
    input[type=radio] {
      position: absolute;
      opacity: 0;
    }
    input[type=radio]:checked + label:before {
      background-color: $primary-color;
      box-shadow: inset 0 0 0 3px #fff;
    }
    label {
      cursor: pointer;
      font-weight: 400;
      &:before {
        content: "";
        background: $white;
        border-radius: 100%;
        border: 1px solid darken($border-color,5%);
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        top: 2px;
        margin-right: 2px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all .25s ease;
      }
    }
  }
}
