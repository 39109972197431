.pane-mobile-menu {
  @include media-breakpoint-down(lg) {
    .nav-toggle--menu, .nav-toggle {
      display: block;
    }
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.nav-toggle--menu {
  display: none;
}
.nav-toggle {
  display: block;
  padding: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  overflow: hidden;
  margin: 0;
  position: absolute;
  background-color: transparent;
  border: none;
  color: $white;
  border-radius: none;
  outline: none;
  z-index: 20;
  @include media-breakpoint-down(sm) {
    top: 11px;
    right: 5px;
  }
  @include media-breakpoint-up(sm) {
    top: 16px;
    right: 5px;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
  span {
    background: $white;
    display: block;
    width: 20px;
    height: 2px;
    right: 17px;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    border-radius: 3px;
    &:first-child {
      top: 20px;
    }
    &:nth-child(2), &:nth-child(3) {
      top: 27px;
    }
    &:nth-child(4) {
      top: 34px;
    }
  }
  &.open {
    span:nth-child(1), span:nth-child(2), span:nth-child(3), span:nth-child(4) {
      top: 27px;
    }
  }
}

.menu-wrapper {
  @include media-breakpoint-down(md) {
    opacity: 0;
    visibility: hidden;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow: scroll;
    right: 0;
    background: $primary-color;
    z-index: 18;
    padding: 20px 0;
  }
  @include media-breakpoint-only(sm) {
    height: 100vh;
    top: 0;
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    visibility: visible;
    opacity: 1;
  }
  &.open {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
  .main-menu,
  .top-menu,
  .language-switcher-locale-url,
  .user {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: $white;
    .links li {
      list-style-type: none;
      &:before {
        display: none;
      }
    }
    li {
      list-style-type: none;
      &:before {
        display: none;
      }
    }
    a {
      color: $white;
      text-decoration: none;
      padding: 3px 0;
      display: block;
    }
  }
  .user {
    padding: 0 0 20px 20px;
  }
  .language-switcher-locale-url {
    padding: 0 0 0 20px;
  }
}