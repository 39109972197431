.item-list {
  .pager {
    padding-top: 30px;
    text-align: left;
    li {
      display: inline-block;
      margin: 0 15px 0 0;
      padding: 0;
      text-transform: capitalize;
      &.pager-current {
        color: $primary-color;
        font-weight: bold;
      }
      a {
        color: $quaternary-color;
        text-decoration: none;
      }
    }
  }
}

.panel-pane.pane-pm-existing-pages .item-list ul.pager {
  padding-top: 30px;
}