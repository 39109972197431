.paragraphs-item-paragraph-faq {
  max-width: $max-content-width;
}

.field-name-field-qa {
  margin-top: 20px;
}
.field-collection-item-field-qa {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  margin: 0 0 -1px 0;
}

.pane-bundle-faq,
.field-name-field-qa {
  h3 {
    cursor: pointer;
    padding: 13px 30px 13px 0;
    margin: 0;
    font-weight: 500;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 22px;
      right: 10px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid $primary-color;
    }
  }
  .field-name-field-qa-answer {
    display: none;
    padding: 0 30px 15px 0;
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .open {
    .field-name-field-qa-answer {
      display: block;
    }
    h3 {
      color: $secondary-color;
      &:after {
        border-bottom: 6px solid $secondary-color;
        border-top: none;
      }
    }
  }
}

