#search-form {
  max-width: none;
  .results-info {
    display: flex;
    padding: 20px 0 0;
    a.text-button {
      @include media-breakpoint-down(sm) {
        display: none;
      }
      @include media-breakpoint-up(sm) {
        display: block;
        text-align: right;
        margin-left: auto;
      }
    }
  }

}

.library-filter {
  background: $blue-bg;
  width: 100%;
  padding: 20px 40px 30px;
  .form-item {
    margin: 10px 0 !important;
  }
  .text-button {
    margin: 0 30px;
  }
}

.page-bibliotheek {
  .pane-pm-existing-pages {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.node-library-composer {
  .label-inline {
    font-weight: 600;
  }
  .content-inline,
  .label-inline {
    display: inline-block;
  }
}

.bib-search-results {
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;
    .node {
      width: calc(50% - 15px);
      &:nth-child(2n+1) {
        margin-right: 15px;
      }
      &:nth-child(2n) {
        margin-left: 15px;
      }
    }
  }

}

.node-library-score {
  .label-inline {
    font-weight: 600;
  }
  .content-inline,
  .label-inline {
    display: inline-block;
  }
  &.view-mode-full {
    .group-header {
      .field-name-back {
        a {
          @extend.btn-blue--back;
        }
      }
    }
    .group-composition,
    .group-edition {
      @include media-breakpoint-down(sm) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      @include media-breakpoint-up(sm) {
        margin-top: 30px;
      }
    }
    .group-bib.highlight {
      background: #E7F2F4 url('../images/icons/application/bg-shape--light.svg') no-repeat 90% 50%;
      background-size: 110% auto;
      margin-top: 30px;
      @include media-breakpoint-down(md) {
        padding: 25px;
      }
      @include media-breakpoint-up(md) {
        padding: 30px;
      }
      h3 {
        margin-top: 0;
      }
      .label-inline {
        font-weight: 400;
      }
    }
  }
  &.view-mode-square,
  &.view-mode-search_result {
    position: relative;
    .field-name-bundle {
      background-color: $yellow;
      text-transform: uppercase;
      font-size: rem-calc(15);
      color: $white;
      font-weight: 700;
      padding: 1px 5px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    border: 1px solid $border-color;
    @include media-breakpoint-down(sm) {
      padding: 20px;
      margin-bottom: 15px;
    }
    @include media-breakpoint-up(sm) {
      padding: 30px;
      margin-bottom: 30px;
    }
    &:hover {
      h2 a {
        color: $secondary-color;
      }
    }
    h2 {
      margin: 0 0 7px 0;
      font-size: rem-calc(24);
      a {
        text-decoration: none;
        &:hover, &:focus, &:active {
          color: $secondary-color;
        }
      }
    }
  }
}
