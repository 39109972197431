.one-sidebar .main .paragraphs-item-call-to-action,
.paragraphs-item-call-to-action {
	background-color: $yellow;
	position: relative;
	@include media-breakpoint-down(md) {
		padding: 25px 30px 30px 30px;
	}
	@include media-breakpoint-up(md) {
		padding: 35px 30px 40px 30px;
	}
	h2 {
		color: $white;
		margin-top: 0;
		@include media-breakpoint-down(md) {
			font-size: rem-calc(30);
		}
		@include media-breakpoint-up(md) {
			font-size: rem-calc(36);
		}
	}
	h2,
	.field-name-field-basic-text-text,
	.field-name-field-link {
		color: $white;
	}
	.field-name-field-basic-text-text {
		p {
			margin: 10px 0 20px 0;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.field-name-field-link {
		margin-top: 20px;
		margin-bottom: -10px;
		a {
			@extend.btn-white--yellow-text;
		}
	}
	a {
		color: $white;
	}
}
