.swiper-overflow {
  position: relative;
}

.swiper-container {
  overflow: visible !important;
  position: relative !important;
  .swiper-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    width:100%;
    height:100%;
    position:relative;
    z-index:1;display:-webkit-box;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
    display:flex;
    -webkit-transition-property:-webkit-transform;
    -moz-transition-property:-moz-transform;
    -o-transition-property:-o-transform;
    -ms-transition-property:-ms-transform;
    transition-property:transform;
    -webkit-box-sizing:content-box;
    -moz-box-sizing:content-box;
    box-sizing:content-box;
    -webkit-transform:translate3d(0,0,0);
    -moz-transform:translate3d(0,0,0);
    -o-transform:translate(0,0);
    -ms-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
  }
  .swiper-slide {
    width:100%;
    height: auto;
    position:relative;
    -webkit-flex-shrink:0;
    -ms-flex:0 0 auto;
    flex-shrink:0;
  }
  .swiper-slide:not(.yottie-widget-feed-section-slide) {
    display: inline-flex;
    display: -webkit-inline-flex;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-size: 41px 41px;
    width: 41px;
    height: 41px;
    position: absolute;
    left: auto;
    z-index:10;
    cursor:pointer;
    margin-top: 0;
    outline: none;
    @include media-breakpoint-down(md) {
      top: -55px;
    }
    @include media-breakpoint-up(md) {
      top: -75px;
    }
    &.swiper-button-disabled {
      opacity: .5;
      cursor:auto;
      pointer-events:none
    }
  }
  .swiper-button-prev {
    background-image: url('../images/icons/application/swiper--prev.svg');
    @include media-breakpoint-down(sm) {
      left: 0;
    }
    @include media-breakpoint-up(sm) {
      right: 50px;
    }
  }
  .swiper-button-next {
    background-image: url('../images/icons/application/swiper--next.svg');
    @include media-breakpoint-down(sm) {
      left: 50px;
    }
    @include media-breakpoint-up(sm) {
      right: 0;
    }
  }
}