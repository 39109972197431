/*.embedded-entity {
  &.align-right {
    float: none;
    img {
      @include media-breakpoint-up(sm)  {
        float: right;
        padding: 0 0 rem-calc(20) rem-calc(20);
      }
    }
    figcaption {
      @include media-breakpoint-up(sm)  {
        float: right;
        clear: right;
      }
    }
  }
  &.align-left {
    float: none;
    img {
      @include media-breakpoint-up(sm)  {
        float: left;
        padding: 0 rem-calc(20) rem-calc(20) 0;
      }
    }
  }
  &.align-center {
    text-align: center;
    img {
      display: inline-block;
      margin: 0 0 rem-calc(15);
    }
  }
  &.caption {
    display: block;
  }
  img.image-style-one-third-width {
    @include media-breakpoint-up(sm)  {
      width: 33%;
    }
  }
  img.image-style-half-width {
    @include media-breakpoint-up(sm)  {
      width: 50%;
    }
  }
  img.image-style-full-width {
    margin-bottom: rem-calc(15);
    width: 100%;
  }
  figcaption {
    display: block;
    margin-top: -rem-calc(20);
    font-size: rem-calc(11);
    font-weight: 400;
    font-style: italic;
    color: lighten($black, 20%);
  }
}*/

.file img.file-icon {
  width: 15px;
  vertical-align: middle;
}


/* Media */
.field-name-field-image-caption,
.media-element-container,
.field-name-field-caption {
  color: #999;
  font-size: 0.9em;
  line-height: 1.3em;
  font-weight: normal;
}
.field-name-field-caption {
  padding-top: 10px;
}
.media-element-container {
  max-width: 100%;
  //@extend .clearfix;
}

/* Half width image style */
.media-half_width {
  height: auto;
  @include media-breakpoint-up(sm) {
    max-width: 50%;
    width: 50%;
  }
  img.panopoly-image-half,
  img.half-width,
  img.file-half-width {
    width: auto;
    max-width: 100%;
    margin: 0;
    float: none;
  }
}
.cke_editable img.file-half-width,
.mceContentBody img.file-half-width {
  max-width: 50%;
  width: 50%;
}

/* Full width image style */
.media-full_width {
  height: auto;
  @include media-breakpoint-up(sm) {
    max-width: 95%;
    width: 95%;
  }
  img.panopoly-image-full,
  img.full-width,
  img.file-full-width {
    width: 100%;
    max-width: 100%;
    margin: 0;
    float: none;
  }
}
.cke_editable img.file-full-width ,
.mceContentBody img.file-full-width {
  max-width: 90%;
  width: 90%;
}

/* Quarter width image style */
.media-quarter_width {
  height: auto;
  @include media-breakpoint-up(sm) {
    max-width: 25%;
    width: 25%;
  }
  img.panopoly-image-quarter,
  img.quarter-width,
  img.file-quarter-width {
    width: auto;
    max-width: 100%;
    margin: 0;
    float: none;
  }
}
.cke_editable img.file-quarter-width,
.mceContentBody img.file-quarter-width {
  max-width: 25%;
  width: 25%;
}

/* Third width image style */
.media-third {
  height: auto;
  @include media-breakpoint-up(sm) {
    max-width: 33%;
    width: 33%;
  }
  img.third-width,
  img.file-third {
    width: auto;
    max-width: 100%;
    margin: 0;
    float: none;
  }
}
.cke_editable img.file-third,
.mceContentBody img.file-third {
  max-width: 33%;
  width: 33%;
}

.file img.file-icon {
  display: inline-block !important;
  margin: 0 0.4rem 0 0 !important;
  *zoom: 1;
  *display: inline;
  vertical-align: bottom;
  height: 1.7rem;

}

.media-wysiwyg-align-left,
.media-float-left {
  float: none;
  margin-right: 0;
  @include media-breakpoint-up(sm) {
    float: left;
    margin-right: 20px;
  }
}
.media-wysiwyg-align-right,
.media-float-right {
  float: none;
  margin-left: 0;
  @include media-breakpoint-up(sm) {
    float: right;
    margin-left: 20px;
  }
}
