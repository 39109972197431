.accordion {
  margin: 15px 0;

  @include media-breakpoint-up(md) {
    margin: 20px 0;
  }

  @include media-breakpoint-up(lg) {
    margin: 30px auto;
  }
}

.accordion-item{
  overflow: hidden;
  &:first-child {
    border-top: 1px solid $border-color;
  }
  border-bottom: 1px solid $border-color;
  padding: 1em 0;

  &.-active{
    .accordion-item__title:before {
      transform: rotate(135deg);
    }
    .accordion-item__content{
      max-height: 999em;
      visibility: visible;
      animation: slide-down .6s ease-out;
      display: block;
      opacity: 1;
    }
  }

}

@keyframes slide-down {
  0% { opacity: 0; -moz-transform: translateY(-100%); }
  100% { opacity: 1; -moz-transform: translateY(0); }
}

.accordion-item__title {
  padding-left: 3em;
  color: $primary-color;
  display: block;
  position: relative;
  &:before {
    content: '+';
    position:absolute;
    font-weight: bold;
    top: 2px;
    left: 0;
    padding: 0 .5em;
    transition: all .7s ease;
  }
}

.accordion-item__content{
  opacity: 0;
  max-height: 0;
  display: none;
  transition: all .7s ease;
  padding-left: 3em;
}