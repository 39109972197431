.footer-top {
	background-color: $primary-color;
	color: $white;
	@include media-breakpoint-down(md) {
		padding: 40px 0;
	}
	@include media-breakpoint-up(md) {
		padding: 60px 0;
	}
	h3 {
		color: $white;
		text-transform: uppercase;
		font-size: rem-calc(16);
		font-weight: 900;
		margin-top:  0;
	}
	.col-md-3:nth-child(1), .col-md-3:nth-child(2) {
		a {
			color: $white;
			text-decoration: none;
			text-shadow: 0.03em 0 $primary-color, -0.03em 0 $primary-color, 0 0.03em $primary-color, 0 -0.03em $primary-color, 0.06em 0 $primary-color, -0.06em 0 $primary-color, 0.09em 0 $primary-color, -0.09em 0 $primary-color, 0.12em 0 $primary-color, -0.12em 0 $primary-color, 0.15em 0 $primary-color, -0.15em 0 $primary-color;
			background: linear-gradient($primary-color,$primary-color),linear-gradient($primary-color,$primary-color),linear-gradient(#749DA9,#749DA9);
			background-size: .05em 1px,.05em 1px,1px 1px;
			background-repeat: no-repeat,no-repeat,repeat-x;
			background-position: 0 95%,100% 95%,0 95%;
			&:hover, &:focus, &:active {
				background: linear-gradient($primary-color,$primary-color),linear-gradient($primary-color,$primary-color),linear-gradient($white,$white);
				background-size: .05em 1px,.05em 1px,1px 1px;
				background-repeat: no-repeat,no-repeat,repeat-x;
				background-position: 0 95%,100% 95%,0 95%;
			}
		}
	}
	.col-md-3:nth-child(4) {
		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}
	.col:not(:last-child) {
		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}
	}
	.koorenstem-logo {
		background: url('../images/logo/koorenstem--white.svg') no-repeat 100% 50%;
		width: 181px;
		height: 74px;
		text-indent: -999px;
		font-size: 0;
	}
	p:last-child {
		margin-bottom: 0;
	}
}

.footer-bottom {
	padding: 15px 0;
	font-weight: 500;
	.container {
		@include media-breakpoint-up(md) {
			display: flex;
			flex-wrap: wrap;
		}
	}
	.menu {
		list-style-type: none;
		padding: 0;
		@include media-breakpoint-down(md) {
			margin-to: 30px;
		}
		@include media-breakpoint-up(md) {
			margin: 0 0 0 auto;
		}
		li {
			@include media-breakpoint-up(md) {
				display: inline-block;
				padding-left: 15px;
			}
			a {
				text-decoration: none;
				&:hover, &:focus, &:active {
					color: $secondary-color;
				}
			}
		}
	}
}


.main-footer {
	background-color: $grey;
	background-position: top center;
	background-size: 100%;
	position: relative;
	@include media-breakpoint-down(md) {
		padding: 30px 0;
		margin-top: 30px;
	}
	@include media-breakpoint-up(md) {
		padding: 60px 0;
		margin-top: 80px;
	}
	ul, ul:not([class])  {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li:before {
		  display: none;
		}
	}
}

.block-ek-footer-block {
	max-width: $max-site-width;
 	margin: 0 auto;
 	min-height: 300px;
	@include media-breakpoint-down(sm) {
		padding-left: $phone-padding;
		padding-right: $phone-padding;
	}
	@include media-breakpoint-up(sm)  {
		padding-left: $tablet-padding;
		padding-right: $tablet-padding;
	}
	@include media-breakpoint-up(lg) {
		padding-left: $desktop-padding;
		padding-right: $desktop-padding;
	}
}

.block-ek-footer-block--logo {
	@include media-breakpoint-down(sm) {
		img {
			width: 133px;
			height: 34px;
		}
	}
	@include media-breakpoint-up(lg) {
		img {
			width: 193px;
			height: 50px;
		}
	}
}

.block-ek-footer-block--slogan-social {
	padding: 30px 0;
	@include media-breakpoint-only(md) {
		padding: 30px 0;
	}
	@include media-breakpoint-up(md) {
		padding: 30px 0;
		display: flex;
		align-items: top;
		justify-content: space-between;
	}
}

.block-ek-footer-block--slogan {
	font-weight: bold;
	font-family: $font-family--headers;
	font-size: rem-calc(27);
	line-height: 27px;
	color: $black;
}

.block-ek-footer-block--social {
}

.block-ek-footer-block--nav {
	font-size: rem-calc(15);
	@include media-breakpoint-up(md) {
		display: flex;
		flex-wrap: wrap;
	}
	a {
		color: rgba($black,.8);
		text-decoration: none;
		line-height: 36px;
		&:hover {
			color: $primary-color;
		}
	}
	.item-list {
		@include media-breakpoint-up(md) {
			flex: 1 0 25%;
			max-width: 25%;
			padding-right: 15px;
		}
	}
}

.subfooter {
	max-width: $max-site-width;
	margin: 0 auto;
	font-size: rem-calc(15);
	@include media-breakpoint-down(sm) {
		padding-left: $phone-padding;
		padding-right: $phone-padding;
	}
	@include media-breakpoint-up(sm)  {
		padding-left: $tablet-padding;
		padding-right: $tablet-padding;
	}
	@include media-breakpoint-up(lg) {
		padding-left: $desktop-padding;
		padding-right: $desktop-padding;
	}
}