.panel-pane.pane-bundle-image {
  max-width: $max-content-width;
  margin-left: auto;
  margin-right: auto;
  img {
    vertical-align: top;
  }
  p {
    margin: 7px 0 0 0;
  }
}